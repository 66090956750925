import React, { useState } from 'react';
import { useCart } from '../CustomHook/useCart';
import { Input } from 'antd';
import { CART_LANGUAGE } from '../Language/Language';

function CartNote({ language = 'vi' }) {
  const { updateNote, cartHeader } = useCart();

  const [note, setNote] = useState(cartHeader.Cart_Note || '');

  const handleInputChange = (e) => {
    setNote(e.target.value);
  };

  const handleInputBlur = (e) => {
    updateNote(e.target.value);
  };
  return (
    <div className="cart__note" style={{ width: '100%' }}>
      <p style={{ fontWeight: 'bold', fontSize: '16px', letterSpacing: 0, marginBottom: '5px', textAlign: 'left' }}>
        {CART_LANGUAGE[language]['CART_NOTE']}
      </p>
      <Input.TextArea onBlur={handleInputBlur} onChange={handleInputChange} value={note} className='cart__note-text-area' rows={4} />
    </div>
  );
}

export default CartNote;

