import React from 'react';
import ReactNotification from 'react-notifications-component';
import Helmet from '../Layout/Helmet';
import CheckoutList from './CheckoutList';
import Footer from './Control/Footer';
import Header from './Control/Header';
import { LanguageContext } from '../ContextProvider/LanguageContextProvider';
import './Css/Style.css';
import 'react-notifications-component/dist/theme.css';

function CheckoutPage() {
  const { language } = React.useContext(LanguageContext);
  return (
    <Helmet title={language === 'vi' ? 'Đặt hàng' : 'Place an order'}>
      <ReactNotification />
      <Header language={language} current={2} />
      <CheckoutList language={language} />
      <Footer />
    </Helmet>
  );
}

export default CheckoutPage;
