import React, { useEffect, useState, } from 'react'
import backgroundTitle from '../../asset/img/img-8.jpg'
import { ForgetPasswordCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input } from 'antd';
import './Css/Login.css';
import { LoginService } from './Services/LoginServices';
import { FORGET_PASSWORD_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../common/Constant.Common';
import { store } from 'react-notifications-component';
import ReCAPTCHA from 'react-google-recaptcha';
import NotifySuccess from './Control/NotifySuccess';

export default function ForgetPage(props) {
    const [success, setSuccess] = useState(false);
    const { language } = props;
    const [captcha, setCaptcha] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = async (values) => {
        const model = {
            captcha,
            email: values.email,
            language:language
        };
       
        try {
            const user = await LoginService.sendMailForget(model);
            if (user.returnCode === RESULT_CODES.SUCCESS) {
                setSuccess(prevProps => !prevProps);
            } else {
                store.addNotification({
                    title: user.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
                    message: `${FORGET_PASSWORD_RETURN_CODE_DESCRIPTION[parseInt(user.returnCode, 10)]}`,
                    type: user.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            console.log("error", error);
        }
        
    };
    const onChangeCaptcha = (value) => {
        setCaptcha(value);
    };
 
    return (
        <Helmet title={ForgetPasswordCommon[language].title}>
            <Breadcrum data={[
                ForgetPasswordCommon[language].title
            ]}
            ></Breadcrum>
            <TitleBreadcrum title={ForgetPasswordCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
            <Form
                style={{ display: `${!success ? 'block' : 'none'}` }}
                className='login-main'
                onFinish={onSubmit}
            >
                <h3>{ForgetPasswordCommon[language].ForgetPasswordField.title}</h3>
                <div className='login-main-hint'>{ForgetPasswordCommon[language].ForgetPasswordField.hint}</div>
                <div className='login-main__field-title'>{ForgetPasswordCommon[language].ForgetPasswordField.main.email.title}</div>
                <Form.Item
                    name='email'
                    rules={[{ required: true, message: ForgetPasswordCommon[language].ForgetPasswordField.main.email.warning }]}
                >
                    <Input type='text'></Input>
                </Form.Item>
                <ReCAPTCHA
                    className='captcha'
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                    onChange={onChangeCaptcha}
                    hl={language}
                />,
                <button type='submit' className='btn-submit-form'>
                    {ForgetPasswordCommon[language].submit}
                </button>
            </Form>
            <NotifySuccess language={language} success={success} content={ForgetPasswordCommon[language].modalSuccess} button={ForgetPasswordCommon[language].back}></NotifySuccess>
        </Helmet>
    );
}
