export const UserService = {
  getUserLocation
};

function getUserLocation() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    headers,
    method: 'GET',
  };
  return fetch(`${process.env.REACT_APP_API_URL}/User/location`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
