import React, { useEffect, useState, } from 'react'
import backgroundTitle from '../../asset/img/img-8.jpg'
import { ChangePasswordCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input, Row, Col } from 'antd';
import './Css/Login.css';
import { Link } from 'react-router-dom';
import ModalLogin from './Control/ModalLogin';
import { CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../common/Constant.Common';
import { LoginService } from './Services/LoginServices';
import { store } from 'react-notifications-component';

export default function ChangePasswordPage(props) {
  const [modal, setModal] = useState(false);
  const { language } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values) => {
    if(values.oldpassword !== values.password) {

    const model = {
      oldpassword: values.oldpassword,
      password: values.password,
    };
    const result = await LoginService.changePassword(model);
    if (result.returnCode === RESULT_CODES.SUCCESS) {
      setModal(prevProps => !prevProps);
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      store.addNotification({
        title: result.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
        message: `${CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION[parseInt(result.returnCode, 10)]}`,
        type: result.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  } else {
    store.addNotification({
      title:'Error!',
      message: `Mật khẩu mới trùng với mật khẩu hiện tại.`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  };
  return (
    <Helmet title={ChangePasswordCommon[language].title}>
      <Breadcrum data={[
        ChangePasswordCommon[language].title
      ]}
      ></Breadcrum>
      <TitleBreadcrum title={ChangePasswordCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
      <Form
        className='login-main login-main-change-password'
        onFinish={onSubmit}
      >
        <h3>{ChangePasswordCommon[language].ChangePasswordField.title}</h3>
        <Row >
          <Col lg={6} md={8} sm={24} xs={24}>
            <div className='login-main__field-title login-main__field-title--profile'>{ChangePasswordCommon[language].ChangePasswordField.main.password.title}</div>
          </Col>
          <Col lg={14} md={16} sm={24} xs={24}>
            <Form.Item
              name='oldpassword'
              rules={[{ required: true, message: ChangePasswordCommon[language].ChangePasswordField.main.password.warning }]}
            >
              <Input type='password' className='login-main__field--input'></Input>
            </Form.Item>
          </Col>
          <Col lg={4} md={24} sm={24} xs={24}>
            <Link to='forget' className='login-main_-field--input--fake-event'>{ChangePasswordCommon[language].forgetPassword}</Link>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} sm={24} xs={24}>
            <div className='login-main__field-title login-main__field-title--profile'>{ChangePasswordCommon[language].ChangePasswordField.main.NewPassword.title}</div>
          </Col>
          <Col lg={14} md={16} sm={24} xs={24}>
            <Form.Item
              name='password'
              rules={[{ required: true, message: ChangePasswordCommon[language].ChangePasswordField.main.NewPassword.warning }]}
            >
              <Input type='password' className='login-main__field--input'></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} sm={24} xs={24}>
            <div className='login-main__field-title login-main__field-title--profile'>
              {ChangePasswordCommon[language].ChangePasswordField.main.reNewPassword.title}
            </div>
          </Col>
          <Col lg={14} md={16} sm={24} xs={24}>
            <Form.Item
              name='repassword'
              rules={[
                {
                  required: true, message: ChangePasswordCommon[language].ChangePasswordField.main.reNewPassword.warning
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(ChangePasswordCommon[language].ChangePasswordField.main.reNewPassword.wrong));
                  },
                }),
              ]}
            >
              <Input type='password' className='login-main__field--input'></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} sm={24} xs={24}>
          </Col>
          <Col lg={14} md={16} sm={24} xs={24}>
            <button htmlType='submit' className='btn-submit-form btn-submit-form-profile'>
              {ChangePasswordCommon[language].submit}
            </button>
          </Col>
        </Row>

      </Form>
      {modal ?
        <ModalLogin handleToggleModal={() => {
          setModal(prevProps => !prevProps);
          document.getElementsByTagName('body')[0].style.overflow = 'auto';
        }} content={ChangePasswordCommon[language].modalSuccess}></ModalLogin>
        : ''
      }
    </Helmet>
  );
}
