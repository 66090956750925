import { store } from 'react-notifications-component';

const options = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 3000,
    onScreen: true
  }
};

export const openSuccessNotification = ({ title = '', message = '' }) => {
  store.addNotification({
    title,
    message,
    type: 'success',
    ...options
  });
};
export const openNotification = ({ title = '', message = '' }) => {
  store.addNotification({
    title,
    message,
    type: 'info',
    ...options,
    dismiss: {
      duration: 4000,
      onScreen: true
    }
  });
};
export const openErrorNotification = ({ title = '', message = '' }) => {
  store.addNotification({
    title,
    message,
    type: 'danger',
    ...options
  });
};

export const getHighestValueByKey = (key, arr = []) => {
  return arr.reduce((prev, cur) => (prev[key] > cur[key]) ? prev[key] : cur[key], 0);
};

export function formatNumber(value) {
  return value ? (value % 1 === 0 ? value : value.toFixed(2)) : ''
}
