import React from 'react';
import Helmet from '../Layout/Helmet';
import Header from './Control/Header';
import Footer from './Control/Footer';
import CustomerInfoList from './CustomerInfoList';
import { LanguageContext } from '../ContextProvider/LanguageContextProvider';
import './Css/Style.css';

function CustomerInfoPage() {
  const { language } = React.useContext(LanguageContext);

  return (
    <Helmet title={language === 'vi' ? 'Thông tin khách hàng' : 'Customer\'s information'}>
      <Header language={language} current={1} />
      <CustomerInfoList language={language}/>
      <Footer />
    </Helmet>
  );
}

export default CustomerInfoPage;

