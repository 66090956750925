export const RESULT_CODES = {
    RUNTIME_ERRROR: '-2',
    ERROR: '-1',
    CLEAR: '0',
    SUCCESS: '1',
    DATA_EXIST: '2',
    PERMISSION: '3',
    DATA_USING: '4',
    LOGIC_ENDATE: '5',
    LOGIC_STARTDATE: '6',
    LOGIC_REPEATTIME_WITH_STARTDATE_ENDDATE: '7',
    CONFLICT_TIME_BOOKROOM: '8',
    ERROR_REPEAT_TYPE: '9',
    IMPORT_ERROR: '10',
    ACCOUNT_INCORECT: '11',
    ACCOUNT_PASSWORD_INCORRECT: '12',
    ACCOUNT_EXPIRED: '13',
    ACCOUNT_INACTIVE: '14',
    DATA_NOT_EXIST: '15',
    DATE_NOT_CORRECT: '16',
    ACCOUNT_OLD_PASSWORD_INCORRECT: '17'
};

export const CREATE_RETURN_CODE_DESCRIPTION = {
    1: "Thêm mới thành công",
    [-1]: "Thêm mới thất bại",
    18:"Bị trùng tên file"
}

export const UPDATE_RETURN_CODE_DESCRIPTION = {
    1: "Cập nhật thành công",
    [-1]: "Cập nhật thất bại"
}

export const DELETE_RETURN_CODE_DESCRIPTION = {
    1: "Xóa thành công",
    [-1]: "Xóa thất bại"
}

export const LOGIN_RETURN_CODE_DESCRIPTION = {
    1: "Đăng nhập thành công",
    11: "Tài khoản không tìm thấy",
    12: "Mật khẩu chưa chính xác",
    14: "Tài khoản chưa kích hoạt",
}

export const CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION = {
    1: "Thay đổi mật khẩu thành công",
    17: "Mật khẩu cũ chưa chính xác"
}

export const FORGET_PASSWORD_RETURN_CODE_DESCRIPTION={
    [-1]:"Không tìm thấy email"
}


export const PRODUCT_TYPE ={
    THEPHINH: 'NSP003',
    THEPTAM:'NSP004',
    THEPTAMGAN:'NSP005',
    THEPKHAC: 'NSP006'
}
export const LIMIT = {
    PRODUCT: 100,
    PRODUCT_TYPE: 100,
    SPECIFICATION: 100,
    UNIT: 100

}