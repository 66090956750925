import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { getProductNameByLanguage } from '../../Product/Helper/Helper';
import { Link } from 'react-router-dom';
import { PRODUCTS_LANGUAGE } from '../../Product/Language/Language';
import noImage from '../../../asset/img/no-image.png';
import { PRODUCT_TYPE } from '../../../common/Constant.Common'
function ProductInfo({ product, language }) {
  // const [firstImage, setFirstImage] = useState(null);
  // const [secondImage, setSecondImage] = useState(null);
  const [productImgConvert, setProductConvert] = useState([]);
  useEffect(() => {
    // setFirstImage(product.Product_Images?.[1]?.name
    //   ? `${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[1]?.name}`
    //   : noImage
    // );
    // setSecondImage(product.Product_Images?.[2]?.name
    //   ? `${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[2]?.name}`
    //   : noImage
    // );
    let imgProduct = [];
    for (let index = 1; index < product.Product_Images.length; index++) {
      if (index > 2) {
        break
      }
      imgProduct.push(`${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[index]?.name}`)
    }
    setProductConvert(imgProduct)
  }, [product._id, product.Product_Images]);

  return (
    <div className='product__info'>
      <div className="product__info__wrapper">
        <div className="product__images__wrapper">
          <Row gutter={[30, 40]}>

            {productImgConvert && productImgConvert.length > 0 ? productImgConvert.map((item, index) => {
              return (
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className="product__image">
                    <img src={item} alt={product.Product_Name} />
                  </div>
                </Col>)
            }) : <Col lg={12} md={12} sm={24} xs={24}>
            </Col>}
          </Row>
        </div>
        <div className="product__info__detail">
          <h3>{getProductNameByLanguage(product, language)}</h3>
          <div dangerouslySetInnerHTML={{ __html: language === 'vi' ? product.Product_Detail_Info : product.Product_Detail_Info_Eng }} />
        </div>
        {product.Product_Category !== PRODUCT_TYPE.THEPHINH &&
          product.Product_Category !== PRODUCT_TYPE.THEPTAM &&
          product.Product_Category !== PRODUCT_TYPE.THEPTAMGAN &&
          product.Product_Category !== PRODUCT_TYPE.THEPKHAC &&
          <div className="product__info__contact">
            <Link to={`/${language}/contact`} className='btn btn__contact'>
              {PRODUCTS_LANGUAGE[language]['CONTACT_ORDER']}
            </Link>
          </div>}
      </div>
    </div>
  );
}

export default ProductInfo;
