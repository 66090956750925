import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCart } from '../CustomHook/useCart';
import { useForm } from 'antd/lib/form/Form';
import { Modal } from 'antd';
import CartItem from './CartItem';
import CartNote from './CartNote';
import { CART_LANGUAGE } from '../Language/Language';
import '../Css/CartModal.css';

function CartModal({ children, visible, hideModal, language = 'vi' }) {
  const history = useHistory();
  const { cartItems, totalItem } = useCart();
  const [form] = useForm();

  const handleFinish = () => {
    form.validateFields().then(() => {
      history.push('/cart/changecustomerinfo');
    }).catch(() => {
      // TO DO
    });
  };

  const handleCancel = () => {
    form.resetFields();
    hideModal();
  }

  useEffect(()=> {
    form.resetFields();
  }, [visible])

  const footer = () => {
    return (
      <div className="cart__modal__footer">
        <div className="cart__modal__footer__wrapper">
          <CartNote language={language} />
          <button className='cart__modal__footer__action' onClick={handleFinish}>{CART_LANGUAGE[language]['PROCEED_TO_CHECKOUT']}</button>
          <Link to='../products' className="cart__modal__footer__return">
            <i className="fas fa-reply" aria-hidden={true}></i>
            <p>{CART_LANGUAGE[language]['CONTINUE_SHOPPING']}</p>
          </Link>
        </div>
      </div>
    );
  };
  return (
    <>
      {children}
      <Modal
        width={1030}
        destroyOnClose={true}
        title={`${CART_LANGUAGE[language]['YOUR_CART'].toUpperCase()} ( ${totalItem} ${CART_LANGUAGE[language]['PRODUCT']} )`}
        visible={visible}
        onCancel={handleCancel}
        centered
        className='cart__modal'
        footer={footer()}
      >
        <div className="cart__modal__content">
          {cartItems.map(item => (
            <CartItem key={item._id} product={item} language={language} form={form} />
          ))}
        </div>
      </Modal>
    </>
  );
}

export default CartModal;


