import React from 'react'
import { Button } from 'antd'
import { ContactCommon } from './Model/ContactModel'
export default function ContactModal(props) {
    return (
        <div className="contact-modal-background">
            <div className="contact-modal">
                <h3>{ContactCommon[props.language].ContactModal.title}</h3>
                <p dangerouslySetInnerHTML={{__html:ContactCommon[props.language].ContactModal.main}}>
                    
                </p>
                <Button className="contact-button-close" onClick={() => props.handleCloseContactModal()}>
                    {ContactCommon[props.language].buttonClose}
                </Button>
            </div>
        </div>
    )
}
