import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import CartItem from './Control/CartItem';
import CartNote from './Control/CartNote';
import { CART_LANGUAGE } from './Language/Language';

function CartList(props) {
  const history = useHistory();
  const [form] = useForm();

  const handleFinish = () => {
    form.validateFields().then(() => {
      history.push('/cart/changecustomerinfo');
    }).catch(() => {
      // TO DO
    });
  };

  return (
    <div className='cart__list'>
      <h3 className='cart__list-title'>{CART_LANGUAGE[props.language]['YOUR_CART']}</h3>
      {props.cartItems.map(item => (
        <CartItem key={item._id} product={item} language={props.language} form={form} />
      ))}
      <div className='cast__list-action'>
        <CartNote language={props.language} />
        <button onClick={handleFinish} className='cart__list-button btn-submit-form'>
          {CART_LANGUAGE[props.language]['PROCEED_TO_CHECKOUT']}
        </button>
      </div>
    </div>
  );
}

export default CartList;

