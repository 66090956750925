import React from 'react'
import { RightOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
export default function Breadcrum(props) {
    return (
        <div className="breadcrum__main-container">
            <div className="container">
                <div className="breadcrum__main">
                    <Link to="/">
                        <i className="fas fa-home"></i>
                    </Link>
                    {props.data.map((item, index) => {
                        return (
                            <span className="breadcrum__main__item" key={index}>
                                <span>
                                    <RightOutlined />
                                </span>
                                {
                                    index === (props.data.length - 1) ?
                                        <span className="breadcrum__main__item--main">
                                            {item}
                                        </span> :
                                        <Link to={item.path} className="breadcrum__main__item--sub">
                                            {item.title}
                                        </Link>
                                }
                            </span>)
                    })}
                </div>
            </div>
        </div>
    )
}
