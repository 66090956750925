import { Col, Row } from 'antd';
import React from 'react';
import ProductCard from './Control/ProductCard';

function ProductList({ products, language }) {
  return (
    <div className='product__list' style={{ height: products.length === 0 && '100vh' }}>
      <Row gutter={[30, 40]}>
        {products.map((item) => (
          <Col key={item._id} lg={8} md={8} sm={12} xs={24}>
            <ProductCard product={item} language={language} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ProductList;
