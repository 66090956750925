export const cartHeaderInitModel = {
  Cart_Note: '',
};

export const TITLE_DATASOURCE = {
  'vi': [{
    id: 1,
    title: 'Anh',
    value: 'Mr.'
  },
  {
    id: 2,
    title: 'Chị',
    value: 'Ms.'
  }],
  'en': [{
    id: 1,
    title: 'Mr.',
    value: 'Mr.'
  },
  {
    id: 2,
    title: 'Ms.',
    value: 'Ms.'
  },
  ]
};

export const RECEIVING_METHOD = {
  'vi': [
    {
      id: 1,
      value: 'WAREHOUSE',
      title: 'Tại kho của Tập đoàn'
    },
    {
      id: 2,
      value: 'REQUESTED_PLACE',
      title: 'Tại địa chỉ của khách hàng'
    },
    {
      id: 3,
      value: 'OTHERS',
      title: 'Phương thức khác (vui lòng điền phương thức mong muốn ở mục dưới)'
    }
  ],
  'en': [
    {
      id: 1,
      value: 'WAREHOUSE',
      title: 'Pick-up at our warehouse'
    },
    {
      id: 2,
      value: 'REQUESTED_PLACE',
      title: 'Deliver to your address'
    },
    {
      id: 3,
      value: 'OTHERS',
      title: 'Others (please indicate below desired method of delivery)'
    }
  ],
};

