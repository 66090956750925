import React from 'react'
import { Link } from 'react-router-dom';

export default function NotifySuccess(props) {
    return (
        <div style={{ display: `${props.success ? 'flex' : 'none'}` }} className='login-main-notify-success'>
            <div className='notify-success' dangerouslySetInnerHTML={{ __html: props.content }}>
            </div>
            <Link to={props.button.path} className='btn-submit-form btn-submit-form-profile'>
                {props.button.title}
            </Link>
        </div>
    );
}
