export const ContactCommon = {
  vi: {
    path: '/contact',
    title: 'Liên hệ',
    buttonClose: 'Đóng lại',
    ContactSupport: {
      title: 'HỖ TRỢ KHÁCH HÀNG',
      main: {
        title: 'LIÊN HỆ',
        targetContact: {
          title: 'Mục đích liên hệ',
          label: [
            'Hỗ trợ chung',
            'Hỗ trợ về cổ đông'
          ],
          warning: 'Hãy chọn loại hỗ trợ!'
        },
        hint: `Các vấn đề khác liên quan tới Tập đoàn và Cổ đông, xin vui lòng liên hệ tại trang <a href='https://tienlensteel.com.vn/vi/contact' ><u>Liên hệ</u></a>`,
        nameCompany: {
          title: 'Tên công ty',
          warning: 'Hãy nhập tên công ty!'
        },
        call: {
          title: 'Danh xưng',
          value: [
            'Anh',
            'Chị'
          ]
        },
        taxCode: {
          title: 'Mã số thuế',
        },
        name: {
          title: 'Họ tên người liên hệ (*)',
          warning: 'Hãy nhập họ tên người liên hệ!',
        },
        phone: {
          title: 'Số điện thoại di động',
          warning: 'Hãy nhập điện thoại!',
        },
        idshareHolder: {
          title: 'Mã số cổ đông',
          hint: '(vui lòng điền vào nếu Quý khách là cổ đông hiện hữu)'
        },
        email: {
          title: 'Email (*)',
          warning: 'Email không hợp lệ!'
        },
        message: {
          title: 'Nội dung tin nhắn (*)',
          warning: 'Hãy nhập nội dung tin nhắn!'
        },
        note: '(*) Các thông tin bắt buộc',
        submit: 'Gửi thông tin'
      }
    },
    ContactMethod: {
      title: 'PHƯƠNG THỨC LIÊN HỆ',
      main: [
        ['Phòng ban', 'Phương thức liên lạc'],
        ['Các vấn đề chung', 'info@tienlen.com.vn'],
        ['Mua hàng', 'sales@tienlen.com.vn'],
        ['Hỗ trợ cổ đông', 'htcd@tienlen.com.vn'],
        ['Tuyển dụng', 'careers@tienlen.com.vn']
      ]
    },
    ContactInfo: {
      title: 'THÔNG TIN CÔNG TY',
      nameCompany: 'Công ty Cổ Phần Tập Đoàn Thép Tiến Lên',
      address: 'G4A, Khu Phố 4, Phường Tân Hiệp, Thành Phố Biên Hoà, tỉnh Đồng Nai.',
      phone: '+84 251 3823187',
      fax: '+84 251 3829043 - 3857591'
    },
    ContactModal: {
      title: 'XÁC NHẬN ĐÃ GỬI YÊU CẦU',
      main: `Yêu cầu hỗ trợ của Quý khách đã được gửi đi thành công.
            <br />
            Nhân viên Tập đoàn sẽ phản hồi lại cho Quý khách trong 48 tiếng.
            <br />
            Nếu sau 03 ngày Quý khách vẫn chưa nhận được email phản hồi, vui lòng kiểm tra thùng thư rác hoặc gửi lại yêu cầu. 
            Quý khách cũng có thể liên hệ số điện thoại <strong> 0251-3823187 </strong> để được trợ giúp.`
    }
  },
  en: {
    path: '/contact',
    title: 'Contact Us',
    buttonClose: 'Close',
    ContactSupport: {
      title: 'Contact form',
      main: {
        title: 'Contact Us',
        targetContact: {
          title: 'Type of Inquiry',
          label: [
            'General Inquiry',
            'Investor Relations'
          ],
          warning: 'Please select the type of support'
        },
        hint: `For general inquiry or inquiry relating to shares and Investor Relations, please contact us <a href='https://tienlensteel.com.vn/en/contact' ><u>here</u></a>`,
        call: {
          title: 'Title',
          value: [
            'Mr',
            'Mrs',
            'Ms'
          ]
        },
        nameCompany: {
          title: 'Company\'s name',
          warning: 'Please enter company\'s name!'
        },
        taxCode: {
          title: 'Tax code',
        },
        name: {
          title: 'Full Name (*)',
          warning: 'Please enter full name!',
        },
        phone: {
          title: 'Mobile phone number'
        },
        idshareHolder: {
          title: 'Shareholder/Stockholder\'s code',
          hint: '(please input if you are an existing shareholder/stockholder of TLH)'
        },
        email: {
          title: 'Email address(*)',
          warning: 'Invalid email!'
        },
        message: {
          title: 'Subject (*)',
          warning: 'Please enter subject!'
        },
        note: '(*) Required field',
        submit: 'Submit'
      }
    },
    ContactMethod: {
      title: 'Contact',
      main: [
        ['Division', 'Email address'],
        ['General Inquiry', 'info@tienlen.com.vn'],
        ['Sales (purchase only)', 'sales@tienlen.com.vn'],
        ['Investor Relations support', 'htcd@tienlen.com.vn'],
        ['Recruitment', 'careers@tienlen.com.vn']
      ]
    },
    ContactInfo: {
      title: 'Company\'s information',
      nameCompany: 'Tien Len Steel Corporation Joint Stock Company',
      address: 'G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province',
      phone: '+84 251 3823187',
      fax: '+84 251 3829043 - 3857591'
    },
    ContactModal: {
      title: 'Inquiry submitted',
      main: `
            Your inquiry has been successfully submitted. 
            <br />Our staff will contact you within 48 hours. 
            <br />If you 
            have not received a reply from us after 3 business days, please check your junk mail box or
            contact us again via the contact form on our website. You may also call us at <strong>(+84) 251-3823187</strong>
            for further support`
    }
  }
};
