import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ProductSpecification from './ProductSpecification';
import CartModal from '../../Cart/Control/CartModal';
import { getHighestValueByKey } from '../../../common/Method.Common';
import { PRODUCTS_LANGUAGE } from '../../Product/Language/Language';

const initialOrderCustomizationModel = {
  id: 1,
  Product_Specification: null,
  Product_Quality: null,
  Product_Origin: null,
  Product_Quantity: null,
  Specification_Product_Type: '',
  Specification_Length: '',
  Specification_Width: '',
  Specification_Height: '',
  Specification_Product_Unit: '',
  Specification_Weight_Over_Unit: '',
  Specification_Weight: '',
  Note: '',
};

function ProductOrder(props) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [orderCustomization, setOrderCustomization] = useState([{ ...initialOrderCustomizationModel }]);
  const hideModal = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const index = orderCustomization.findIndex(item => item.id === parseInt(e.target.id, 10));
      orderCustomization[index][e.target.nameQuantity] = e.target.quantityValue;
      orderCustomization[index][e.target.nameWeight] = e.target.weightValue;
      orderCustomization[index][e.target.nameHeight] = e.target.heightValue;
      orderCustomization[index][e.target.nameLength] = e.target.lengthValue;
      orderCustomization[index][e.target.nameWidth] = e.target.widthValue;
      orderCustomization[index][e.target.nameWeightOverUnit] = e.target.weightOverUnitValue;
      orderCustomization[index][e.target.nameUnit] = e.target.unitValue;
      orderCustomization[index][e.target.nameQuality] = e.target.qualityValue;
      orderCustomization[index][e.target.nameOrigin] = e.target.originValue;
      orderCustomization[index][e.target.nameNote] = e.target.noteValue;
    setOrderCustomization([...orderCustomization]);
  };

  const handleAddSpecification = () => {
    orderCustomization.push({ ...initialOrderCustomizationModel, id: getHighestValueByKey('id', orderCustomization) + 1 });
    setOrderCustomization([...orderCustomization]);
  };

  const handleRemoveSpecification = (id) => {
    const newOrderCustomization = orderCustomization.filter(item => item.id !== id);

    form.setFieldsValue({
      [`Product_Quantity_${id}_${props.product._id}`]: null,
      [`Product_Specification_${id}_${props.product._id}`]: null,
    });

    setOrderCustomization([...newOrderCustomization]);

  };

  const handleOrderNow = () => {
    form.validateFields().then(() => {
      props.onAddItem({ ...props.product, orderCustomization, language: props.language });
      history.push({ pathname: '/cart/changecustomerinfo' });
    }).catch(() => {
      // TO DO
    });
  };

  const handleAddToCart = () => {
    form.validateFields().then((values) => {
      props.onAddItem({ ...props.product, orderCustomization, language: props.language });
      setVisible(true);
    }).catch(() => {
      // TO DO
    });
  };
  const handleReset = () => {
    form.resetFields();
  }

  useEffect(() => {
    setOrderCustomization([{ ...initialOrderCustomizationModel }]);
    form.resetFields();
  }, [props.id, form, visible]);

  useEffect(() => {
    setOrderCustomization([{ ...initialOrderCustomizationModel }]);
  }, [])
  return (
    <div className='product__order'>
      <div className="product__order__title">
        {PRODUCTS_LANGUAGE[props.language]['ONLINE_ORDER']}
      </div>
      <div className="product__order__customization">
        <Form
          labelCol={{ lg: { span: 8 }, md: { span: 24 } }}
          wrapperCol={{ lg: { span: 16 }, md: { span: 24 } }}
          form={form}>
          <ProductSpecification
            product={props.product}
            orderCustomization={orderCustomization}
            handleChange={handleChange}
            handleRemoveSpecification={handleRemoveSpecification}
            language={props.language}
            reset={handleReset}
            flag={visible}
          />
        </Form>
        <div className="product__order__customization__action">
          {/* <div className="add__specification">
            <p onClick={handleAddSpecification}>+ {PRODUCTS_LANGUAGE[props.language]['ADD_SPECIFICATON']}</p>
          </div> */}
          <div className="product__order__customization__action__right">
            <button onClick={handleOrderNow} className="btn order-now">
              {PRODUCTS_LANGUAGE[props.language]['ORDER_NOW']}
            </button>
            <CartModal visible={visible} hideModal={hideModal} language={props.language}>
              <button onClick={handleAddToCart} className="btn add-to-cart">
                {PRODUCTS_LANGUAGE[props.language]['ADD_TO_CART']}
                <ShoppingCartOutlined className='cart__icon' />
              </button>
            </CartModal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductOrder;
