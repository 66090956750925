import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import './Css/Policy.css';
import PolicySalePage from './PolicySalePage';
import PolicySecurityPage from './PolicySecurityPage';
import PolicyUsagePage from './PolicyUsagePage';

export default function PolicyApp(props) {
    let { path } = useRouteMatch();
    const { language } = props;
    return (
        <>
            <Switch>
                <Route path={`${path}/`} render={(props) => <PolicySecurityPage {...props} language={language} />} exact></Route>
                <Route path={`${path}/sales`} render={(props) => <PolicySalePage {...props} language={language} />}></Route>
                <Route path={`${path}/security`} render={(props) => <PolicySecurityPage {...props} language={language} />}></Route>
                <Route path={`${path}/usage`} render={(props) => <PolicyUsagePage {...props} language={language} />}></Route>
            </Switch>
        </>
    )
}