export const CART_LANGUAGE = {
  'vi': {
    TITLE: 'Giỏ hàng',
    EMPTY_CART: 'Không có sản phẩm nào trong giỏ',
    CONTINUE_SHOPPING: 'Tiếp tục mua hàng',
    YOUR_CART: 'Giỏ hàng của bạn',
    PRODUCT: 'sản phẩm',
    CART_NOTE: 'Ghi chú thêm',

    SPECIFICATION_CODE: 'Mã quy cách',
    SPECIFICATION: 'Quy cách',
    STEEL_GRADE: 'Mác thép',
    ORIGIN: 'Xuất xứ',
    QUANTITY: 'Số lượng',
    PRODUCT_TYPE:'Chủng loại',
    SPECIFICATION_NAME: 'Quy cách',
    LENGTH: 'Độ dài (mm)',
    WIDTH: 'Bề rộng (mm)',
    HEIGHT: 'Độ dày (mm)',
    UNIT: 'Đơn vị tính',
    WEIGHT_OVER_UNIT: 'Trọng lượng (kg/m)',
    WEIGHT_OVER_UNIT_PCS: 'Trọng lượng (kg/tấm)',
    WEIGHT:'Khối lượng (kg)',

    DELETE: 'Xóa',
    ADD_SPECIFICATION: 'Thêm quy cách',
    PROCEED_TO_CHECKOUT: 'Tiến hành đặt hàng',

    CUSTOMER_INFO: 'Thông tin khách hàng',
    ACCOUNT_ALREADY: 'Bạn đã có tài khoản?',
    DO_NOT_HAVE_ACCOUNT: 'Bạn chưa có tài khoản?',
    SIGN_IN: 'Đăng nhập',
    REGISTER: 'Đăng ký',
    REGISTER_NOW: 'Đăng ký ngay',
    FULLNAME: 'Họ và tên',
    PHONE_NUMBER: 'Điện thoại',
    RECEIVING_METHOD: 'Phương thức nhận hàng',
    ADDRESS_OR_METHOD: 'Địa chỉ hoặc phương thức giao hàng',
    ADDRESS_OR_METHOD_DESIRED: 'Địa chỉ / phương thức giao hàng mong muốn',
    ADDRESS: 'Địa chỉ giao hàng',
    CUSTOMER_TITLE: 'Danh xưng',

    CUSTOMER_ORDER: 'Đơn hàng',
    PLACE_ORDER: 'Đặt hàng',
    EDIT: 'Sửa',
    CONTINUE: 'Tiếp tục',
    ORDER_SUCCESS: `
      Quý khách đã tiến hành đặt hàng thành công. 
      Chuyên viên phụ trách sẽ phản hồi anh/chị trong vòng 24 tiếng thông qua số điện thoại hoặc địa chỉ email quý vị đã cung cấp. 
      Nếu anh/chị không nhận được thông tin phúc đáp trong vòng 24 tiếng, kính mong anh/chị kiểm tra thùng thư rác hoặc gửi lại yêu cầu 
      và thông tin cần hỗ trợ cho Tập Đoàn qua trang web, gửi email trực tiếp tới sales@tienlen.com.vn, hoặc gọi điện đến số  (0251) 382 3187 để được hỗ trợ ngay.
    `,
    CONTACT_MESSAGE: 'Chúng tôi sẽ sớm liên hệ với bạn',
    BACK_TO_CART: 'Quay lại',
    BACK_TO_HOME_PAGE: 'Quay lại trang chủ',

    ADDRESS_OR_METHOD_HINT: `Quý khách có thể tham khảo các phương thức giao hàng tại <a target='_blank' href='/vi/policy/sales#sales-4'>đây</a>`,

    FULLNAME_REQUIRED: 'Vui lòng nhập họ và tên',
    EMAIL_INVALID: 'Email không hợp lệ',
    PHONE_NUMBER_REQUIRED: 'Vui lòng nhập số điện thoại',
    RECEIVING_METHOD_REQUIRED: 'Vui lòng chọn phương thức nhận hàng',
    ADDRESS_REQUIRED: 'Vui lòng nhập địa chỉ giao hàng',
    ADDRESS_OR_METHOD_REQUIRED: 'Vui lòng nhập địa chỉ hoặc phương thức giao hàng mong muốn',
    CUSTOMER_TITLE_REQUIRED: 'Vui lòng chọn danh xưng',
    ADD_TO_CART_SUCCESSFULLY: 'Thêm vào giỏ hàng thành công.',
    NOTIFICATION: 'Thông báo',
  },
  'en': {
    ADD_TO_CART_SUCCESSFULLY: 'Add to cart successfully.',
    NOTIFICATION: 'Notification',
    TITLE: 'Cart',
    EMPTY_CART: 'Your cart is empty',
    CONTINUE_SHOPPING: 'Continue Shopping',
    YOUR_CART: 'Your cart',
    PRODUCT: 'item(s)',
    CART_NOTE: 'Notes',

    SPECIFICATION_CODE: 'Specification Code',
    SPECIFICATION: 'Specification',
    STEEL_GRADE: 'Steel grade',
    ORIGIN: 'Origin',
    QUANTITY: 'Quantity',
    PRODUCT_TYPE:'Product Type',
    SPECIFICATION_NAME: 'Specification',
    LENGTH: 'Length (mm)',
    WIDTH: 'Width (mm)',
    HEIGHT: 'Height (mm)',
    UNIT: 'Unit',
    WEIGHT_OVER_UNIT: 'Weight (kg/m)',
    WEIGHT_OVER_UNIT_PCS: 'Weight (kg/pc)',
    WEIGHT:'Weight (kg)',

    DELETE: 'Delete',
    ADD_SPECIFICATION: 'Add',
    PROCEED_TO_CHECKOUT: 'Proceed to checkout',
    CUSTOMER_INFO: 'Customer\'s information',
    ACCOUNT_ALREADY: 'Have an account?',
    DO_NOT_HAVE_ACCOUNT: 'Don\'t have an account?',

    SIGN_IN: 'Sign in',
    REGISTER: 'Register',
    REGISTER_NOW: 'Register now',
    FULLNAME: 'Full name',
    PHONE_NUMBER: 'Phone number',
    RECEIVING_METHOD: 'Receiving method',
    ADDRESS_OR_METHOD: 'Address or method of delivery',
    ADDRESS_OR_METHOD_DESIRED: 'Address or desired method of delivery',
    ADDRESS: 'Address',
    CUSTOMER_TITLE: 'Title',

    CUSTOMER_ORDER: 'Customer\'s order',
    PLACE_ORDER: 'Place an order',
    EDIT: 'Edit',
    CONTINUE: 'Continue',
    ORDER_SUCCESS: `
      Your order has been successfully placed. A sales executive will contact you within 24 hours via the phone number or email address you provided. 
      If you do not hear back from us within 24 hours, please check your junk mail box or resend us your order through the website, 
      our mailbox (sales@tienlen.com.vn), or call us directly through our phone number (0251) 382 3187 for further assistance.
    `,
    CONTACT_MESSAGE: 'Our staff will contact you at the soonest possible',
    BACK_TO_CART: 'Back',
    BACK_TO_HOME_PAGE: 'Back to home page',

    ADDRESS_OR_METHOD_HINT: `Click <a target='_blank' href='/en/policy/sales#sales-4'>here</a> to view all available method of delivery`,

    FULLNAME_REQUIRED: 'Please enter your full name',
    EMAIL_INVALID: 'Invalid email',
    PHONE_NUMBER_REQUIRED: 'Please enter your phone number',
    RECEIVING_METHOD_REQUIRED: 'Please enter receiving method',
    ADDRESS_REQUIRED: 'Please enter your delivery address',
    CUSTOMER_TITLE_REQUIRED: 'Please select title',
    ADDRESS_OR_METHOD_REQUIRED: 'Please enter address or desired method of delivery'
  }
};
