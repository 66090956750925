export const PRODUCTS_LANGUAGE = {
  'vi': {
    PATH: '/products',
    TITLE: 'Sản phẩm',
    SEARCH_PRODUCT: 'Tìm kiếm sản phẩm',
    PRICE: 'Giá',
    CONTACT: 'Liên hệ',
    ONLINE_ORDER: 'Đặt hàng trực tuyến',
    ADD_SPECIFICATON: 'Thêm quy cách',
    SPECIFICATION: 'Quy cách',
    STEEL_GRAGDE: 'Mác thép',
    ORIGIN: 'Xuất xứ',
    QUANTITY: 'Số lượng',
    ORDER_NOW: 'Đặt hàng ngay',
    ADD_TO_CART: 'Thêm vào giỏ hàng',
    OTHER_PRODUCTS: 'Các sản phẩm khác',
    CONTACT_ORDER: 'Liên hệ đặt hàng',

    REQUIRED_SPECIFICATION_CODE: 'Vui lòng nhập mã quy cách',
    REQUIRED_SPECIFICATION: 'Vui lòng nhập quy cách',
    REQUIRED_TYPE: 'Vui lòng nhập chủng loại',
    REQUIRED_QUANTITY: 'Số lượng không hợp lệ',
    REQUIRED_LENGTH: 'Vui lòng nhập độ dài',
    REQUIRED_WIDTH: 'Vui lòng nhập bề rộng',
    REQUIRED_HEIGHT: 'Vui lòng nhập độ dày',
    REQUIRED_WEIGHT_OVER_UNIT: 'Vui lòng nhập trọng lượng',
    REQUIRED_UNIT: 'Vui lòng nhập đơn vị tính',

    SPECIFICATION_CODE: 'Mã quy cách',
    PRODUCT_TYPE: 'Chủng loại',
    SPECIFICATION_NAME: 'Quy cách',
    LENGTH: 'Độ dài (mm)',
    WIDTH: 'Bề rộng (mm)',
    HEIGHT: 'Độ dày (mm)',
    UNIT: 'Đơn vị tính',
    WEIGHT_OVER_UNIT: 'Trọng lượng (kg/m)',
    WEIGHT_OVER_UNIT_PCS: 'Trọng lượng (kg/tấm)',
    WEIGHT: 'Khối lượng (kg)',
    NOTE: 'Ghi chú',
    PROVISINAL: 'Tạm tính',
    NOTIFICATION: "Thông báo",
    WARNING: "Cảnh báo",
    WARNING_WAS_ADDED(type, name) {
      return `Chủng loại ${type}, quy cách ${name} đã thêm vào giỏ hàng.`
    },
    MISSINGPARAMETER: 'Quy cách bị thiếu giá trị chiều dài hoặc trọng lượng không thể tính khối lượng.',
    
  },
  'en': {
    WARNING: "Warning",
    WARNING_WAS_ADDED(type, name) {
      return `Product type ${type}, specification ${name} has been added to cart successfully.`
    },
    PATH: '/products',
    TITLE: 'Products',
    SEARCH_PRODUCT: 'Search',
    PRICE: 'Price',
    CONTACT: 'Contact',
    ADD_SPECIFICATON: 'Add',
    ONLINE_ORDER: 'Online order',
    SPECIFICATION: 'Specification',
    STEEL_GRAGDE: 'Steel grade',
    ORIGIN: 'Origin',
    QUANTITY: 'Quantity',
    ORDER_NOW: 'Order now',
    ADD_TO_CART: 'Add to cart',
    OTHER_PRODUCTS: 'Other products',
    CONTACT_ORDER: 'Contact',

    REQUIRED_SPECIFICATION_CODE: 'Please enter product specification code',
    REQUIRED_SPECIFICATION: 'Please enter product specification',
    REQUIRED_QUANTITY: 'Please enter quantity',
    REQUIRED_TYPE: 'Please enter type',
    REQUIRED_LENGTH: 'Please enter length',
    REQUIRED_WIDTH: 'Please enter width',
    REQUIRED_HEIGHT: 'Please enter height',
    REQUIRED_WEIGHT_OVER_UNIT: 'Please enter weight',
    REQUIRED_UNIT: 'Please enter unit',

    SPECIFICATION_CODE: 'Specification Code',
    PRODUCT_TYPE: 'Product Type',
    SPECIFICATION_NAME: 'Specification',
    LENGTH: 'Length (mm)',
    WIDTH: 'Width (mm)',
    HEIGHT: 'Thickness (mm)',
    UNIT: 'Unit',
    WEIGHT_OVER_UNIT: 'Weight (kg/m)',
    WEIGHT_OVER_UNIT_PCS: 'Weight (kg/pc)',
    WEIGHT: 'Weight (kg)',
    NOTE: 'Note',
    PROVISINAL: 'Approximate',
    MISSINGPARAMETER: 'Missing parameter. Can not show weight.'
  }
};
