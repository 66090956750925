import React, { useEffect, } from 'react';
import { Link, useHistory } from 'react-router-dom';
import backgroundTitle from '../../asset/img/img-8.jpg';
import { LoginCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input } from 'antd';
import './Css/Login.css';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoginService } from './Services/LoginServices';
import { LOGIN_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../common/Constant.Common';
import { store } from 'react-notifications-component';

export default function SignInPage(props) {
    const { language } = props;
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const onSubmit = async (values) => {
        const user = await LoginService.login(values.username, values.password);
        if (user.returnCode === RESULT_CODES.SUCCESS) {
            if (user.token) {
                localStorage.setItem('accessToken', JSON.stringify(user.token));
                const userInfo = await LoginService.getUserInfor();
                if (userInfo.returnCode === RESULT_CODES.SUCCESS) {
                    localStorage.setItem('userInfo', JSON.stringify(userInfo.data));
                    const customerModel = {
                        Customer_Fullname: userInfo.data.User_Username,
                        Customer_Email: userInfo.data.User_Email,
                        Customer_PhoneNumber: userInfo.data.User_Phone,
                        Customer_Address: `${userInfo.data.User_Address}`,
                        Customer_Title: userInfo.data.User_Call,
                    };
                    localStorage.setItem('customermodel', JSON.stringify(customerModel));
                    history.push('/');
                }
            }
        } else {
            store.addNotification({
                title: user.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
                message: `${LOGIN_RETURN_CODE_DESCRIPTION[parseInt(user.returnCode, 10)]}`,
                type: user.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };
    return (
        <Helmet title={LoginCommon[language].title}>
            <Breadcrum data={[
                LoginCommon[language].title
            ]}
            ></Breadcrum>
            <TitleBreadcrum title={LoginCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
            <Form
                className='login-main'
                onFinish={onSubmit}
            >
                <h3>{LoginCommon[language].SignInLoginField.title}</h3>
                <div className='login-main__field-title'>{LoginCommon[language].SignInLoginField.main.email.title}</div>
                <Form.Item
                    name='username'
                    rules={[{ required: true, type: 'email', message: LoginCommon[language].SignInLoginField.main.email.warning }]}
                >
                    <Input type='email'></Input>
                </Form.Item>
                <div className='login-main__field-title'>{LoginCommon[language].SignInLoginField.main.password.title}</div>
                <Form.Item
                    name='password'
                    rules={[{ required: true, message: LoginCommon[language].SignInLoginField.main.password.warning }]}
                >
                    <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}></Input.Password>
                </Form.Item>
                <button type='submit' className='btn-submit-form'>
                    {LoginCommon[language].submit}
                </button>
                <div className='login-main__signIn-hint'>
                    <Link to={`/${language}/forget`} className='login-main__link'>
                        {LoginCommon[language].forgetPassword}
                    </Link>
                    <p>
                        {LoginCommon[language].questionRegister}<Link to={`/${language}/register`} className='login-main__link'>{LoginCommon[language].register}</Link>
                    </p>
                </div>
            </Form>
        </Helmet>
    );
}
