
import { authHeader } from '../../../helper/authHeader';

export const ProductService = {
  landingPageSearch,
  getById,
  geolocation,
  searchProductType,
  searchSpecification,
  searchUnit,
};

function landingPageSearch(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/Product/landingPageSearch`, requestOptions).then(handleResponse);
}

function getById(_id) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    headers,
    method: 'GET',
  };
  return fetch(`${process.env.REACT_APP_API_URL}/Product/getById/${_id}`, requestOptions).then(handleResponse);
}
function geolocation() {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    headers,
    method: 'GET',
  };
  return fetch(`https://geolocation.1trust.app/cookieconsentpub/v1/geo/location`, requestOptions).then(handleResponse);
}

function searchProductType(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/ProductType/search`, requestOptions).then(handleResponse);
}
function searchSpecification(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/Specification/search`, requestOptions).then(handleResponse);
}
function searchUnit(searchModel, searchOption) {
  const model = {
    searchModel,
    searchOption
  };

  const auth = authHeader();
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', auth);

  const requestOptions = {
    headers,
    method: 'POST',
    body: JSON.stringify(model)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/Unit/search`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
