import React from 'react';

function Footer() {
  return (
    <footer><div className="checkout__footer">
      <div className="container">
        <div className="checkout__footer__main">
          <h2>
            Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved
          </h2>
        </div>
      </div>
    </div>
    </footer>
  );
}

export default React.memo(Footer);
