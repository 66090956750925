export const ContactService = {
    sendMail,
    sendMailProductContact,
};

const content = 'Content-Type';
const Type = 'application/json';

function sendMail(values) {
    const headers = new Headers();
    headers.append(content, Type);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(values)
    };
    return fetch(process.env.REACT_APP_API_URL + `/sendMailContact`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function sendMailProductContact(values) {
    const headers = new Headers();
    headers.append(content, Type);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(values)
    };
    return fetch(process.env.REACT_APP_API_URL + `/sendMailProductContact`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}