import React, { useState, useEffect } from 'react';
import { Link,useRouteMatch } from 'react-router-dom';
import { useWindowDimensions } from '../CustomHook/useWindowDimensions';
import { HomeCommon } from '../Model/HomeModel';

export default function HomeSliderItem(props) {
    const { width } = useWindowDimensions();
    const [background, setBackground] = useState(null);
    const { path } = useRouteMatch();

    useEffect(() => {
        if (width >= 1024) return setBackground(props.background);

        if (width >= 768) return setBackground(props.backgroundTablet);

        setBackground(props.backgroundMobile);
    }, [
        width,
        props.backgroundMobile,
        props.backgroundTablet,
        props.background,
    ]);
    return (
        <div className="home__heading__slide__item" style={{ height: `100vh` }}>
            <div
                className="home__heading__slide__item-main"
                style={{ backgroundImage: `url('${background}')` }}
            >
                <div style={{ height: "57vh",width:'100%' ,display:'flex',justifyContent:'flex-end'}}>
                    <div className={ props.subtitle ==='' ? "home__heading__slide__item__background home__heading__slide__item__background__hidden" : "home__heading__slide__item__background"} >
                        <div
                            style={{
                                height: "100%",
                                width: "100%",

                            }}
                        >
                            <div className="home__heading__slide__item__title">
                                <div
                                    className="home__heading__slide__item__title__main"
                                    dangerouslySetInnerHTML={{
                                        __html: props.subtitle,
                                    }}
                                />

                                <a
                                    href={props.link}
                                    style={{bottom:props.subtitle ? 100 : 50}}
                                    className="home__main__button home__main__button--banner"
                                    target="_blank"
                                >
                                    <span>
                                        {
                                            HomeCommon[props.language]
                                                .buttonBanner
                                        }
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
