import { openNotification } from "../../../common/Method.Common";
import { CART_LANGUAGE } from "../Language/Language";

const updateCart = (cart) => {
  localStorage.setItem('cart', JSON.stringify(cart));
};

const updateCartHeader = (cartHeader) => {
  localStorage.setItem('cartHeader', JSON.stringify(cartHeader));
};

export const totalItem = cart => {
  if (!cart) return;

  if (cart.length === 0) return 0;

  return cart.reduce((prev, cur) => prev + (!!cur.orderCustomization ? cur.orderCustomization.length : 0), 0);
};

export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      const index = state.cartItems.findIndex(item => item._id === action.payload._id);
      if (index === -1) {
        const orderCustomization = [...action.payload.orderCustomization];
        state.cartItems.push({
          ...action.payload,
          orderCustomization,
          quantity: 1
        });
      } else {
        const orderCustomization = {
          ...action.payload.orderCustomization[0],
        }
        const orderCustomizationsOld = state.cartItems[index].orderCustomization;
        const length = orderCustomizationsOld.length;
        let postionAlike = -1;
        const orderCustomizationAlike = orderCustomizationsOld.find((item, i) => {
          postionAlike = i;
          return (item[`Specification_Product_Type_${i + 1}_${state.cartItems[index]?._id}`]
            === orderCustomization[`Specification_Product_Type_1_${state.cartItems[index]?._id}`]
            &&
            item[`Product_Specification_${i + 1}_${state.cartItems[index]?._id}`]
            === orderCustomization[`Product_Specification_1_${state.cartItems[index]?._id}`]
            &&
            item[`Specification_Weight_Over_Unit_${i + 1}_${state.cartItems[index]?._id}`]
            == orderCustomization[`Specification_Weight_Over_Unit_1_${state.cartItems[index]?._id}`]
            )
        })
        if (!!orderCustomizationAlike) {
          orderCustomizationAlike[`Product_Quantity_${postionAlike + 1}_${state.cartItems[index]?._id}`]
            = Number.parseInt(orderCustomizationAlike[`Product_Quantity_${postionAlike + 1}_${state.cartItems[index]?._id}`], 10) +
            Number.parseInt(orderCustomization[`Product_Quantity_1_${state.cartItems[index]?._id}`], 10);
          orderCustomizationAlike[`Specification_Weight_${postionAlike + 1}_${state.cartItems[index]?._id}`]
            = Number.parseInt(orderCustomizationAlike[`Specification_Weight_${postionAlike + 1}_${state.cartItems[index]?._id}`], 10) +
            Number.parseInt(orderCustomization[`Specification_Weight_1_${state.cartItems[index]?._id}`], 10);
        } else {
          // define key for orderCustomization
          const quantityKey = `Product_Quantity_${length + 1}_${state.cartItems[index]?._id}`
          const LengthKey = `Specification_Length_${length + 1}_${state.cartItems[index]?._id}`
          const Product_TypeKey = `Specification_Product_Type_${length + 1}_${state.cartItems[index]?._id}`
          const Product_UnitKey = `Product_Unit_${length + 1}_${state.cartItems[index]?._id}`
          const SpecificationKey = `Product_Specification_${length + 1}_${state.cartItems[index]?._id}`
          const WidthKey = `Specification_Width_${length + 1}_${state.cartItems[index]?._id}`
          const HeightKey = `Specification_Height_${length + 1}_${state.cartItems[index]?._id}`
          const WeightKey = `Specification_Weight_${length + 1}_${state.cartItems[index]?._id}`
          const Weight_Over_UnitKey = `Specification_Weight_Over_Unit_${length + 1}_${state.cartItems[index]?._id}`
          const Quality_Key = `Product_Quality_${length + 1}_${state.cartItems[index]?._id}`
          const Origin_Key = `Product_Origin_${length + 1}_${state.cartItems[index]?._id}`
          const Note_Key = `Note_${length + 1}_${state.cartItems[index]?._id}`

          
          orderCustomization.id = length + 1;
          orderCustomization[quantityKey] = orderCustomization[`Product_Quantity_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Product_Quantity_1_${state.cartItems[index]?._id}`]

          orderCustomization[LengthKey] = orderCustomization[`Specification_Length_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Length_1_${state.cartItems[index]?._id}`]

          orderCustomization[Product_TypeKey] = orderCustomization[`Specification_Product_Type_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Product_Type_1_${state.cartItems[index]?._id}`]

          orderCustomization[Product_UnitKey] = orderCustomization[`Specification_Product_Unit_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Product_Unit_1_${state.cartItems[index]?._id}`]

          orderCustomization[SpecificationKey] = orderCustomization[`Product_Specification_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Product_Specification_1_${state.cartItems[index]?._id}`]

          orderCustomization[WidthKey] = orderCustomization[`Specification_Width_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Width_1_${state.cartItems[index]?._id}`]

          orderCustomization[HeightKey] = orderCustomization[`Specification_Height_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Height_1_${state.cartItems[index]?._id}`]

          orderCustomization[WeightKey] = orderCustomization[`Specification_Weight_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Weight_1_${state.cartItems[index]?._id}`]

          orderCustomization[Weight_Over_UnitKey] = orderCustomization[`Specification_Weight_Over_Unit_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Specification_Weight_Over_Unit_1_${state.cartItems[index]?._id}`]

          orderCustomization[Quality_Key] = orderCustomization[`Product_Quality_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Product_Quality_1_${state.cartItems[index]?._id}`]

          orderCustomization[Origin_Key] = orderCustomization[`Product_Origin_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Product_Origin_1_${state.cartItems[index]?._id}`]
          
          orderCustomization[Note_Key] = orderCustomization[`Note_1_${state.cartItems[index]?._id}`];
          delete orderCustomization[`Note_1_${state.cartItems[index]?._id}`]

          state.cartItems[index].orderCustomization.push(orderCustomization)
          state.cartItems[index].quantity += 1;
        }
      }
      const language = action.payload.language || "vi";
      updateCart(state.cartItems);
      openNotification({ title: CART_LANGUAGE[language].NOTIFICATION, message: CART_LANGUAGE[language].ADD_TO_CART_SUCCESSFULLY })
      return {
        ...state,
        totalItem: totalItem(state.cartItems),
        cartItems: [...state.cartItems]
      };
    case 'INCREASE':
      state.cartItems[state.cartItems.findIndex(item => item._id === action.payload._id)].quantity++;
      updateCart(state.cartItems);
      return {
        ...state,
        totalItem: totalItem(state.cartItems),
        cartItems: [...state.cartItems]
      };
    case 'DECREASE':
      state.cartItems[state.cartItems.findIndex(item => item._id === action.payload._id)].quantity--;
      updateCart(state.cartItems);
      return {
        ...state,
        totalItem: totalItem(state.cartItems),
        cartItems: [...state.cartItems]
      };
    case 'UPDATE_ITEM':
      state.cartItems[state.cartItems.findIndex(item => item._id === action.payload._id)] = action.payload;
      updateCart(state.cartItems);
      return {
        ...state,
        totalItem: totalItem(state.cartItems),
        cartItems: [...state.cartItems]
      };
    case 'REMOVE_ITEM':
      const cart = state.cartItems.filter(item => item._id !== action.payload._id);
      if (cart.length === 0) {
        state.cartHeader.Cart_Note = '';
        updateCartHeader(state.cartHeader);
      }
      updateCart(cart);
      return {
        ...state,
        totalItem: totalItem(cart),
        cartItems: [...cart],
        cartHeader: { ...state.cartHeader }
      };
    case 'CLEAR':
      state.cartHeader.Cart_Note = '';
      updateCartHeader(state.cartHeader);
      updateCart([]);
      return {
        ...state,
        totalItem: totalItem([]),
        cartHeader: { ...state.cartHeader },
        cartItems: []
      };
    case 'UPDATE_NOTE':
      state.cartHeader.Cart_Note = action.payload;
      updateCartHeader(state.cartHeader);
      return {
        ...state,
        cartHeader: { ...state.cartHeader }
      };
    default:
      return state;
  }
};
