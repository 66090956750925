import React, { useEffect } from 'react';
import Helmet from '../Layout/Helmet';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import Breadcrum from '../Layout/Breadcrum';
import { PolicyCommon } from './Model/PolicyModel';
import backgroundTitle from '../../asset/img/img-8.jpg';
import BGNews from '../../asset/img/BGnews.png';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

export default function PolicySecurityPage(props) {
    const { language } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Helmet title={PolicyCommon[language].PolicySecurity.title}>
            <Breadcrum data={[
                PolicyCommon[language].title,
            ]}
            ></Breadcrum>
            <TitleBreadcrum title={PolicyCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
            <div className="policy-navigation">
                <div className="container">
                    <Row>
                        <Col lg={8} md={8} sm={24} xs={24} className="policy-navigation__item active">
                            <Link to={`/${language}${PolicyCommon[language].PolicySecurity.path}`} className="policy-navigation__item__link">{PolicyCommon[language].PolicySecurity.title}</Link>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24} className="policy-navigation__item">
                            <Link to={`/${language}${PolicyCommon[language].PolicyUsage.path}`} className="policy-navigation__item__link">{PolicyCommon[language].PolicyUsage.title}</Link>
                        </Col>
                        <Col lg={8} md={8} sm={24} xs={24} className="policy-navigation__item">
                            <Link to={`/${language}${PolicyCommon[language].PolicySale.path}`} className="policy-navigation__item__link">{PolicyCommon[language].PolicySale.title}</Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="policy">
                <div className="home__main__products--overlay" style={{
                    background: `url(${BGNews})`,
                }}
                ></div>
                <div className="container">
                    <h2>{PolicyCommon[language].PolicySecurity.title}</h2>
                    <div className="policy__content" dangerouslySetInnerHTML={{ __html: PolicyCommon[language].PolicySecurity.content }}>
                    </div>
                    <div className="policy__decor"></div>
                </div>
            </div>
        </Helmet >
    )
}
