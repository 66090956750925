import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Collapse } from 'antd';
import { contactMedia, FooterCommon, FooterCommonMobile } from './Model/LayoutModel';
import background from '../../asset/img/footer.jpg';

const { Panel } = Collapse;

export default function Footer(props) {

  const { language } = props;

  return (
    <footer>
      <div className='footer-decor'></div>
      <div className='footer' style={{ backgroundImage: `url('${background}')` }}>
        <div className='footer__overlay'></div>
        <div className='container'>
          <Row className='footer__desktop'>
            <Col lg={10} md={10} sm={24} xs={24}>
              <div className='footer__item'>
                {FooterCommon[language].FooterTitle.main.map((item, index) => {
                  if (item.hasOwnProperty('link')) {
                    return (
                      <a href={item.link}
                        className='footer__item__main' key={`${index}-footer-main`}
                        target='_blank' rel='noopener noreferrer'
                      >
                        {item.title}
                      </a>
                    );
                  }
                  return (
                    <Link to={`/${language}${item.path}`} className='footer__item__main' key={`${index}-footer-main`}>
                      {item.title}
                    </Link>
                  );
                })}
                {FooterCommon[language].FooterTitle.sub.map((item, index) => {
                  return (
                    <Link to={`/${language}${item.path}`} className='footer__item__sub' key={`${index}-footer-sub`}>
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col lg={14} md={14} sm={24} xs={24}>
              <div className='footer__item-main'>
                <img src={FooterCommon[language].FooterEnding.logo} alt='logo'></img>
                <div className='footer__item-main__detail'>
                  <h4>{FooterCommon[language].FooterEnding.title}</h4>
                    <p >
                      <i className='fas fa-map-marker-alt' ></i>
                  <a href="https://goo.gl/maps/1d47FRPXZmn22rZ57" target="_blank">
                      <span dangerouslySetInnerHTML={{ __html: FooterCommon[language].FooterEnding.address }} style={{color: 'black'}}>
                      </span>
                  </a>
                    </p>
                    <p className='footer__item__phone-number'>
                      <i className='fas fa-phone'></i>
                  <a href={`tel:${FooterCommon[language].FooterEnding.phone}`} style={{color: 'black'}}>
                      {FooterCommon[language].FooterEnding.phone}
                  </a>
                    </p>

                  <p>
                    <i className='fas fa-clock'></i>
                    <span dangerouslySetInnerHTML={{ __html: FooterCommon[language].FooterEnding.workTime }}>
                    </span>
                  </p>
                </div>
              </div>
              <div className='footer__item-media'>
                <a href={`${contactMedia.facebook}`} target='_blank' rel='noopener noreferrer'>
                  <div className='footer__item-media__icon'>
                    <i className='fab fa-facebook-f'></i>
                  </div>
                </a>
                <a href={`mailto:${contactMedia.email}`}>
                  <div className='footer__item-media__icon'>
                    <i className='fas fa-envelope'></i>
                  </div>
                </a>
                <a href={`tel:${contactMedia.phone}`}>
                  <div className='footer__item-media__icon'>
                    <i className='fas fa-phone-alt'></i>
                  </div>
                </a>
              </div>
            </Col>
          </Row>

          <div className='footer__mobile'>
            <div className='footer__mobile__nav'>
              {FooterCommonMobile[language].FooterTitle.main.map((item, index) => {
                if (item.link) {
                  return (
                    <a href={item.link}
                      target='_blank' rel='noopener noreferrer'
                      className='footer__mobile__nav__main' key={`footer__mobile__nav__main__${index}`}
                    >
                      {item.title}
                    </a>
                  );
                } else {
                  if (item.sub) {
                    return (
                      <Collapse expandIconPosition='right' key={item.title} ghost expandIcon={({ isActive }) => {
                        return isActive ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />;
                      }}>
                        <Panel header={item.title} key={item.title} className='footer__mobile__nav__main'>
                          {item.sub.map((subItem, subIndex) => {
                            return (
                              <Link to={`/${language}${subItem.path}`} className='footer__mobile__nav__sub' key={`footer__mobile__nav__sub__${subIndex}`}>
                                {subItem.title}
                              </Link>
                            );
                          })}
                        </Panel>
                      </Collapse>
                    );
                  }
                  return (
                    <Link to={`/${language}${item.path}`} className='footer__mobile__nav__main' key={`footer__mobile__nav__main__${index}`}>
                      {item.title}
                    </Link>
                  );
                }
              })}
            </div>
            <div className="footer__mobile__company">
              <img src={FooterCommon[language].FooterEnding.logo} alt='logo'></img>
              <div className='footer__mobile__company__info'>
                <h4>{FooterCommon[language].FooterEnding.title}</h4>
                  <p className='footer__mobile__company__info__flex'>
                    <i className='fas fa-map-marker-alt'></i>
                <a href="https://goo.gl/maps/1d47FRPXZmn22rZ57" target="_blank">
                    <span dangerouslySetInnerHTML={{ __html: FooterCommon[language].FooterEnding.address }} style={{color: 'black'}}>
                    </span>
                </a>
                  </p>
                  <p className='footer__item__phone-number'>
                    <i className='fas fa-phone'></i>
                <a href={`tel:${FooterCommon[language].FooterEnding.phone}`} style={{color: 'black'}}>
                    {FooterCommon[language].FooterEnding.phone}
                </a>
                  </p>
                <p className='footer__mobile__company__info__flex'>
                  <i className='fas fa-clock' ></i>
                  <span dangerouslySetInnerHTML={{ __html: FooterCommon[language].FooterEnding.workTime }}>
                  </span>
                </p>
              </div>
            </div>
            <div className="footer__mobile__media">
              <a href={`${contactMedia.facebook}`} target='_blank' rel='noopener noreferrer'>
                <div className='footer__item-media__icon'>
                  <i className='fab fa-facebook-f'></i>
                </div>
              </a>
              <a href={`mailto:${contactMedia.email}`}>
                <div className='footer__item-media__icon'>
                  <i className='fas fa-envelope'></i>
                </div>
              </a>
              <a href={`tel:${contactMedia.phone}`}>
                <div className='footer__item-media__icon'>
                  <i className='fas fa-phone-alt'></i>
                </div>
              </a>
            </div>
          </div>

        </div>
      </div>
      <div className='footer--policy'>
        <div className='container'>
          <div className='footer--policy__main'>
            <h2>
              Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved
            </h2>
          </div>
        </div>
      </div>
    </footer>
  );
}
