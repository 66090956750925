import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import ProductList from './ProductList';
import backgroundTitle from '../../asset/img/img-8.jpg';
import productbg from '../../asset/img/BGnews.png';
import { PRODUCTS_LANGUAGE } from './Language/Language';
import { RESULT_CODES } from '../../common/Constant.Common';
import { ProductService } from './Service/Product.Service';
import './Css/Style.css';

function ProductPage({ language }) {
  const inputRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [searchOption, setSearchOption] = useState({ page: 1, sort: { UpdatedDate: -1 }, language });
  // const [searchOption, setSearchOption] = useState({ page: 1, sort: { CreatedDate: -1 }, language });

  useEffect(() => {
    setSearchOption({ page: 1, sort: { UpdatedDate: -1 }, language });
    // setSearchOption({ page: 1, sort: { CreatedDate: -1 }, language });
  }, [language]);

  const handleSearch = useCallback(() => {
    const searchModel = { Product_Name: inputRef.current.state.value, Status: true };
    ProductService.landingPageSearch(searchModel, searchOption).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const data = result.result.docs;
      setProducts(data);
    }).catch(() => {
      // TO DO
    });
  }, [searchOption]);

  const handlePressEnter = useCallback((e) => {
    if (e.key !== 'Enter') return;

    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', handlePressEnter);

    return () => {
      window.removeEventListener('keypress', handlePressEnter);
    };
  }, [handlePressEnter]);

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Helmet title={PRODUCTS_LANGUAGE[language]['TITLE']}>
      <Breadcrum data={[PRODUCTS_LANGUAGE[language]['TITLE']]} />
      <TitleBreadcrum title={PRODUCTS_LANGUAGE[language]['TITLE']} background={backgroundTitle} />
      <div className="product">
        <div className="product__main">
          <div className="product__main__wrapper">
            <div className="product__background" style={{
              backgroundImage: ` linear-gradient(to top, rgba(255, 255, 255, 0), 
            rgba(255, 255, 255, 1)), url(${productbg}) `
            }} />
            <div className="container">
              <div className="product__search">
                <h3>{PRODUCTS_LANGUAGE[language]['TITLE']}</h3>
                <div className="product__search__main">
                  <Input ref={inputRef} allowClear placeholder={PRODUCTS_LANGUAGE[language]['SEARCH_PRODUCT']} className='product__search__input' />
                  <div onClick={handleSearch} className="product__search__btn">
                    <SearchOutlined />
                  </div>
                </div>
              </div>
              <ProductList products={products} language={language} />
            </div>
          </div>
        </div>
      </div>
    </Helmet>
  );
}

export default ProductPage;

