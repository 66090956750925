import banner1 from '../../../asset/img/001_Banner/banner-1.jpg';
import banner2 from '../../../asset/img/001_Banner/banner-2.jpg';
import banner3 from '../../../asset/img/001_Banner/banner-3.jpg';
import banner4 from '../../../asset/img/001_Banner/banner-4.jpg';

// Banner Tablet
import bannerTablet1 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet.jpg';
import bannerTablet2 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-2.jpg';
import bannerTablet3 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-3.jpg';
import bannerTablet4 from '../../../asset/img/001_Banner/banner_tienlen-v3-tablet-4.jpg';

// Banner Mobile
import bannerMobile1 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile.jpg';
import bannerMobile2 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-2.jpg';
import bannerMobile3 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-3.jpg';
import bannerMobile4 from '../../../asset/img/001_Banner/banner_tienlen-v3-mobile-4.jpg';


export const HomeCommon = {
  vi: {
    title: 'Trang chủ',
    hiddenTitle: 'Thép Tiến Lên',
    hiddenSubTitle: 'chung tay kiến tạo',
    buttonSeeMore: 'Xem thêm',
    buttonDetail: 'Chi tiết',
    buttonBanner: 'Sản phẩm chúng tôi cung cấp',
    path: '/vi',
    slider: [
      {
        title: 'THÉP TIẾN LÊN',
        subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
        background: banner1,
        backgroundTablet: bannerTablet1,
        backgroundMobile: bannerMobile1
      },
      {
        title: 'THÉP TIẾN LÊN',
        subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
        background: banner2,
        backgroundTablet: bannerTablet2,
        backgroundMobile: bannerMobile2
      },
      {
        title: 'THÉP TIẾN LÊN',
        subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
        background: banner3,
        backgroundTablet: bannerTablet3,
        backgroundMobile: bannerMobile3
      },
      {
        title: 'THÉP TIẾN LÊN',
        subtitle: 'CHUNG TAY KIẾN TẠO TƯƠNG LAI',
        background: banner4,
        backgroundTablet: bannerTablet4,
        backgroundMobile: bannerMobile4
      },
    ],
  },
  en: {
    title: 'Home',
    hiddenTitle: 'Tien len Steel',
    hiddenSubTitle: 'Constructing a future',
    buttonSeeMore: 'See more',
    buttonDetail: 'Details',
    buttonBanner: 'Our products and services',
    path: '/en',
    slider: [
      {
        title: 'Tien Len Steel',
        subtitle: 'Constructing a future with you',
        background: banner1,
        backgroundTablet: bannerTablet1,
        backgroundMobile: bannerMobile1
      },
      {
        title: 'Tien Len Steel',
        subtitle: 'Constructing a future with you',
        background: banner2,
        backgroundTablet: bannerTablet2,
        backgroundMobile: bannerMobile2
      },
      {
        title: 'Tien Len Steel',
        subtitle: 'Constructing a future with you',
        background: banner3,
        backgroundTablet: bannerTablet3,
        backgroundMobile: bannerMobile3
      },
      {
        title: 'Tien Len Steel',
        subtitle: 'Constructing a future with you',
        background: banner4,
        backgroundTablet: bannerTablet4,
        backgroundMobile: bannerMobile4
      },
    ],
  }
};

