import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../CustomHook/useCart';
import { Form } from 'antd';
import ProductSpecification from '../../ProductDetail/Control/ProductSpecification';
import { getProductNameByLanguage } from '../../Product/Helper/Helper';
import { CART_LANGUAGE } from '../Language/Language';
import { getHighestValueByKey } from '../../../common/Method.Common';
import noImages from '../../../asset/img/no-image.png';
import '../Css/CartItem.css';

const initialOrderCustomizationModel = {
  id: 1,
  Product_Specification: null,
  Product_Quality: null,
  Product_Origin: null,
  Product_Quantity: null,
  Product_Specification:'',
  Specification_Product_Type:'',
  Specification_Length:'',
  Specification_Width:'',
  Specification_Height:'',
  Specification_Product_Unit:'',
  Specification_Weight_Over_Unit:'',
  Specification_Weight:'',
  Note:'',

};

function CartItem({ product, language, form }) {
  const { updateItem, removeItem } = useCart();
  const [flag,setFlag] = useState(true)
  const [orderCustomization, setOrderCustomization] = useState([...(!!product.orderCustomization ? product.orderCustomization : [])]);
  const image = product.Product_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[0]?.name}`
    : noImages;

  const handleAddSpecification = () => {
    orderCustomization.push({ ...initialOrderCustomizationModel, id: getHighestValueByKey('id', orderCustomization) + 1 });
    setOrderCustomization([...orderCustomization]);
    setFlag(true)
  };

  const testHandle = ()=>{
    setFlag(false)
  }

  const handleChange = (e) => {
    const index = orderCustomization.findIndex(item => item.id === parseInt(e.target.id, 10));
    const nameProductSpecification = orderCustomization[index][e.target.nameProductSpecification]

    if( (e.target.weightOverUnitValue && e.target.quantityValue && nameProductSpecification) ||  (e.target.heightValue && e.target.lengthValue && e.target.widthValue) ){
      orderCustomization[index][e.target.nameQuantity] = e.target.quantityValue;
      orderCustomization[index][e.target.nameWeight] = e.target.weightValue;
      orderCustomization[index][e.target.nameHeight] = e.target.heightValue;
      orderCustomization[index][e.target.nameLength] = e.target.lengthValue;
      orderCustomization[index][e.target.nameWidth] = e.target.widthValue;
      orderCustomization[index][e.target.nameWeightOverUnit] = e.target.weightOverUnitValue;
      orderCustomization[index][e.target.nameUnit] = e.target.unitValue;
      orderCustomization[index][e.target.nameQuality] = e.target.qualityValue;
      orderCustomization[index][e.target.nameOrigin] = e.target.originValue;
      orderCustomization[index][e.target.nameNote] = e.target.noteValue;
    }
    if(e.target.weightOverUnitValue && nameProductSpecification && e.target.specificationValue ){
      orderCustomization[index][e.target.nameWeightOverUnit] = e.target.weightOverUnitValue;
       orderCustomization[index][e.target.nameWeight] = e.target.weightValue;
    }

    if((!e.target.heightValue && e.target.lengthValue && e.target.widthValue ) 
    || (e.target.heightValue && !e.target.lengthValue && e.target.widthValue) 
    || (e.target.heightValue && e.target.lengthValue && !e.target.widthValue) 
    && ((product.Product_Category === 'NSP004' || product.Product_Category === 'NSP005' )))
    {
      orderCustomization[index][e.target.nameWeightOverUnit] = 0;
      orderCustomization[index][e.target.nameWeight] = 0
    }
    if((!nameProductSpecification && product.Product_Category === 'NSP003')){
     
      orderCustomization[index][e.target.nameWeightOverUnit] = 0;
      orderCustomization[index][e.target.nameWeight] = 0
    }
    
    if(nameProductSpecification && !e.target.weightOverUnitValue && product.Product_Category === 'NSP003' && e.target.specificationValue){
     
      orderCustomization[index][e.target.nameWeightOverUnit] = 0;
      orderCustomization[index][e.target.nameWeight] = 0
    }
   
    setOrderCustomization([...orderCustomization]);
    updateItem({ ...product, orderCustomization });
  };

  const handleRemoveSpecification = (id) => {
    const newOrderCustomization = orderCustomization.filter(element => element.id !== id);
    form.setFieldsValue({
      [`Product_Quantity_${id}_${product._id}`]: null,
      [`Product_Specification_${id}_${product._id}`]: null,
      [`Specification_Height_${id}_${product._id}`]: null,
      [`Specification_Width_${id}_${product._id}`]: null,
      [`Specification_Length_${id}_${product._id}`]: null,
      [`Product_Quantity_${id}_${product._id}`]: null,
    });
    setOrderCustomization([...newOrderCustomization]);
    updateItem({ ...product, orderCustomization: newOrderCustomization });
  };

  useEffect(() => {
    form.validateFields();
  }, [orderCustomization, form]);

  return (
    <div className='cart__item'>
      <div className="cart__item__info">
        <div className="cart__item__image">
          <Link to={`/${language}/product/${product._id}`}>
            <div className="cart__item__image-wrapper">
              <img src={image} alt={product.Product_Name} />
            </div>
          </Link>
        </div>
        <div className="cart__item__name">
          <h3>{getProductNameByLanguage(product, language)}</h3>
          <div onClick={() => removeItem(product)} className="cart__item__delete">
            {CART_LANGUAGE[language]['DELETE']}
          </div>
        </div>
      </div>
      <div className="cart__item__detail">
        <Form
        labelCol={{ lg: { span: 9 }, md: { span: 24 } }}
        wrapperCol={{ lg: { span: 14 }, md: { span: 24 } }}
         form={form}>
          <ProductSpecification
            testHandle={testHandle}
            flag={flag}
            form={form}
            product={product}
            orderCustomization={orderCustomization}
            language={language}
            handleChange={handleChange}
            handleRemoveSpecification={handleRemoveSpecification}
            disabled={true}
            norequired={true}
            proCode={product.Product_Code}
          />
        </Form>
        <div className="product__order__customization__action">
          <div className="add__specification">
            <p onClick={handleAddSpecification} >+ {CART_LANGUAGE[language]['ADD_SPECIFICATION']}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CartItem);
