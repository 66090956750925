import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LanguageContext } from '../components/ContextProvider/LanguageContextProvider';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header';
import MessageContact from '../components/Layout/MessageContact';
import HomePage from '../components/Home/HomePage';
import RegisterPage from '../components/Login/RegisterPage';
import SignInPage from '../components/Login/SignInPage';
import CartPage from '../components/Cart/CartPage';
import ProductDetailPage from '../components/ProductDetail/ProductDetailPage';
import ProductPage from '../components/Product/ProductPage';
import ProfilePage from '../components/Login/ProfilePage';
import ChangePasswordPage from '../components/Login/ChangePasswordPage';
import { PrivateRoute } from '../components/Layout/PrivateRoute';
import PolicyApp from '../components/Policy/PolicyApp';
import ContactPage from '../components/Contact/ContactPage';
import ForgetPassword from '../components/Login/ForgetPasswordPage';
import ForgetFormPage from '../components/Login/ForgetFormPage';
import { useHistory } from 'react-router-dom';

export default function Index() {
  const { language, handleChangeLanguage } = React.useContext(LanguageContext);
  const history = useHistory();

  // if(window.location.pathname=='/'){
  //   history.push(`/${language}`);
  // }
  return (
    <>
      <Header handleSignLanguage={handleChangeLanguage} language={language}></Header>
      <MessageContact></MessageContact>
      <Switch>
        <Route path={`/${language}`} render={(props) => <HomePage {...props} language={language} />} exact></Route>
        <Route path={`/${language}/register`} render={(props) => <RegisterPage {...props} language={language} />}></Route>
        <PrivateRoute path={`/${language}/profile`} component={ProfilePage} language={language}></PrivateRoute>
        <Route path={`/${language}/signIn`} render={(props) => <SignInPage {...props} language={language} />}></Route>
        <Route path={`/${language}/changePassword`} render={(props) => <ChangePasswordPage {...props} language={language} />}></Route>
        <Route path={`/${language}/forget`} render={(props) => <ForgetPassword {...props} language={language} />}></Route>
        <Route path={`/${language}/forgetForm/:confirmCode`} render={(props) => <ForgetFormPage {...props} language={language} />}></Route>
        <Route path={`/${language}/policy`} render={(props) => <PolicyApp {...props} language={language} />}></Route>
        <Route path={`/${language}/contact`} render={(props) => <ContactPage {...props} language={language} />}></Route>
        <Route path={`/${language}/cart`} render={(props) => <CartPage {...props} language={language} />} />
        <Route path={`/${language}/products`} render={(props) => <ProductPage {...props} language={language} />} />
        <Route path={`/${language}/product/:id`} render={(props) => <ProductDetailPage {...props} language={language} />} />
      </Switch>
      <Footer language={language}></Footer>
    </>
  );
}
