import React from 'react';
import { Link } from 'react-router-dom';
import { CART_LANGUAGE } from './Language/Language';
function CartEmpty({ language }) {
  return (
    <div className='cart__empty'>
      <h3 className="cart__empty-title">{CART_LANGUAGE[language]['YOUR_CART']}</h3>
      <p className="cart__empty-message">{CART_LANGUAGE[language]['EMPTY_CART']}</p>
      <Link to={`/${language}/products`} className="cart__empty-button btn-submit-form">
        {CART_LANGUAGE[language]['CONTINUE_SHOPPING']}
      </Link>
    </div>
  );
}

export default CartEmpty;
