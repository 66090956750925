import React, { useState } from 'react';
import { CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCart } from '../Cart/CustomHook/useCart';
import { CheckoutService } from './Service/Checkout.Service';
import CustomerInfo from './Control/CustomerInfo';
import OrderInfo from './Control/OrderInfo';
import { RESULT_CODES } from '../../common/Constant.Common';
import { openErrorNotification } from '../../common/Method.Common';
import { CART_LANGUAGE } from '../Cart/Language/Language';
import CartNote from '../Cart/Control/CartNote';
import { Button } from 'antd';

function CheckoutList({ language }) {
  const customerModel = localStorage.getItem('customermodel') ? JSON.parse(localStorage.getItem('customermodel')) : null;
  const userInfoModel = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  const { cartItems, clearCart, totalItem, cartHeader } = useCart();
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);


  const handlePerformOrder = () => {
    if (!cartItems || cartItems?.length === 0)
      return openErrorNotification({
        title: language === 'vi' ? 'Thông báo' : 'Notification',
        message: language === 'vi' ? 'Giỏ hàng không có sản phẩm nào' : 'Cart can not be empty'
      });

    if (!customerModel)
      return openErrorNotification({
        title: language === 'vi' ? 'Thông báo' : 'Notification',
        message: language === 'vi' ? 'Thông tin khách hàng không được để trống' : 'Customer\'s information can not be empty'
      });

    const model = { cartItems, customerModel, cartHeader, language };
    console.log(model);
    setDisabled(true)
    CheckoutService.sendMailWhenPerformOrder(model).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.message);

      if (!userInfoModel) {
        localStorage.removeItem('customermodel');
      }
      clearCart();
      setShow(true);
    }
    ).catch(() => {
      // TO DO
    });
  };

  return (
    <main className='checkout__list'>
      <div className="container">
        <div className="checkout__list-content">
          <Link to='/cart/changecustomerinfo' className="checkout__list-return">
            <ArrowLeftOutlined className='checkout__list-return-icon' />
            {CART_LANGUAGE[language]['BACK_TO_CART']}
          </Link>
          <div className="checkout__list-content-main">
            <CustomerInfo language={language} customerModel={customerModel} />
            <OrderInfo language={language} cartItems={cartItems} totalItem={totalItem} onClick={handlePerformOrder} />
            <div className='checkout__list__action'>
              <CartNote language={language} />
              <Button loading={disabled} onClick={handlePerformOrder} className='btn-submit-form'>
                {CART_LANGUAGE[language]['PLACE_ORDER']}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={`overlay ${show ? 'show' : ''}`} />
      <div className={`modal order__result ${show ? 'show' : ''}`}>
        <div className="order__result__content">
          <div className="modal__container">
            <div className="order__result__icon">
              <CheckCircleOutlined className='order__result__icon--success' />
            </div>
            <div className="order__result__message">
              <p>{CART_LANGUAGE[language]['ORDER_SUCCESS']}</p>
            </div>
            <div className="order__result__action">
              <Link to={`/${language}/products`} className='btn-submit-form'>
                {CART_LANGUAGE[language]['CONTINUE_SHOPPING']}
              </Link>
              <Link to='/'>
                {CART_LANGUAGE[language]['BACK_TO_HOME_PAGE']}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CheckoutList;
