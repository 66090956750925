import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import ProductCard from '../../Product/Control/ProductCard';
import { RESULT_CODES } from '../../../common/Constant.Common';
import { PRODUCTS_LANGUAGE } from '../../Product/Language/Language';
import { ProductService } from '../../Product/Service/Product.Service';

const defaultSliderSetting = {
  arrows: false,
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
};

function ProductRelated({ language }) {
  const slickRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [searchOption] = useState({ page: 1, limit: 6 });
  const [sliderSetting, setSliderSetting] = useState({ ...defaultSliderSetting });

  useEffect(() => {
    ProductService.landingPageSearch({}, searchOption).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const data = result.result.docs;
      setProducts(data || []);
      if (window.innerWidth < 768) {
        setSliderSetting({ ...defaultSliderSetting, slidesToScroll: 1, slidesToShow: 1 });
      }
      if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setSliderSetting({ ...defaultSliderSetting, slidesToScroll: 2, slidesToShow: 2 });
      }
    }).catch(() => {
      // TO DO
    });
  }, [searchOption]);

  return (
    <div className='product__related'>
      <div className='product__related__title'>
        <p>{PRODUCTS_LANGUAGE[language]['OTHER_PRODUCTS']}</p>
        <div />
      </div>
      <Slider ref={slickRef} {...sliderSetting}>
        {products.map((item) => (
          <ProductCard key={item._id} product={item} language={language} />
        ))}
      </Slider>
    </div>
  );
}

export default ProductRelated;
