import React from 'react';
import { Link } from 'react-router-dom';
import { getProductNameByLanguage } from '../Helper/Helper';
import { PRODUCTS_LANGUAGE } from '../Language/Language';
import noImages from '../../../asset/img/no-image.png';

function ProductCard({ product, language = 'vi' }) {

  const thumbnail = product.Product_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[0]?.name}`
    : noImages;

  return (
    <div className='product__card' style={{height:'100%'}}>
      <div className="product__card__item" style={{height:'100%',display:'flex',flexDirection:'column'}}>
        <Link to={`/${language}/product/${product._id}`}>
          <div className="product__card__thumbnail">
            <img src={thumbnail} alt={product.Product_Name} />
          </div>
        </Link>
        <div className="product__card__content" style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
          
          <h3 className='text-clamp text-clamp--2' style={{flex:1}}>{getProductNameByLanguage(product, language)}</h3>
          <p >{`${PRODUCTS_LANGUAGE[language]['PRICE']}: ${PRODUCTS_LANGUAGE[language]['CONTACT']}`}</p>
         
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
