import React, { useEffect, useState, useRef } from 'react';
import backgroundTitle from '../../asset/img/img-8.jpg';
import { RegisterCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input, Select, Checkbox,InputNumber } from 'antd';
import './Css/Login.css';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { LoginService } from './Services/LoginServices';
import { RESULT_CODES } from '../../common/Constant.Common';
import { openErrorNotification } from '../../common/Method.Common';

const { Option } = Select;

const PolicyAgreementInVietnamese = () => {
  return (
    <>
      Tôi đồng ý với
      <Link to='/vi/policy/security' target='_blank'> Chính sách bảo mật</Link> và
      <Link to='/vi/policy/usage' target='_blank'> Chính sách sử dụng </Link>
      của Công ty CP Tập đoàn thép Tiến Lên.
    </>
  );
};

const PolicyAgreementInEnglish = () => {
  return (
    <>
      I have read and agree with the
      <Link to='/en/policy/security' target='_blank'> Privacy Policy</Link> and
      <Link to='/en/policy/usage' target='_blank'> Terms of Use </Link>
      of Tien Len Steel Corporation Joint Stock Company.
    </>
  );
};

export default function RegisterPage(props) {
  const { language } = props;
  const [form] = Form.useForm();
  const recaptchaRef = useRef(null);
  const [captcha, setCaptcha] = useState(null);
  const [policy, setPolicy] = useState(false);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (language === 'vi') {
      form.setFieldsValue({ User_Call: 'Anh' });
    } else {
      form.setFieldsValue({ User_Call: 'Mr.' });
    }
  }, [language, form]);

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
  };

  const onChangePolicy = (e) => {
    setPolicy(e.target.checked);
  };
  const onSubmit = async (values) => {
    if (!policy || !captcha) return;

    try {
      const model = values;

      if (language === 'vi') {
        model.User_Gender = values.User_Call === 'Anh' ? 'male' : 'female';
      } else {
        model.User_Gender = values.User_Call === 'Mr.' ? 'male' : 'female';
      }

      const result = await LoginService.create(model);
      if (result.returnCode === RESULT_CODES.SUCCESS) {
        setSuccess(true);
      } else {
        openErrorNotification({
          title: language === 'vi' ? 'Thông báo' : 'Notification',
          message: language === 'vi' ? 'Email đã được sử dụng' : 'Email already in use'
        });
      }

      if (recaptchaRef.current) recaptchaRef.current.reset();
    } catch (error) {
      // TO DO
      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
  };
  return (
    <Helmet title={RegisterCommon[language].title}>
      <Breadcrum data={[
        RegisterCommon[language].title
      ]}
      ></Breadcrum>
      <TitleBreadcrum title={RegisterCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
      <Form
        form={form}
        style={{ display: `${!success ? 'block' : 'none'}` }}
        className='login-main'
        onFinish={onSubmit}
        initialValues={{
          User_Call: language === 'vi' ? 'Anh' : 'Mr.'
        }}
      >
        <h3>{RegisterCommon[language].RegisterLoginField.title}</h3>
        <p>{RegisterCommon[language].RegisterLoginField.main.hint}</p>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterLoginField.main.email.title}</div>
        <Form.Item
          name='User_Email'
          rules={[{ required: true, type: 'email', message: RegisterCommon[language].RegisterLoginField.main.email.warning }]}
        >
          <Input type='text'></Input>
        </Form.Item>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterLoginField.main.password.title}</div>
        <Form.Item
          name='User_Password'
          rules={[{ required: true, message: RegisterCommon[language].RegisterLoginField.main.password.warning }]}
        >
          <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}></Input.Password>
        </Form.Item>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterLoginField.main.rePassword.title}</div>
        <Form.Item
          name='User_Repassword'
          rules={[
            {
              required: true, message: RegisterCommon[language].RegisterLoginField.main.rePassword.warning
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('User_Password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(RegisterCommon[language].RegisterLoginField.main.rePassword.wrong));
              },
            }),
          ]}
        >
          <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}></Input.Password>
        </Form.Item>
        <h3>{RegisterCommon[language].RegisterPersonalField.title}</h3>
        {/* <p>{RegisterCommon[language].RegisterPersonalField.main.hint}</p> */}
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterPersonalField.main.name.title}</div>
        <Form.Item
          name='User_Username'
          rules={[{ required: true, message: RegisterCommon[language].RegisterPersonalField.main.name.warning }]}
        >
          <Input type='text'></Input>
        </Form.Item>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterPersonalField.main.phone.title}</div>
        <Form.Item
          name='User_Phone'
          rules={[
            {
              required: true,
              pattern: /^[\d]{0,10}$/,
              message: RegisterCommon[language].RegisterPersonalField.main.phone.warning,
            },
          ]}
         
        >
          
          <Input 
          maxLength={10}
          ></Input>
        </Form.Item>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterPersonalField.main.address.title}</div>
        <Form.Item
          name='User_Address'
          rules={[{ required: true, message: RegisterCommon[language].RegisterPersonalField.main.address.warning }]}
        >
          <Input type='text'></Input>
        </Form.Item>
        <div className='login-main__field-title'>{RegisterCommon[language].RegisterPersonalField.main.gender.title}</div>
        <Form.Item
          name='User_Call'
        >
          <Select>
            {RegisterCommon[language].RegisterPersonalField.main.gender.label.map((item, index) => {
              return <Option value={item.value} key={'option' + index}>{item.title}</Option>
            })}
          </Select>
        </Form.Item>
        <ReCAPTCHA
          className='captcha'
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
          onChange={onChangeCaptcha}
          hl={language}
        />
        <Checkbox className='login-main-policy' onChange={onChangePolicy}>
          {language === 'vi' ? <PolicyAgreementInVietnamese /> : <PolicyAgreementInEnglish />}
        </Checkbox>
        <button type='submit' className='btn-submit-form'>
          {RegisterCommon[language].submit}
        </button>
        <div className='login-main__hint__end'>
          {RegisterCommon[language]['haveAccount']}
          <Link to={`/${language}/signIn`}> {RegisterCommon[language]['signIn']}</Link>
        </div>
      </Form>
      <div className='login-main-notify-success' style={{ display: `${success ? 'block' : 'none'}` }}
        dangerouslySetInnerHTML={{ __html: RegisterCommon[language].modalSuccess }}>
      </div>
    </Helmet>
  );
}
