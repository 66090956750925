import React from 'react'

export default function ModalLogin(props) {
    return (
        <div className="modal-login-overlay" onClick={props.handleToggleModal}>
            <div className="modal-login" onClick={(e)=>e.stopPropagation()}>
                <div className="modal-login-icon">
                    <i className="far fa-check-circle"></i>
                </div>
                <div className="modal-login-content">
                    {props.content}
                </div>
                <div className="modal-login-decor"></div>
            </div>
        </div>
    )
}
