import React, { useEffect, useState, } from 'react'
import backgroundTitle from '../../asset/img/img-8.jpg'
import { ForgetFormCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input, Row, Col } from 'antd';
import './Css/Login.css';
import { CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION, RESULT_CODES } from '../../common/Constant.Common';
import { LoginService } from './Services/LoginServices';
import { store } from 'react-notifications-component';
import NotifySuccess from './Control/NotifySuccess';

export default function ForgetFormPage(props) {
    const [success, setSuccess] = useState(false);
    const { language } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const onSubmit = async (values) => {
        const model = {
            password: values.password,
            confirmCode: props.match.params.confirmCode
        }
        const result = await LoginService.changePasswordForget(model);
        if (result.returnCode === RESULT_CODES.SUCCESS) {
            setSuccess(prevProps => !prevProps);
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        }
        else {
            store.addNotification({
                title: result.returnCode !== RESULT_CODES.SUCCESS ? 'Error!' : 'Success',
                message: `${CHANGE_PASSWORD_RETURN_CODE_DESCRIPTION[parseInt(result.returnCode)]}`,
                type: result.returnCode !== RESULT_CODES.SUCCESS ? 'danger' : 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }
    return (
        <Helmet title={ForgetFormCommon[language].title}>
            <Breadcrum data={[
                ForgetFormCommon[language].title
            ]}
            ></Breadcrum>
            <TitleBreadcrum title={ForgetFormCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
            <Form
                style={{ display: `${!success ? 'block' : 'none'}` }}
                className='login-main login-main-change-password'
                onFinish={onSubmit}
            >
                <h3>{ForgetFormCommon[language].ForgetFormField.title}</h3>
                <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ForgetFormCommon[language].ForgetFormField.main.NewPassword.title}</div>
                    </Col>
                    <Col lg={14} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: ForgetFormCommon[language].ForgetFormField.main.NewPassword.warning }]}
                        >
                            <Input type='password' className='login-main__field--input'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ForgetFormCommon[language].ForgetFormField.main.reNewPassword.title}</div>
                    </Col>
                    <Col lg={14} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='repassword'
                            rules={[
                                {
                                    required: true, message: ForgetFormCommon[language].ForgetFormField.main.reNewPassword.warning
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(ForgetFormCommon[language].ForgetFormField.main.reNewPassword.wrong));
                                    },
                                }),
                            ]}
                        >
                            <Input type='password' className='login-main__field--input'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={8} sm={24} xs={24}>
                    </Col>
                    <Col lg={14} md={16} sm={24} xs={24}>
                        <button htmlType='submit' className='btn-submit-form btn-submit-form-profile'>
                            {ForgetFormCommon[language].submit}
                        </button>
                    </Col>
                </Row>
            </Form>
            <NotifySuccess success={success} content={ForgetFormCommon[language].modalSuccess} button={ForgetFormCommon[language].back}></NotifySuccess>
        </Helmet>
    );
}
