import React from 'react'

export default function TitleBreadcrum(props) {
    return (
        <div className="title-breadcrum" style={{backgroundImage:`url(${props.background}`}}>
            <div className="title-breadcrum__overlay"></div>
            <div className="container">
                <div className="title-breadcrum__main">
                    <div className="title-breadcrum__main__circle"></div>
                    <h1>
                        {props.title}
                    </h1>
                </div>
            </div>
        </div>
    )
}
