import React, { useReducer, createContext, } from 'react';
import { cartHeaderInitModel } from '../Model/Cart.Model';
import { cartReducer, totalItem } from './CartReducer';
export const CartContext = createContext();

const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const cartHeader = localStorage.getItem('cartHeader') ? JSON.parse(localStorage.getItem('cartHeader')) : cartHeaderInitModel;
const initialState = { cartHeader, cartItems: storage, totalItem: totalItem(storage) };

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addItem = payload => {
    dispatch({ type: 'ADD_ITEM', payload });
  };

  const increase = payload => {
    dispatch({ type: 'INCREASE', payload });
  };

  const decrease = payload => {
    dispatch({ type: 'DECREASE', payload });
  };

  const updateItem = payload => {
    dispatch({ type: 'UPDATE_ITEM', payload });
  };

  const updateNote = payload => {
    dispatch({ type: 'UPDATE_NOTE', payload });
  };

  const removeItem = payload => {
    dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR' });
  };

  const contextValues = {
    addItem,
    updateItem,
    updateNote,
    removeItem,
    increase,
    decrease,
    clearCart,
    ...state
  };

  return (
    <CartContext.Provider value={contextValues} >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
