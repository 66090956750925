export const RegisterCommon = {
  vi: {
    path: '/register',
    title: 'Đăng ký thành viên',
    submit: 'Đăng ký',
    haveAccount: 'Bạn đã có tài khoản ? ',
    signIn: 'Đăng nhập',
    RegisterLoginField: {
      title: 'THÔNG TIN ĐĂNG NHẬP',
      main: {
        email: {
          title: 'Email (*)',
          warning: 'Hãy nhập email!'
        },
        hint: `(*) Các thông tin bắt buộc`,
        password: {
          title: 'Mật khẩu (*)',
          warning: 'Hãy nhập mật khẩu!'
        },
        rePassword: {
          title: 'Nhập lại mật khẩu (*)',
          warning: 'Hãy nhập mật khẩu xác nhận !',
          wrong: 'Mật khẩu xác nhận chưa chính xác!'
        },
      }
    },
    RegisterPersonalField: {
      title: 'THÔNG TIN CÁ NHÂN',
      main: {
        name: {
          title: 'Họ và tên (*)',
          warning: 'Hãy nhập họ và tên!'
        },
        checkbox: `(*) Các thông tin bắt buộc`,
        phone: {
          title: 'Điện thoại (*)',
          warning: 'Hãy nhập điện thoại!'
        },
        address: {
          title: 'Địa chỉ giao hàng (*)',
          warning: 'Hãy nhập địa chỉ giao hàng !',
        },
        gender: {
          title: 'Danh xưng (*)',
          warning: 'Hãy chọn danh xưng',
          label: [{
            title: 'Anh',
            value: 'Anh'
          },
          {
            title: 'Chị',
            value: 'Chị'
          }]
        },
      }
    },
    // modalSuccess: `Cảm ơn bạn đã đăng ký thành viên!
    //     <br />
    //     Chúng tôi đã gửi thư xác nhận qua email của bạn, vui lòng kiểm tra email để kích hoạt tài khoản.`
    modalSuccess: 'Bạn đã đăng ký thành công !'
  },
  en: {
    path: '/register',
    title: 'Register an account',
    submit: 'Register',
    haveAccount: 'Already have an account ?',
    signIn: 'Sign in',
    RegisterLoginField: {
      title: 'ACCOUNT INFORMATION',
      main: {
        email: {
          title: 'Email (*)',
          warning: 'Invalid email'
        },
        hint: `(*) Required field`,
        password: {
          title: 'Password (*)',
          warning: 'Please enter your password'
        },
        rePassword: {
          title: 'Re-enter password (*)',
          warning: 'Please re-enter your password',
          wrong: 'Password does not match'
        },
      }
    },
    RegisterPersonalField: {
      title: 'PERSONAL INFORMATION',
      main: {
        name: {
          title: 'Full name (*)',
          warning: 'Please enter your full name'
        },
        checkbox: `(*) Required field`,
        phone: {
          title: 'Phone number (*)',
          warning: 'Please enter your phone number'
        },
        address: {
          title: 'Delivery address (*)',
          warning: 'Please enter your address',
        },
        gender: {
          title: 'Title (*)',
          warning: 'Please select a title',
          label: [{
            title: 'Mr.',
            value: 'Mr.'
          },
          {
            title: 'Ms.',
            value: 'Ms.'
          },
          {
            title: 'Mrs.',
            value: 'Mrs.'
          }]
        },
      }
    },
    // modalSuccess: `Thank you for registering an account !
    //     <br />
    //     An activation email has been sent to your mailbox. Please check your mailbox to activate your account.`
    modalSuccess: 'Your account has been registered successfully !'
  }
};

export const LoginCommon = {
  vi: {
    path: '/signIn',
    title: 'Đăng nhập',
    submit: 'Đăng nhập',
    SignInLoginField: {
      title: 'THÔNG TIN ĐĂNG NHẬP',
      main: {
        email: {
          title: 'Email',
          warning: 'Hãy nhập email!'
        },
        password: {
          title: 'Mật khẩu',
          warning: 'Hãy nhập mật khẩu!'
        },
      }
    },
    forgetPassword: 'Quên mật khẩu?',
    questionRegister: 'Bạn chưa có tài khoản?',
    register: 'Đăng ký'
  },
  en: {
    path: '/signIn',
    title: 'Sign in',
    submit: 'Sign in',
    SignInLoginField: {
      title: 'SIGN IN INFORMATION',
      main: {
        email: {
          title: 'Email',
          warning: 'Please enter your email'
        },
        password: {
          title: 'Password',
          warning: 'Please enter your password'
        },
      }
    },
    forgetPassword: 'Forgot your password?',
    questionRegister: '',
    register: 'Register an account'
  }
};

export const ProfileCommon = {
  vi: {
    path: '/profile',
    title: 'Tài khoản',
    submit: 'Lưu',
    ProfileField: {
      title: 'THÔNG TIN TÀI KHOẢN',
      main: {
        email: {
          title: 'Email',
          warning: 'Hãy nhập email!'
        },
        hint: `(*) Các thông tin bắt buộc`,
        password: {
          title: 'Mật khẩu',
          warning: 'Hãy nhập mật khẩu!'
        },
        name: {
          title: 'Họ và tên',
          warning: 'Hãy nhập họ và tên!'
        },
        phone: {
          title: 'Điện thoại',
          warning: 'Hãy nhập điện thoại!'
        },
        address: {
          title: 'Địa chỉ giao hàng',
          warning: 'Hãy nhập địa chỉ giao hàng !',
        },
        gender: {
          title: 'Danh xưng',
          warning: 'Hãy chọn danh xưng',
          label: [{
            title: 'Anh',
            value: 'Mr.'
          },
          {
            title: 'Chị',
            value: 'Ms.'
          }]
        },
        birthday: {
          title: 'Ngày sinh',
        }
      }
    },
    modalSuccess: 'Đã cập nhật thông tin tài khoản thành công.'
  },
  en: {
    path: '/profile',
    title: 'Account',
    submit: 'Save',
    ProfileField: {
      title: 'Account information',
      main: {
        email: {
          title: 'Email',
          warning: 'please enter your email!'
        },
        hint: `(*) Required information`,
        password: {
          title: 'Password',
          warning: 'Please enter password!'
        },
        name: {
          title: 'Full name',
          warning: ' please enter your full name!'
        },
        phone: {
          title: 'Phone number',
          warning: 'Please enter your phone number!'
        },
        address: {
          title: 'Delivery address',
          warning: 'Please enter your delivery address!',
        },
        gender: {
          title: 'Title',
          warning: ' Please choose title',
          label: [{
            title: 'Mr.',
            value: 'Mr.'
          },
          {
            title: 'Ms.',
            value: 'Ms.'
          }]
        },
        birthday: {
          title: 'Birthday',
        }
      }
    },
    modalSuccess: 'updated account information successfully.'
  }
};

export const ChangePasswordCommon = {
  vi: {
    path: '/changePassword',
    title: 'Đổi mật khẩu',
    submit: 'Lưu',
    forgetPassword: 'Quên mật khẩu?',
    ChangePasswordField: {
      title: 'ĐỔI MẬT KHẨU',
      main: {
        password: {
          title: 'Mật khẩu hiện tại',
          warning: 'Hãy nhập mật khẩu hiện tại!'
        },
        NewPassword: {
          title: 'Mật khẩu mới',
          warning: 'Hãy nhập mật khẩu mới !',
        },
        reNewPassword: {
          title: 'Xác nhận mật khẩu mới',
          warning: 'Hãy nhập mật khẩu xác nhận !',
          wrong: 'Mật khẩu xác nhận chưa chính xác!'
        },
      }
    },
    modalSuccess: 'Đã cập nhật mật khẩu thành công.'
  },
  en: {
    path: '/changePassword',
    title: 'Change Password',
    submit: 'Save',
    forgetPassword: 'Forgot password?',
    ChangePasswordField: {
      title: 'Change Password',
      main: {
        password: {
          title: 'Current password',
          warning: 'Please enter your current password!'
        },
        NewPassword: {
          title: 'New password',
          warning: 'Please enter your new password!',
        },
        reNewPassword: {
          title: 'Confirm new password',
          warning: 'Please enter the confirmation password!',
          wrong: 'Confirmation password is not correct!'
        },
      }
    },
    modalSuccess: 'Updated successfully.'
  }
};

export const ForgetPasswordCommon = {
  vi: {
    path: '/forget',
    title: 'Quên mật khẩu',
    submit: 'Gửi',
    backHome: 'Quay lại Trang chủ',
    back: {
      title: 'Quay lại Trang chủ',
      path: '/'
    },
    ForgetPasswordField: {
      title: 'BẠN QUÊN MẬT KHẨU?',
      hint: 'Vui lòng nhập địa chỉ email đã đăng ký để tiến hành khôi phục mật khẩu.',
      main: {
        email: {
          title: 'Email',
          warning: 'Hãy nhập email!'
        },
      }
    },
    modalSuccess: 'Chúng tôi đã gửi thư khôi phục mật khẩu qua email của bạn!<br> Vui lòng kiểm tra email để thiết lập mật khẩu mới.'
  },
  en: {
    path: '/forget',
    title: 'Forgot your pass word',
    submit: 'Submit',
    backHome: 'Quay lại Trang chủ',
    back: {
      title: 'Back to home page',
      path: '/'
    },
    ForgetPasswordField: {
      title: 'FORGOT YOUR PASSWORD ?',
      hint: 'Please enter your email address to reset your password.',
      main: {
        email: {
          title: 'Email',
          warning: 'Please enter your email'
        },
      }
    },
    modalSuccess: 'We have sent you a link to reset your password. Please check your mailbox.'
  }
};

export const ForgetFormCommon = {
  vi: {
    path: '/forgetForm',
    title: 'Mật khẩu mới',
    back: {
      title: 'Quay lại trang Đăng nhập',
      path: '/vi/signIn'
    },
    submit: 'Lưu',
    ForgetFormField: {
      title: 'CÀI ĐẶT MẬT KHẨU MỚI',
      main: {
        NewPassword: {
          title: 'Mật khẩu mới',
          warning: 'Hãy nhập mật khẩu mới !',
        },
        reNewPassword: {
          title: 'Nhập lại mật khẩu mới',
          warning: 'Hãy nhập mật khẩu xác nhận !',
          wrong: 'Mật khẩu xác nhận chưa chính xác!'
        },
      }
    },
    modalSuccess: 'Cài đặt mật khẩu mới thành công!<br>Bạn có thể đăng nhập với mật khẩu mới vừa tạo.'
  },
  en: {
    path: '/forgetForm',
    title: 'Reset password',
    back: {
      title: 'Back to Sign-in',
      path: '/en/signIn'
    },
    submit: 'Submit',
    ForgetFormField: {
      title: 'RESET PASSWORD',
      main: {
        NewPassword: {
          title: 'New password',
          warning: 'Please enter your new password',
        },
        reNewPassword: {
          title: 'Re-enter password',
          warning: 'Please enter confirm password',
          wrong: 'Password does not match'
        },
      }
    },
    modalSuccess: 'You have successfully reset your password!<br>You may now sign in with your new password.'
  }
};

