import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Form, InputNumber, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../Css/ProductSpecification.css';
import { PRODUCTS_LANGUAGE } from '../../Product/Language/Language';
import ProductTypeSelect from '../../Product/Control/ProductTypeSelect';
import SpecificationSelect from '../../Product/Control/SpecificationSelect';
import UnitSelect from '../../Product/Control/UnitSelect';
import { ProductService } from '../../Product/Service/Product.Service';
import { PRODUCT_TYPE } from '../../../common/Constant.Common'
import { openNotification } from '../../../common/Method.Common';

const manageSpecificationLength = [
  { key: '6000', value: 6000, name: '6000' },
  { key: '12000', value: 12000, name: '12000' },
]

function ProductSpecification(props) {
  const { product, orderCustomization, handleChange, handleRemoveSpecification, language = 'vi',testHandle,flag } = props
  const category = product.Product_Category;
  const [type, setType] = useState('');
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weightOverUnit, setWeightOverUnit] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [note,setNote] = useState('')
  const [quality,setQuality] = useState('')
  const [origin,setOrigin] = useState('')
  const [specification, setSpecification] = useState();
  const [unit, setUnit] = useState('');
  const [unitName, setUnitName] = useState('');
  const [disabledLength, setDisabledLength] = useState(0)
  const [disabledWidth, setDisabledWidth] = useState(0)
  const [disabledHeight, setDisabledHeight] = useState(0)
  const [disabledWeightOverUnit, setDisabledWeightOverUnit] = useState(0)
  const [itemId, setItemId] = useState({});

  useEffect(() => {
    const searchOption = { page: 1, limit: 1 };
    if (specification) {
      ProductService.searchSpecification({ Specification_Code: specification, Status: true }, searchOption).then(result => {
        const data = result.result.docs[0] || [];
        if(data.Specification_Length) {
          setLength(data.Specification_Length);
        } else  {
          setLength(0)
          // openNotification({
          //   title: PRODUCTS_LANGUAGE[language].WARNING,
          //   message: PRODUCTS_LANGUAGE[language].MISSINGPARAMETER })
        }
        if(data.Specification_Width) {
          setWidth(data.Specification_Width);
        } else setWidth(null)
        if(data.Specification_Height) {
          setHeight(data.Specification_Height);
        } else setHeight(null)
        if(!data.Specification_Weight_Over_Unit) {
          setWeightOverUnit(0)
          // openNotification({
          //   title: PRODUCTS_LANGUAGE[language].WARNING,
          //   message: PRODUCTS_LANGUAGE[language].MISSINGPARAMETER })
        } else 
        setWeightOverUnit(data.Specification_Weight_Over_Unit)   
        ProductService.searchUnit({ Unit_Code: data.Specification_Product_Unit }, searchOption).then(result => {
          const dataS = result.result.docs || [];
          // setDataSource(data);
          setUnitName(dataS[0].Unit_Name)
        })
        
        // setWeight(data.Specification_Weight)
        setUnit(data.Specification_Product_Unit)
        // props.reset();
      })
    } else {
      
      setWeightOverUnit(0);
      setWeight(0);
      setUnit('')
    }
  }, [specification,language])
  console.log({weight,weightOverUnit});
  useEffect(() => {
    if (category == PRODUCT_TYPE.THEPHINH ) {
      if (length && weightOverUnit && quantity) {
        const weight1 = parseInt(quantity) * length * weightOverUnit / 1000;
        setWeight(weight1.toFixed(2))
      }
      else {setWeight(null)}
    }
    else if (category == PRODUCT_TYPE.THEPTAM ) {
      if ((height && width && length)  ||  (height && width && length  && quantity)) {
        const weightOverUnit1 = height * (width / 1000) * (length / 1000) * 7.85;
        setWeightOverUnit(weightOverUnit1.toFixed(2))
        const weight1 = parseInt(quantity) * weightOverUnit1;
        if(!isNaN(weight1) && quantity !== 0) {
          setWeight(weight1.toFixed(2))
        } else {setWeight(null)}
      } else {
       
        setWeightOverUnit(null)
        setWeight(null)
      }
    }
    else if (category == PRODUCT_TYPE.THEPTAMGAN ) {
      if ((height && width && length)  ||  (height && width && length && !!quantity && quantity !== 0)) {
        const weightOverUnit1 = ((height * (width / 1000) * (length / 1000) * 7.85) + (3 * (width / 1000) * (length / 1000)))
        setWeightOverUnit(weightOverUnit1.toFixed(2))
        const weight1 = parseInt(quantity) * weightOverUnit1;
        if(!isNaN(weight1) && quantity !== 0) {
          setWeight(weight1.toFixed(2))
        } else {setWeight(null)}
      } else {
        setWeightOverUnit(null)
      }
    }
  }, [length, width, height, weightOverUnit, category, quantity,specification,note,origin,quality])
 
  useEffect(()=>{
    if(Object.keys(itemId).length > 0 ){ 
        handleChange({target:{
          qualityValue:quality,
          quantityValue:quantity,
         weightValue:weight,
         weightOverUnitValue:weightOverUnit,
         widthValue:width,
         heightValue:height,
         lengthValue:length,
         unitValue:unit,
         originValue:origin,
         specificationValue:specification,
         noteValue:note,
         nameNote:`Note_${itemId.id}_${product._id}`,
         nameOrigin:`Product_Origin_${itemId.id}_${product._id}`,
         nameQuality:`Product_Quality_${itemId.id}_${product._id}`,
         nameUnit:`Specification_Product_Unit_${itemId.id}_${product._id}`,
         nameQuantity:`Product_Quantity_${itemId.id}_${product._id}`,
         nameWeight:`Specification_Weight_${itemId?.id}_${product._id}`,
         nameWidth:`Specification_Width_${itemId?.id}_${product._id}`,
         nameHeight:`Specification_Height_${itemId?.id}_${product._id}`,
         nameWeightOverUnit:`Specification_Weight_Over_Unit_${itemId?.id}_${product._id}`,
         nameLength:`Specification_Length_${itemId?.id}_${product._id}`,
         nameProductSpecification:`Product_Specification_${itemId?.id}_${product._id}`,
         id:itemId.id,
         nameProduct:product.Product_Category,
       }}) 
      }

  },[weightOverUnit,weight,length,width,quantity,height,specification,flag,origin,quality,note])
  useEffect(()=>{
    if(Object.keys(itemId).length > 0 && flag ){
      setSpecification(null)
      setQuantity(null)
      setQuality('')
      setOrigin('')
      setNote('')
      setLength(null)
      setHeight(null)
      setWidth(null)
    }
    if( testHandle && flag ){
      testHandle()
    }
  })
  useEffect(() => {
    const searchOption = { page: 1, limit: 1 };
    if (category !== PRODUCT_TYPE.THEPHINH) {
      ProductService.searchUnit({ Unit_Code: 'T' }, searchOption).then(result => {
        const dataS = result.result.docs || [];
        // setDataSource(data);
        if (language == 'vi') {
          setUnitName(dataS[0].Unit_Name)
          setUnit(dataS[0].Unit_Name)
        }
        else {
          setUnitName(dataS[0].Unit_Name_Eng)
          setUnit(dataS[0].Unit_Name_Eng)
        }
      })
    } else {
      ProductService.searchUnit({ Unit_Code: 'UNY' }, searchOption).then(result => {
        const dataS = result.result.docs || [];
        // setDataSource(data);
        if (language == 'vi') {
          setUnitName(dataS[0].Unit_Name)
          setUnit(dataS[0].Unit_Name)
        }
        else {
          setUnitName(dataS[0].Unit_Name_Eng)
          setUnit(dataS[0].Unit_Name_Eng)
        }
      })
    }
  }, [unit, unitName])
  
  // const handleChangeSpecification_Product_THEPHINH_Type = (e, index, id, productId) => {
  //   console.log({ check:orderCustomization[index][`Product_Specification_${id}_${productId}`]});
  //   const had = orderCustomization.some((item, i) => {
  //     return item[`Specification_Product_Type_${i + 1}_${productId}`] === e
  //       &&
  //       orderCustomization[index][`Product_Specification_${id}_${productId}`]
  //       === item[`Product_Specification_${i + 1}_${productId}`];
  //   })
  //   if (had) {
  //     openNotification({
  //       title: PRODUCTS_LANGUAGE[language].WARNING,
  //       message: PRODUCTS_LANGUAGE[language].WARNING_WAS_ADDED(e,
  //         orderCustomization[index][`Product_Specification_${id}_${productId}`]
  //       )
  //     })
  //     setType("");
  //     orderCustomization[index][`Specification_Product_Type_${id}_${productId}`] = "";
  //     return;
  //   }
  //   setType(e);
  //   orderCustomization[index][`Specification_Product_Type_${id}_${productId}`] = e;
  // }
  const handleChangeSpecification_Product_THEPHINH = (e, index, id, productId) => {
    console.log({ check:orderCustomization,e:e});
    const had = orderCustomization.some((item, i) => {
      return item[`Product_Specification_${i + 1}_${productId}`] === e
        &&
        orderCustomization[index][`Specification_Product_Type_${id}_${productId}`]
        === item[`Specification_Product_Type_${i + 1}_${productId}`] && e !== undefined
    })
    
    if (had) {
      openNotification({
        title: PRODUCTS_LANGUAGE[language].WARNING,
        message: PRODUCTS_LANGUAGE[language].WARNING_WAS_ADDED(
          product?.Product_Name
          , e)
      })
      setSpecification("");
      orderCustomization[index][`Product_Specification_${id}_${productId}`] = ""
      return;
    }
    setSpecification(e);
    orderCustomization[index][`Product_Specification_${id}_${productId}`] = e
  }


  const handleSave = (item)=>{
    setWeightOverUnit(item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`] ? item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`] : weightOverUnit )
    setLength(item[`Specification_Length_${item.id}_${product._id}`] ? item[`Specification_Length_${item.id}_${product._id}`] : length)
    setHeight(item[`Specification_Height_${item.id}_${product._id}`] ? item[`Specification_Height_${item.id}_${product._id}`] : height)
    setWidth(item[`Specification_Width_${item.id}_${product._id}`]?item[`Specification_Width_${item.id}_${product._id}`]:width)
    setWeight(item[`Specification_Weight_${item.id}_${product._id}`]?item[`Specification_Weight_${item.id}_${product._id}`]:weight)
    setQuantity(item[`Product_Quantity_${item.id}_${product._id}`]?item[`Product_Quantity_${item.id}_${product._id}`]:quantity) 
    setUnit(unit)
    setQuality(item[`Product_Quality_${item.id}_${product._id}`] ? item[`Product_Quality_${item.id}_${product._id}`] : quality)                
    setOrigin(item[`Product_Origin_${item.id}_${product._id}`] ? item[`Product_Origin_${item.id}_${product._id}`] : origin)
    setNote(item[`Note_${item.id}_${product._id}`] ? item[`Note_${item.id}_${product._id}`] : note)
    setSpecification(item[`Product_Specification_${item.id}_${product._id}`]? item[`Product_Specification_${item.id}_${product._id}`] : specification)                
  }


  return (
    <>
      {orderCustomization.map((item, index) => (
        <Row gutter={[50, 0]} style={{ paddingTop: '25px', borderBottom: "1px solid #d9d9d9" }}>
          {category === PRODUCT_TYPE.THEPKHAC ?
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                labelAlign='left'
                label={PRODUCTS_LANGUAGE[language]['NOTE']}
                key={`Note_${item.id}_${product._id}`} >
                <Input.TextArea id={item.id} value={item[`Note_${item.id}_${product._id}`]}
                  //  tabIndex={window.innerWidth > 768 ? index * 4 + 4 : 0}
                  onChange={handleChange}
                  name={`Note_${item.id}_${product._id}`}
                  className='product__order__customization__input'
                  placeholder={PRODUCTS_LANGUAGE[language]['NOTE']} />
              </Form.Item>
              {item.id !== 1 && <div key={`icon_${item.id}_${product._id}`} onClick={() => handleRemoveSpecification(item.id)}
                className='product__order__customization__remove'>
                <CloseCircleOutlined className='remove-icon' />
              </div>}
            </Col>
            :
            <>   
              {category === PRODUCT_TYPE.THEPHINH ?
                <>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <SpecificationSelect
                      onChange={e => {
                        setItemId({id: item.id, index: index})
                        handleSave(item)
                        handleChangeSpecification_Product_THEPHINH(e, index, item.id, product._id)
                      }}
                      key={`Product_Specification_${item.id}_${product._id}`}
                      name={`Product_Specification_${item.id}_${product._id}`}
                      label={PRODUCTS_LANGUAGE[language]['SPECIFICATION']}
                      placeholder={PRODUCTS_LANGUAGE[language]['SPECIFICATION']}
                      initialValue={item[`Product_Specification_${item.id}_${product._id}`]}
                      value={item[`Product_Specification_${item.id}_${product._id}`]}
                      type={type}
                      category={product.Product_Category}
                      required={true}
                      warning={PRODUCTS_LANGUAGE[language]['REQUIRED_SPECIFICATION']}
                      product={product.Product_Code}
                    />
                  </Col>
                </>
                : ''
              }
              {category === PRODUCT_TYPE.THEPTAM || category === PRODUCT_TYPE.THEPTAMGAN ?
                <>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <div key={`Specification_Height_${item.id}_${product._id}`} style={{ position: 'relative' }}>
                      <Form.Item
                        labelAlign='left'
                        label={PRODUCTS_LANGUAGE[language]['HEIGHT']}
                        key={`Specification_Height_${item.id}_${product._id}`}
                        name={`Specification_Height_${item.id}_${product._id}`}
                        initialValue={item[`Specification_Height_${item.id}_${product._id}`]}
                        rules={[
                          {
                            required: true,
                            message: PRODUCTS_LANGUAGE[language]['REQUIRED_HEIGHT'],
                            pattern: new RegExp(/^[0-9]*$/)
                          },
                        ]}
                      //  rules={[{ required: true, message: PRODUCTS_LANGUAGE[language]['REQUIRED_HEIGHT'] }]} 
                      >
                       <InputNumber 
                       max={999}
                       maxLength={3}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  

                        id={item.id}
                          placeholder={item[`Specification_Height_${item.id}_${product._id}`] ? item[`Specification_Height_${item.id}_${product._id}`] : PRODUCTS_LANGUAGE[language]['HEIGHT']}
                          onChange={value => {
                            handleSave(item)
                            setItemId({id: item.id, index: index})
                            setHeight(value);
                            
                          }}
                        
                          name={`Specification_Height_${item.id}_${product._id}`}
                          disabled={false}
                          // value={height ? parseInt(height) : parseInt(item[`Specification_Height_${item.id}_${product._id}`]) ? parseInt(item[`Specification_Height_${item.id}_${product._id}`]) : PRODUCTS_LANGUAGE[language]['HEIGHT']}
                          className='product__order__customization__input' style={{textAlign: 'right', width: '100%'}} min={0}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelAlign='left'
                      label={PRODUCTS_LANGUAGE[language]['WIDTH']}
                      initialValue={item[`Specification_Width_${item.id}_${product._id}`]}
                      key={`Specification_Width_${item.id}_${product._id}`}
                      name={`Specification_Width_${item.id}_${product._id}`}
                      rules={[{ required: true, message: PRODUCTS_LANGUAGE[language]['REQUIRED_WIDTH'], pattern: new RegExp(/^[0-9]*$/) }]}
                    >
                      <InputNumber 
                      max={9999}
                      maxLength={4}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  
                      id={item.id} 
                      placeholder={item[`Specification_Width_${item.id}_${product._id}`] ? item[`Specification_Width_${item.id}_${product._id}`] :  PRODUCTS_LANGUAGE[language]['WIDTH']}
                        onChange={value=> {
                        handleSave(item)
                          setItemId({id: item.id, index: index})
                          setWidth(value);
                          // handleChange(e)
                        }}
                        name={`Specification_Width_${item.id}_${product._id}`}
                        disabled={false}
                        className='product__order__customization__input' 
                        style={{textAlign: 'right', width: '100%'}} 
                        min={0}
                        />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      labelAlign='left'
                      label={PRODUCTS_LANGUAGE[language]['LENGTH']}
                      initialValue={item[`Specification_Length_${item.id}_${product._id}`]}
                      key={`Specification_Length_${item.id}_${product._id}`}
                      name={`Specification_Length_${item.id}_${product._id}`}
                      rules={[{ required: true, message: PRODUCTS_LANGUAGE[language]['REQUIRED_LENGTH'], pattern: new RegExp(/^[0-9]*$/) }]}
                    >
                      
                     <InputNumber 
                     max={99999}
                     maxLength={6}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  
                      id={item.id}
                        style={{textAlign: 'right', width: '100%'}} min={0}
                        placeholder={item[`Specification_Length_${item.id}_${product._id}`] ? item[`Specification_Length_${item.id}_${product._id}`]  : PRODUCTS_LANGUAGE[language]['LENGTH']}
                        onChange={value => {
                        handleSave(item)
                          setItemId({id: item.id, index: index})
                          setLength(value);
                          }}
                        name={`Specification_Length_${item.id}_${product._id}`}
                        disabled={false}
                        // value={length ? parseInt(length) : parseInt(item[`Specification_Length_${item.id}_${product._id}`]) ? parseInt(item[`Specification_Length_${item.id}_${product._id}`]) : PRODUCTS_LANGUAGE[language]['LENGTH']}
                        className='product__order__customization__input'
                        />
                    </Form.Item>
                  </Col>
                </>
                : ''}

              <Col lg={12} md={12} sm={24} xs={24}>
                <div key={`Product_Quantity_${item.id}_${product._id}`} style={{ position: 'relative' }}>
                  <Form.Item
                    labelAlign='left'
                    label={`${PRODUCTS_LANGUAGE[language]['QUANTITY']} (${unitName})`}
                    initialValue={item[`Product_Quantity_${item.id}_${product._id}`]}
                    name={`Product_Quantity_${item.id}_${product._id}`}
                    rules={[{ required: true, message: PRODUCTS_LANGUAGE[language]['REQUIRED_QUANTITY'], pattern: new RegExp(/^[0-9]*$/) }]}
                  >
                   <InputNumber 
                        inputStyle="text-align: right"
                        id={item.id}
                        style={{textAlign: 'left',width:'100%'}} 
                        min={1}
                        defaultValue={item[`Product_Quantity_${item.id}_${product._id}`] ? item[`Product_Quantity_${item.id}_${product._id}`]:''}
                        onChange={value => {
                        handleSave(item)
                        setQuantity(value);
                        setItemId({id: item.id, index: index});
                      }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  
                        name={`Product_Quantity_${item.id}_${product._id}`}
                        className='product__order__customization__input'
                        placeholder={item[`Product_Quantity_${item.id}_${product._id}`] ? item[`Product_Quantity_${item.id}_${product._id}`] 
                        : `${PRODUCTS_LANGUAGE[language]['QUANTITY']} (${unitName})`}
                        value={ item[`Product_Quantity_${item.id}_${product._id}`] ? item[`Product_Quantity_${item.id}_${product._id}`] : ''}
                      />
                  </Form.Item>
                </div>

              </Col>

             
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelAlign='left'
                  label={` ${category == PRODUCT_TYPE.THEPHINH ? PRODUCTS_LANGUAGE[language]['WEIGHT_OVER_UNIT'] : PRODUCTS_LANGUAGE[language]['WEIGHT_OVER_UNIT_PCS']}`}
                  key={`Specification_Weight_Over_Unit_${item.id}_${product._id}`}
                  name={`Specification_Weight_Over_Unit_${item.id}_${product._id}`}
                  //  rules={[{ required: props.norequired?false:weightOverUnit?false:true, message: PRODUCTS_LANGUAGE[language]['REQUIRED_WEIGHT_OVER_UNIT'] }]}>
                  rules={[{ required: false, message: PRODUCTS_LANGUAGE[language]['REQUIRED_WEIGHT_OVER_UNIT'] }]}>
                  < InputNumber 
                      id={item.id} 
                      style={{textAlign: 'right',width:'100%'}} 
                      placeholder={item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`] ? item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`]:PRODUCTS_LANGUAGE[language]['WEIGHT_OVER_UNIT']}
                      value={item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`] ? item[`Specification_Weight_Over_Unit_${item.id}_${product._id}`]:PRODUCTS_LANGUAGE[language]['WEIGHT_OVER_UNIT']}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  
                    //   onChange={e => {
                    //   setWeightOverUnit(e.target.value);
                    //   handleChange(e)
                    // }}
                    disabled={true}
                    name={`Specification_Weight_Over_Unit_${item.id}_${product._id}`}
                    className='product__order__customization__input'
                    type='number'
                    min={0}
                  />
                </Form.Item>

              </Col>

              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelAlign='left'
                  label={PRODUCTS_LANGUAGE[language]['WEIGHT']}
                  key={`Specification_Weight_${item.id}_${product._id}`}
                  extra={PRODUCTS_LANGUAGE[language]['PROVISINAL']}
                >
                  < InputNumber 
                  id={item.id} 
                  style={{textAlign: 'right',width:'100%'}} 
                  placeholder={item[`Specification_Weight_${item.id}_${product._id}`]?item[`Specification_Weight_${item.id}_${product._id}`]:PRODUCTS_LANGUAGE[language]['WEIGHT']}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}  
                    name={`Specification_Weight_${item.id}_${product._id}`}
                    className='product__order__customization__input'
                    disabled={true}
                    value={item[`Specification_Weight_${item.id}_${product._id}`] ? item[`Specification_Weight_${item.id}_${product._id}`]: PRODUCTS_LANGUAGE[language]['WEIGHT']}
                  />

                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelAlign='left'
                  label={PRODUCTS_LANGUAGE[language]['STEEL_GRAGDE']}
                  key={`Product_Quality_${item.id}_${product._id}`}
                  initialValue={item[`Product_Quality_${item.id}_${product._id}`]}
                  name={`Product_Quality_${item.id}_${product._id}`}
                  >
                  <Input id={item.id}
                    // tabIndex={window.innerWidth > 768 ? index * 4 + 13 : 0}
                    value={item[`Product_Quality_${item.id}_${product._id}`]}
                    defaultValue={item[`Product_Quality_${item.id}_${product._id}`] ? item[`Product_Quality_${item.id}_${product._id}`]:''}

                    onChange={e=>{
                      handleSave(item)
                      setItemId({id: item.id, index: index})
                      setQuality(e.target.value)
                      }
                    }
                    name={`Product_Quality_${item.id}_${product._id}`}
                    className='product__order__customization__input'
                   
                    placeholder={ PRODUCTS_LANGUAGE[language]['STEEL_GRAGDE']} 
                    />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  labelAlign='left'
                  label={PRODUCTS_LANGUAGE[language]['ORIGIN']}
                  key={`Product_Origin_${item.id}_${product._id}`}
                  name={`Product_Origin_${item.id}_${product._id}`}
                  initialValue={item[`Product_Origin_${item.id}_${product._id}`]}
                  >
                  <Input id={item.id} value={item[`Product_Origin_${item.id}_${product._id}`]}
                    // tabIndex={window.innerWidth > 768 ? index * 4 + 14 : 0}
                    onChange={e=>{
                      handleSave(item)
                      setItemId({id: item.id, index: index})
                      setOrigin(e.target.value)
                      }
                    }
                    name={`Product_Origin_${item.id}_${product._id}`}
                    className='product__order__customization__input'
                    placeholder={PRODUCTS_LANGUAGE[language]['ORIGIN']} />
                </Form.Item>

              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>

                <Form.Item
                  labelAlign='left'
                  label={PRODUCTS_LANGUAGE[language]['NOTE']}
                  key={`Note_${item.id}_${product._id}`}
                  name={`Note_${item.id}_${product._id}`}
                  initialValue={item[`Note_${item.id}_${product._id}`]}
                  >
                  <Input.TextArea id={item.id} value={item[`Note_${item.id}_${product._id}`]}
                    // tabIndex={window.innerWidth > 768 ? index * 4 + 15 : 0}
                    onChange={e=>{
                      handleSave(item)
                      setItemId({id: item.id, index: index})
                      setNote(e.target.value)
                      }
                    }
                    name={`Note_${item.id}_${product._id}`}
                    className='product__order__customization__input'
                    placeholder={PRODUCTS_LANGUAGE[language]['NOTE']} />
                </Form.Item>
                {item.id !== 1 && <div key={`icon_${item.id}_${product._id}`} onClick={() => handleRemoveSpecification(item.id)}
                  className='product__order__customization__remove'>
                  <CloseCircleOutlined className='remove-icon' />
                </div>}
              </Col>
            </>}
        </Row>
      )
      )}
    </>

  );
}

export default ProductSpecification;
