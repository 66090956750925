import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../Cart/CustomHook/useCart';
import Breadcrum from '../Layout/Breadcrum';
import Helmet from '../Layout/Helmet';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import backgroundTitle from '../../asset/img/img-8.jpg';
import productbg from '../../asset/img/BGnews.png';
import './Css/Style.css';
import ProductInfo from './Control/ProductInfo';
import ProductOrder from './Control/ProductOrder';
import ProductRelated from './Control/ProductRelated';
import { getProductNameByLanguage } from '../Product/Helper/Helper';
import { RESULT_CODES,PRODUCT_TYPE } from '../../common/Constant.Common';
import { PRODUCTS_LANGUAGE } from '../Product/Language/Language';
import { ProductService } from '../Product/Service/Product.Service';

function ProductDetailPage({ language }) {
  const { addItem } = useCart();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    ProductService.getById(id).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error('Error');

      setProduct(result.data[0] || null);
    }).catch(() => {
      // TO DO
    });
  }, [id]);

  return (
    <Helmet title={getProductNameByLanguage(product, language)}>
      <Breadcrum data={[
        {
          path: `/${language}${PRODUCTS_LANGUAGE[language]['PATH']}`,
          title: PRODUCTS_LANGUAGE[language]['TITLE']
        },
        getProductNameByLanguage(product, language)
      ]} />
      <TitleBreadcrum title={getProductNameByLanguage(product, language)} background={backgroundTitle} />
      <div className="product__detail">
        <div className="product__detail__wrapper">
          <div className="product__detail__background" style={{
            backgroundImage: ` linear-gradient(to top, rgba(255, 255, 255, 0.5), 
            rgba(255, 255, 255, 0)), url(${productbg}) `
          }} />
          <div className="container">
            {product && <ProductInfo product={product} language={language} />}
            {product && (product.Product_Category === PRODUCT_TYPE.THEPHINH||product.Product_Category ===PRODUCT_TYPE.THEPTAM||product.Product_Category ===PRODUCT_TYPE.THEPTAMGAN ||product.Product_Category ===PRODUCT_TYPE.THEPKHAC) &&
              <ProductOrder product={product} onAddItem={addItem} id={id} language={language} />
            }
            <ProductRelated language={language} />
          </div>
        </div>
      </div>
    </Helmet>
  );
}

export default ProductDetailPage;
