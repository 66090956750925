import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { CART_LANGUAGE } from '../Cart/Language/Language';
import { RECEIVING_METHOD, TITLE_DATASOURCE } from '../Cart/Model/Cart.Model';
function CustomerInfoList({ language }) {
  var customerModelInit = localStorage.getItem('customermodel') ? JSON.parse(localStorage.getItem('customermodel')) : {};

  const userInfoModel = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  // debugger;
 
  if(!!userInfoModel){
    customerModelInit.Customer_Fullname = userInfoModel.User_Username;
    customerModelInit.Customer_PhoneNumber = userInfoModel.User_Phone;
    customerModelInit.Customer_Email = userInfoModel.User_Email;
    customerModelInit.Customer_Receiving_Method = '';
    customerModelInit.Customer_Address = userInfoModel.User_Address;
    customerModelInit.Customer_Title = userInfoModel.User_Call;
  }else if (!!customerModelInit) {
    customerModelInit.Customer_Fullname = customerModelInit.Customer_Fullname;
    customerModelInit.Customer_PhoneNumber = customerModelInit.Customer_PhoneNumber;
    customerModelInit.Customer_Email = customerModelInit.Customer_Email;
    customerModelInit.Customer_Receiving_Method = '';
    customerModelInit.Customer_Address = customerModelInit.User_Address;
    customerModelInit.Customer_Title = customerModelInit.Customer_Title;
  } else {
    customerModelInit.Customer_Fullname = '';
    customerModelInit.Customer_PhoneNumber = '';
    customerModelInit.Customer_Email ='' ;
    customerModelInit.Customer_Receiving_Method = '';
    customerModelInit.Customer_Address = '';
    customerModelInit.Customer_Title=''
  }


  const customerModel = customerModelInit;
  
  
  const [visible, setVisible] = useState(false);
  const [visibleHint, setVisibleHint] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const handleFinish = useCallback(() => {
    form.validateFields().then(values => {
      localStorage.setItem('customermodel', JSON.stringify({ ...values }));
      history.push({ pathname: '/cart/checkout', });
    }).catch(() => {
      // TO DO
    });
  }, [form, history, customerModel]);

  const handlePressEnter = useCallback((e) => {
    if (e.key !== 'Enter') return;

    handleFinish();
  }, [handleFinish]);

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === 'Customer_Receiving_Method' && changedValues.Customer_Receiving_Method === 'REQUESTED_PLACE') {
      setVisible(true);
      setVisibleHint(false);
    }
    if (formFieldName === 'Customer_Receiving_Method' && changedValues.Customer_Receiving_Method === 'OTHERS') {
      setVisible(true);
      setVisibleHint(true);
    }
    if (formFieldName === 'Customer_Receiving_Method' && changedValues.Customer_Receiving_Method === 'WAREHOUSE') {
      setVisible(false);
      setVisibleHint(false);
    }
  };

  useEffect(() => {
    // localStorage.getItem()
    let customerModel1 = JSON.parse(localStorage.getItem("customermodel"))
if(customerModel1) {
  var customerModel = customerModel1;
}
  }, [])

  useEffect(() => {
    if (customerModel?.Customer_Receiving_Method === 'REQUESTED_PLACE' || customerModel?.Customer_Receiving_Method === 'OTHERS') {
      setVisible(true);
    }
    if (customerModel?.Customer_Receiving_Method === 'OTHERS') {
      setVisibleHint(true);
    }
    window.addEventListener('keypress', handlePressEnter);

    return () => {
      window.removeEventListener('keypress', handlePressEnter);
    };
  }, [handlePressEnter, customerModel?.Customer_Receiving_Method]);

  let title = null;
  if (customerModel?.Customer_Title && typeof (customerModel.Customer_Title) !== 'number') {
    if (customerModel.Customer_Title === 'Anh' || customerModel.Customer_Title === 'Mr.') {
      title = 1;
    } else if (customerModel.Customer_Title === 'Mrs.' && language === 'vi') {
      title = 2;
    } else if (customerModel.Customer_Title === 'Mrs.') {
      title = 3;
    } else {
      title = 2;
    }
  }
  if (customerModel?.Customer_Title && typeof (customerModel.Customer_Title) === 'number') {
    title = customerModel.Customer_Title;
    if (language === 'vi' && title === 3) {
      title = 2;
    }
  }
  const linkCart = `/${language}/cart`
  const linkSingIn = `/${language}/signIn`
  const linkRegister = `/${language}/register`
console.log(customerModel);
  return (
    <main className='customer-info__list'>
      <div className="container">
        <div className="customer-info__list-content">
          <Link to={linkCart} className="customer-info__list-return">
            <ArrowLeftOutlined className='customer-info__list-return-icon' />
            {CART_LANGUAGE[language]['BACK_TO_CART']}
          </Link>
          <div className="customer-info__list-content-main">
            <h3>{CART_LANGUAGE[language]['CUSTOMER_INFO']}</h3>
            {!userInfoModel &&
              <>
                <p>{CART_LANGUAGE[language]['ACCOUNT_ALREADY']} <Link to={linkSingIn}>{CART_LANGUAGE[language]['SIGN_IN']}</Link> </p>
                <p>{CART_LANGUAGE[language]['DO_NOT_HAVE_ACCOUNT']} <Link to={linkRegister}>{CART_LANGUAGE[language]['REGISTER_NOW']}</Link> </p>
              </>
            }
            <Form form={form} requiredMark={false} id='change-customer-info-form'
              onValuesChange={handleFormValuesChange}
              colon={false}
              layout='vertical'
              name='change-customer-info-form'
            >
              <Form.Item
                label={CART_LANGUAGE[language]['FULLNAME']}
                className='customer__form-item customer__fullname'
                labelAlign='left'
                initialValue={customerModel?.Customer_Fullname}
                name='Customer_Fullname'
                rules={[{ required: true, message: CART_LANGUAGE[language]['FULLNAME_REQUIRED'] }]}
              >
                <Input className='customer__form-input' />
              </Form.Item>
              <Form.Item
                label='Email'
                className='customer__form-item customer__email'
                labelAlign='left'
                name='Customer_Email'
                initialValue={customerModel?.Customer_Email}
                rules={[{ required: true, type: 'email', message: CART_LANGUAGE[language]['EMAIL_INVALID'] }]}
              >
                <Input className='customer__form-input' />
              </Form.Item>
              <Form.Item
                label={CART_LANGUAGE[language]['PHONE_NUMBER']}
                className='customer__form-item customer__phone-number'
                labelAlign='left'
                initialValue={customerModel?.Customer_PhoneNumber}
                name='Customer_PhoneNumber'
                rules={[{ required: true,  pattern: /^[\d]{0,10}$/, message: CART_LANGUAGE[language]['PHONE_NUMBER_REQUIRED'] }]}

              >
                <Input className='customer__form-input' 
                // type='number' 
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                label={CART_LANGUAGE[language]['RECEIVING_METHOD']}
                className='customer__form-item customer__receiving__method'
                labelAlign='left'
                initialValue={customerModel?.Customer_Receiving_Method || 'WAREHOUSE'}
                name='Customer_Receiving_Method'
                rules={[{ required: true, message: CART_LANGUAGE[language]['RECEIVING_METHOD_REQUIRED'] }]}
              >
                <Select>
                  {RECEIVING_METHOD[language].map((item) => {
                    return <Select.Option value={item.value} key={item.id}>{item.title}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              {visibleHint &&
                <p className='customer__receiving__method__hint' dangerouslySetInnerHTML={{ __html: CART_LANGUAGE[language]['ADDRESS_OR_METHOD_HINT'] }} />
              }
              {visible && <Form.Item
                label={CART_LANGUAGE[language]['ADDRESS_OR_METHOD_DESIRED']}
                className='customer__form-item customer__address'
                labelAlign='left'
                initialValue={customerModel?.Customer_Address}
                name='Customer_Address'
                rules={[{ required: true, message: CART_LANGUAGE[language]['ADDRESS_OR_METHOD_REQUIRED'] }]}
              >
                <Input className='customer__form-input' />
              </Form.Item>}
              <Form.Item
                label={CART_LANGUAGE[language]['CUSTOMER_TITLE']}
                className='customer__form-item customer__title'
                initialValue={customerModel?.Customer_Title}
                labelAlign='left'
                name='Customer_Title'
                rules={[{ required: true, message: CART_LANGUAGE[language]['CUSTOMER_TITLE_REQUIRED'] }]}
              >
                <Select>
                  {TITLE_DATASOURCE[language].map((item) => {
                    return <Select.Option  value={item.value} key={item.id}>{item.title}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <div className="customer__form-action">
                <button onClick={handleFinish} className='btn-submit-form'>
                  {CART_LANGUAGE[language]['CONTINUE']}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
}

export default CustomerInfoList;
