import React, { useEffect } from 'react';
import './Css/Home.css';
import HomeSlider from './Control/HomeSlider';
import { HomeCommon } from './Model/HomeModel';
import HomeContent from './HomeContent';
import Helmet from '../Layout/Helmet';

function HomePage(props) {
    const { language } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Helmet title={HomeCommon[language].title}>
            <HomeSlider language={language} slider={HomeCommon[language].slider}></HomeSlider>
            <HomeContent language={language}></HomeContent>
        </Helmet>
    );
}

export default HomePage;
