import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import BGNews from '../../asset/img/BGnews.png';
import { Link } from 'react-router-dom';
import HomeProductCard from './Control/HomeProductCard';
import { ProductService } from '../Product/Service/Product.Service';
import { RESULT_CODES } from '../../common/Constant.Common';
import { ManageBannerService } from './Service/ManageBanner.Service';

export default function HomeContent({ language }) {
  const [searchOption] = useState({ page: 1, limit: 6, sort: { CreatedDate: -1 } });
  const [products, setProducts] = useState([]);
  const [dataSource, setDataSource] = useState ([])

  useEffect (() => {
    const searchOption = {
      limit: 10,
      page: 1
    }
    const searchModel = {
      News_Sort: false
    }
    ManageBannerService.getImagesForProductsPage (searchModel, searchOption).then (result => {
      setDataSource (result?.result?.docs)
    })
  }, [])

  useEffect(() => {
    ProductService.landingPageSearch({ Status: true }, searchOption).then(result => {
      if (result.returnCode !== RESULT_CODES.SUCCESS) return;

      const data = result.result.docs;
      setProducts(data || []);
    }).catch(() => {
      // TO DO
    });
  }, [searchOption]);
  return (
    <div className='home__main'>
      <div className='home__main__child'>
        <div className='home__main__products--overlay' style={{
          background: `url(${BGNews})`,
        }} />
        <div className='container'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='home__main__heading' style = {{marginTop: (dataSource.length === 0)?'80px':'0px'}}>
            <Col lg={12} md={14} sm={12} xs={24}>
              <div className='home__main__title'>
                <div className='home__main__title__circle home__main__title__circle--red'></div>
                <h2 className='home__main__title__main'>
                  {language === 'vi' ? 'CÁC SẢN PHẨM CHÍNH' : 'MAIN PRODUCTS'}
                </h2>
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {products.map((product => (
              <Col key={product._id} lg={8} md={12} sm={24} xs={24}>
                <HomeProductCard product={product} language={language} />
              </Col>
            )))}
          </Row>
          <div className='home-button'>
            <Link to={`/${language}/products`} className='btn-seeMore'>
              {language === 'vi' ? 'Xem thêm' : 'See more'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
