import React from 'react';
import { Link } from 'react-router-dom';
import { SwapRightOutlined } from '@ant-design/icons';
import { getProductNameByLanguage } from '../../Product/Helper/Helper';
import noImages from '../../../asset/img/no-image.png';

function HomeProductCard({ product, language = 'vi' }) {

  const thumbnail = product.Product_Images?.[0]?.name
    ? `${process.env.REACT_APP_API_URL}/products/${product.Product_Images?.[0]?.name}`
    : noImages;

  return (
    <div className='home__product__card'>
      <div className="home__product__card__item" style={{ backgroundImage: `url(${thumbnail})` }}>
        <div className="home__product__card__content">
          <p className='text-clamp text-clamp--2'>{getProductNameByLanguage(product, language)}</p>
          <Link to={`/${language}/product/${product._id}`} className='home__product__card__content__detail'>
            {language === 'vi' ? 'Chi tiết' : 'Details'}
            <div className='home__product__card__content__detail__icon'>
              <SwapRightOutlined />
            </div>
            <div className='home__product__card__content__detail__line' />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeProductCard;
