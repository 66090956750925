import 'antd/dist/antd.css';
import './components/Layout/Css/layout.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Index from './route/Index';
import CartContextProvider from './components/Cart/Context/CartContext';
import CheckoutPage from './components/Checkout/CheckoutPage';
import CustomerInfoPage from './components/Checkout/CustomerInfoPage';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import LanguageContextProvider from './components/ContextProvider/LanguageContextProvider';

function App() {
  return (
    <BrowserRouter>
      <ReactNotification />
      <Switch>
        <LanguageContextProvider>
          <CartContextProvider>
            <Switch>
              <Route exact path="/cart/checkout" component={CheckoutPage} />
              <Route exact path="/cart/changecustomerinfo" component={CustomerInfoPage} />
              <Route path="/" component={Index} />
            </Switch>
          </CartContextProvider>
        </LanguageContextProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
