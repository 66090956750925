import React, { createContext, useEffect, useState } from 'react';
import { RESULT_CODES } from '../../common/Constant.Common';
import { UserService } from '../User/Service/UserService';
import { useHistory } from 'react-router-dom';


export const LanguageContext = createContext();
function LanguageContextProvider({ children }) {
  const history = useHistory();
  const [language, setLanguage] = useState('');


  useEffect(() => {
    const language1=window.location.pathname.split('/')[1]
    console.log('check',language1);
    if(language1 !== 'vi' && language1 !== 'en'){
      
      UserService.getUserLocation().then(result => {
        if (result.returnCode !== RESULT_CODES.SUCCESS) throw new Error(result.returnCode);
        const { data } = result;
        console.log(result);
        if (data) {
          if (data.country === 'VN') {
            setLanguage('vi');
            history.push(`/vi`);
          } else {
            setLanguage('en');
            history.push(`/en`);
          }
        }
      }).catch(() => {
        setLanguage('en');
      });

    }
    else{
      setLanguage(language1)
    }
    
  }, []);
  const handleChangeLanguage = (data) => {
    localStorage.setItem('language',JSON.stringify(data))
    setLanguage(data);
    let string1=window.location.pathname.substring(3);
        let string2=`/${data}`.concat(string1);
        // window.location.href=string2
        history.push(string2)
  };

  const contextValues = {
    language: language || 'vi',
    handleChangeLanguage
  };

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageContextProvider;
