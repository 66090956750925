import { authHeader } from '../../../helper/authHeader';

export const LoginService = {
    create,
    login,
    getUserInfor,
    logout,
    update,
    changePassword,
    changePasswordForget,
    sendMailForget
};
const content = 'Content-Type';
const Type = 'application/json';

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/signIn`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        })
}

function create(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/create`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        })
}

function getUserInfor() {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'GET',
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/getUserInfor`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function update(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/updateUser`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}


function changePassword(model) {
    const auth = authHeader();
    const headers = new Headers();
    headers.append(content, Type);
    headers.append('Authorization', auth);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/changePassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function changePasswordForget(model){
    const headers = new Headers();
    headers.append(content, Type);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/changePasswordForget`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        })
}

function sendMailForget(model){
    const headers = new Headers();
    headers.append(content, Type);
    const requestOptions = {
        headers,
        method: 'POST',
        body: JSON.stringify(model)
    };
    return fetch(process.env.REACT_APP_API_URL + `/User/sendMailForget`, requestOptions)
    .then(handleResponse)
    .then(result => {
        return result;
    })
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('accessToken');
    // localStorage.removeItem('userInfor');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}