import React, { useEffect } from 'react';
import { useCart } from './CustomHook/useCart';
import Breadcrum from '../Layout/Breadcrum';
import Helmet from '../Layout/Helmet';
import backgroundTitle from '../../asset/img/img-8.jpg';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import CartEmpty from './CartEmpty';
import CartList from './CartList';
import { CART_LANGUAGE } from './Language/Language';
import './Css/Style.css';

function CartPage({ language }) {
  const { cartItems } = useCart();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title={CART_LANGUAGE[language]['TITLE']}>
      <Breadcrum data={[CART_LANGUAGE[language]['TITLE']]} />
      <TitleBreadcrum title={CART_LANGUAGE[language]['TITLE']} background={backgroundTitle} />
      <div className="cart">
        <div className="container">
          {cartItems.length === 0 ? <CartEmpty language={language} /> : <CartList language={language} cartItems={cartItems} />}
        </div>
      </div>
    </Helmet>
  );
}

export default CartPage;

