export const PolicyCommon = {
  vi: {
    title: 'Hỗ trợ khách hàng',
    PolicySecurity: {
      title: 'Chính sách bảo mật website',
      path: '/policy/security',
      content: `
        <p>Thép Tiến Lên tôn trọng sự riêng tư của khách hàng, và chúng tôi cam kết bảo mật những thông tin cá nhân mang tính riêng tư của khách hàng. 
        Trong tài liệu này, “thông tin cá nhân” được định nghĩa bao gồm tên, địa chỉ, địa chỉ email, số điện thoại hay bất kỳ thông tin cá nhân nào khác mà 
        Quý khách cung cấp. Quý khách vui lòng đọc chính sách bảo mật thông tin dưới đây để hiểu rõ hơn về những thông tin cá nhân nào mà chúng tôi sẽ thu thập, 
        cũng như cách chúng tôi sử dụng những thông tin này và những cam kết chúng tôi thực hiện. <p>Khi Quý khách truy cập và sử dụng trang web 
        (bao gồm cả việc đăng ký tài khoản), 
        chúng tôi hiểu rằng Quý khách đã hoàn toàn đồng ý với những điều khoản của chính sách này.</p> </p>
        <h3>1. THU THẬP THÔNG TIN CÁ NHÂN</h3>
        <p>
        Các loại thông tin cá nhân sau đây có thể được thu thập, lưu trữ và sử dụng
        <br>
        <ul style="margin-left: 22px">
          <li style="list-style: disc">
          Lượt truy cập và sử dụng website này của Quý khách bao gồm nguồn giới thiệu, 
          thời lượng truy cập, số trang quý khách xem, số links (liên kết). 
          Ngoài ra chúng tôi cũng thu thập thông tin về trình duyệt Web của Quý khách như địa chỉ IP, vị trí địa lý, 
          phiên bản trình duyệt, 
          thông tin như địa chỉ email mà bạn nhập khi đăng ký tài khoản với hoặc khi liên hệ qua website của chúng tôi;
          </li>
          <br>
          <li style="list-style: disc">
            Thông tin quý khách nhập khi đăng ký tài khoản trên trang web của chúng tôi bao gồm tên, địa chỉ, địa chỉ email. 
            Mọi thông tin phải đảm bảo tính chính xác và hợp pháp. Chúng tôi không chịu trách nhiệm liên quan đến pháp luật của thông tin; 
          </li>
          <br>
          <li style="list-style: disc">
            Thông tin liên quan đến bất cứ thứ gì bạn mua trên website của chúng tôi; 
          </li>
          <br>
          <li style="list-style: disc">
            Thông tin có trong bất kỳ liên lạc nào mà bạn gửi cho chúng tôi bằng email hoặc thông qua website của chúng tôi, bao gồm nội dung liên lạc; 
          </li>
          <br>
          <li style="list-style: disc">
            Bất kỳ thông tin cá nhân nào khác mà bạn gửi cho chúng tôi.
          </li>
        </ul>
        </p>
        <h3>2. PHẠM VI SỬ DỤNG THÔNG TIN CÁ NHÂN</h3>
        <p>
        Các thông tin cá nhân Quý khách cung cấp sẽ được sử dụng để chúng tôi đáp ứng yêu cầu của Quý khách hoặc 
        để chúng tôi có thể liên lạc trực tiếp với Quý khách qua các hình thức như thư từ, email, 
        tin nhắn hay điện thoại. Khi cung cấp thông tin cho chúng tôi qua trang web này,
         Quý khách hiểu rõ và chấp thuận việc thu thập, sử dụng và tiết lộ những thông tin 
         cá nhân nêu trên cho mục đích được xác định trong chính sách này hoặc trên các trang có liên quan của Website.
        <p>
        Khi Quý khách sử dụng trang web này đồng nghĩa với việc Quý khách hoàn toàn đồng ý và chấp thuận rằng 
        những thông tin cá nhân Quý khách cung cấp có thể được bảo lưu tại trụ sở hay chi nhánh của Thép Tiến Lên
         với mục đích nâng cấp và phát triển trang web cũng như các dịch vụ của Thép Tiến Lên.</p> </p>
        <h3>3. AN TOÀN THÔNG TIN</h3>
        <p>Chúng tôi cam kết bảo vệ sự riêng tư của Quý khách, không cung cấp, chuyển giao hay tiết lộ thông tin cá nhân của 
        Quý khách cho bất kỳ bên thứ ba nào để họ hoặc bất kỳ bên thứ ba nào khác với mục đích khuyến mãi nếu không có sự đồng ý rõ ràng của 
        khách hàng trừ trường hợp buộc phải cung cấp khi Cơ quan chức năng yêu cầu. 
        <br>
        Để đảm bảo sự an toàn tối đa về bảo thông tin, trang web này được áp dụng công nghệ SSL (Secure Sockets Layer) 
        nhằm bảo vệ tính bảo mật và an toàn cho thông tin của khách hàng. Tuy nhiên, rủi ro khi gửi những dữ liệu trên đường truyền vẫn hiện hữu 
        và không có hệ thống kỹ thuật nào có thể được bảo mật tuyệt đối. Do đó chúng tôi không thể cam kết chắc chắn rằng 
        và cũng không chịu trách nhiệm với các thông tin khách hàng cung cấp sẽ được bảo mật một cách tuyệt đối an toàn. 
        Dù vậy, Thép Tiến Lên sẽ luôn nỗ lực cđíchập nhật công nghệ mới và tiến hành những biên pháp đề phòng thích hợp để giảm thiểu tối đa rủi ro cho Quý khách hàng.
        </p>
        <h3>4. QUYỀN HẠN CỦA NGƯỜI DÙNG</h3> 
        <p>
        Khi Quý khách phát hiện thông tin cá nhân bị sử dụng sai mục đích hoặc bị lạm dụng thì 
        Quý khách có thể gửi khiếu nại thông qua trang liên lạc của chúng tôi hoặc theo địa chỉ sau: <br>
        <strong><i>Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên</i></strong><br>
        <strong><i>G4A, Khu Phố 4, Phường Tân Hiệp, Thành Phố Biên Hoà, Tỉnh Đồng Nai.</i></strong><br>
        <strong><i>Số điện thoại: 02513823187</i></strong><br>
        <strong><i>Địa chỉ email: info@tienlen.com.vn</i></strong><br>
        Chúng tôi sẽ dùng mọi biện pháp cần thiết để ngăn chặn không cho thông tin cá nhân đó bị tiếp tục lạm dụng, 
        đồng thời có các biện pháp hỗ trợ để bảo vệ quyền và lợi ích hợp pháp của khách hàng. 
        <br>
        Khi tiếp nhận những phản hồi này, chúng tôi sẽ xác nhận lại thông tin, chứng cớ và sẽ trả lời trong phạm vi có thể để hướng dẫn 
        khách hàng khôi phục và bảo mật lại thông tin. Trong trường hợp hình thức thoả thuận không đạt được giữa hai bên, 
        một trong hai bên có quyền nhờ đến cơ quan pháp luật có thẩm quyền để bảo vệ lợi ích hợp pháp của mình.</p>
        <h3>5. COOKIES </h3> 
        <p>Website của chúng tôi có thể thu thập cookie để giúp Quý khách có trải nghiệm tốt hơn khi sử dụng Website. 
        Quý khách có quyền đồng ý hoặc không đồng ý sử dụng Cookie.</p>
        <h3>6. LIÊN KẾT</h3> 
        <p>Website của chúng tôi có thể dẫn nguồn link tới những trang web khác như đối tác, trang chủ của Công Ty Cổ Phần Tập đoàn Thép Tiến Lên 
        hoặc những dịch vụ liên quan. Việc này nhằm mang lại cho Quý khách trải nghiệm tốt hơn và hiểu rõ hơn về sản phẩm và dịch vụ chúng tôi cung cấp. 
        Chúng tôi không chịu trách nhiệm về nội dung và quyền riêng tư khi Quý khách truy cập đến website được liên kết đó.</p>
        <h3>7. THAY ĐỔI VỀ CHÍNH SÁCH</h3> 
        <p>Thép Tiến Lên có quyền thay đổi quy định này bao gồm việc bổ sung, loại bỏ một phần nội dung hoặc tạm ngưng trang web mà không cần báo trước. 
        Tuy nhiên, nếu quy định này được thay đổi theo hướng có thể gây bất lợi cho khách hàng, 
        Thép Tiến Lên sẽ thông báo trước trên website chính <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a></p>
      `
    },
    PolicyUsage: {
      title: 'Chính sách sử dụng website',
      path: '/policy/usage',
      content: `
            <h3>LỜI NGỎ</h3> 
            <p>Chân thành cảm ơn bạn đã truy cập website chính thức của Tập Đoàn Thép Tiến Lên. 
            <br>Kính mong bạn giành chút thời gian để xem qua và tham khảo các điều khoản về các thỏa thuận pháp lý giữa bạn 
            và Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên (sau đây được gọi tắt là Thép Tiến Lên).
            </p>
            <p>Thông qua việc truy cập, duyệt, hoặc sử dụng bất kì nội dung hoặc hình ảnh nào của trang thông tin điện tử này 
            và các trang web trực thuộc (bao gồm trang chủ <a target='_blank' href='https://tienlensteel.com.vn'>https://www.tienlensteel.com.vn</a>), 
            bạn (người sử dụng) công nhận rằng bạn đã đọc, 
            hiểu và nhất trí với việc tuân thủ các quy định của những điều khoản này. Nếu bạn (người sử dụng) không nhất trí với những điều khoản này, 
            xin vui lòng không sử dụng trang thông tin điện tử này.</p>
            <p>Thép Tiến Lên chịu trách nhiệm đảm bảo tính minh bạch và hợp pháp của việc cung cấp những nội dung trên trang web đến người dùng (bạn). 
            Toàn bộ những thông tin, hình ảnh và những nội dung Tập Đoàn được đăng tải trên trang web đảm bảo tuân thủ các quy định của pháp luật 
            và phù hợp với thuần phong mỹ tục Việt Nam.</p>
            <p>Thép Tiến Lên cung cấp thông tin, tài liệu và hình ảnh như một tiện ích cho bạn với tư cách là người sử dụng website. 
            Người dùng website được phép liên hệ trực tiếp với đội ngũ nhân viên của Thép Tiến Lên thông qua số điện thoại và địa chỉ email được cung cấp.</p>
            <h3>CÁC TÍNH NĂNG THIẾT YẾU</h3>
            <p>Thép Tiến Lên cung cấp thông tin kỹ thuật, các thông số, xuất xứ cũng như các tài liệu liên quan đến những sản phẩm và các dịch vụ của Thép Tiến Lên. 
            Người sử dụng được trao đổi trực tiếp với nhân viên của Thép Tiến Lên thông qua địa chỉ email hoặc số điện thoại được cung cấp trên trang web này. 
            Việc sử dụng trang web này là hoàn toàn miễn phí. Thép Tiến Lên bảo lưu quyền đặt quảng cáo, bao gồm cả quảng cáo banner, trên trang web.</p>
            <p>Quyền truy cập vào website của Thép Tiến Lên và các trang web trực thuộc 
            (bao gồm trang chủ <a target='_blank' href='https://tienlensteel.com.vn'>https://www.tienlensteel.com.vn</a>) chỉ dành cho những người 
            có đủ khả năng nhận thức và có năng lực pháp lý. 
            Việc bạn sử dụng các dịch vụ trực tuyến được xác thực bằng tên người dùng và mật khẩu liên quan mà bạn cung cấp trong quá trình đăng ký.</p>
            <h3>THƯƠNG HIỆU VÀ BẢN QUYỀN</h3> 
            <p>Website này và các trang web trực thuộc (bao gồm trang chủ <a target='_blank' href='https://tienlensteel.com.vn'>https://www.tienlensteel.com.vn</a>) 
            và toàn bộ nội dung của website, bao gồm những văn bản, 
            thiết kế, đồ họa, giao diện, hình ảnh, mã code đều thuộc bản quyền của Thép Tiến Lên hoặc bên thứ ba cấp phép chính thức cho Thép Tiến Lên. 
            Bản quyền của Thép Tiến Lên được thể hiện trên website bằng dòng chữ tiếng Anh “Copyright Tien Len Steel Corporation Joint Stock Company. 
            All rights reserved.'</p>
            <p>Tất cả những nội dung được đăng tải và sử dụng trên trang web này và các trang web trực thuộc 
            (bao gồm trang chủ <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) 
            bao gồm nhãn hiệu, thương hiệu, tên thương mại, mẫu logo công ty, các hình ảnh, văn bản, các thông tin về sản phẩm, hoặc kiểu dáng sản phẩm... 
            đều thuộc quyền sở hữu của Thép Tiến Lên và được bảo hộ theo quy định của Luật Sở hữu trí tuệ Việt Nam và các văn bản liên quan.</p> 
            <p>Tất cả những hành vi có tính chất trích dẫn, sao chép, sửa đổi, xuất bản, lưu thông, phân phối... 
            nhằm mục đích thương mại dưới mọi hình thức sẽ mà không được sự chấp thuận trước bằng văn bản của Thép Tiến Lên là xâm phạm quyền của Thép Tiến Lên. 
            Thép Tiến Lên hoàn toàn có quyền yêu cầu người sử dụng chấm dứt việc sử dụng và bồi thường thiệt hại (nếu có).</p>
            <h3>QUYỀN VÀ TRÁCH NHIỆM CỦA THÉP TIẾN LÊN</h3> 
            <p>Thép Tiến Lên có quyền sửa đổi, thay thế và/hoặc chấm dứt các nội dung, 
            tính năng của một phần hay toàn bộ website mà không cần có bất kì thông báo nào với người sử dụng. 
            <br>Thép Tiến Lên có quyền thực hiện các biện pháp an ninh để bảo vệ và chống lại các truy cập trái phép hoặc sửa đổi trái phép, 
            tiết lộ hoặc phá hủy thông tin trên website.
            <br>Trong lúc sử dụng website, các thông tin và nội dung truy cập của người sử dụng sẽ được truyền qua một phương tiện/thiết bị 
            nằm ngoài sự kiểm soát của Thép Tiến Lên. Theo đó, Thép Tiến Lên hoàn toàn không chịu trách nhiệm cho hoặc liên quan đến bất kỳ sự chậm trễ, 
            thất bại, bị gián đoạn của bất kỳ dữ liệu hoặc các thông tin khác được truyền trong kết nối với việc sử dụng của website. 
            <br>Các thông tin cá nhân của người sử dụng website như địa chỉ, email, số điện thoại 
            (do người sử dụng tự nguyện cung cấp bằng các hình thức khác nhau trên website) có thể được sử dụng nội bộ cho mục đích nâng cấp các sản phẩm, 
            dịch vụ của Thép Tiến Lên và tuân thủ theo chính sách bảo mật thông tin khách hàng được đăng trên trang web này. 
            <br>Thép Tiến Lên được phép sử dụng bất kỳ ý tưởng hay khái niệm nào mà bạn đã đưa lên website cho tất cả các mục đích không giới hạn, 
            bao gồm cả việc phát triển, sản xuất hay tiếp thị sản phẩm. Tập Đoàn không có trách nhiệm phải bồi thường hay thưởng cho người cung cấp. 
            Người sử dụng phải ý thức và bảo đảm những thông tin, tài liệu khi gửi phải nằm trong quyền hạn sử dụng của của bản thân mình hoặc đã có sự 
            đồng ý từ bên thứ ba; điều đó có nghĩa Thép Tiến Lên sẽ không vi phạm bất cứ quyền lợi nào của bên thứ ba.
            <br>Thép Tiến Lên có quyền từ chối một số tài liệu bạn gửi mà không bắt buộc phản hồi lý do.</p>
            <h3>QUYỀN VÀ TRÁCH NHIỆM CỦA NGƯỜI SỬ DỤNG</h3> 
            <p>Người sử dụng có thể xem, chiết xuất thông tin trên website (in, tải, chuyển tiếp…) hoặc chia sẻ cho người khác nhưng chỉ cho mục đích sử dụng cá nhân 
            và phi thương mại với điều kiện phải trích dẫn thông báo bản quyền sau đây: “Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved.” </p>
            <p>Bạn đảm bảo tuân theo pháp luật và các quy định liên quan đến việc sử dụng website của Thép Tiến Lên; 
            Tuyệt đối không can thiệp, gây ảnh hưởng đến việc sử dụng website của những người sử dụng khác; Không can thiệp vào hoạt động và quản lý website của Tập Đoàn.</p> 
            <p>Người dùng nhận thức rõ và chấp nhận rằng Thép Tiến Lên và/hoặc các công ty thành viên/đơn vị trực thuộc/nhân viên 
            Thép Tiến Lên không chịu trách nhiệm đối với bất kỳ tổn thất, thiệt hại, chi phí phát sinh từ bất kỳ quyết định nào của bạn khi sử dụng bất kỳ thông tin nào 
            trên website với bất kỳ nguyên nhân gì.</p> 
            <p>Nếu bạn không bằng lòng với bất kỳ thông tin nào trên Website hoặc với bất kỳ điều khoản và điều kiện sử dụng 
            thông tin trên Website này thì phương thức duy nhất bạn nên thực hiện là chấm dứt truy cập/sử dụng thông tin trên website.</p>
            <h3>CHÍNH SÁCH CHO LIÊN KẾT URL</h3> 
            <p>Bất cứ ai liên kết tới trang web của Thép Tiến Lên và các trang web trực thuộc 
            (bao gồm trang chủ <a target='_blank' href='https://tienlensteel.com.vn'>https://www.tienlensteel.com.vn</a>) 
            được coi là hoàn toàn hiểu và đồng ý với các mục đươc liệt kê dưới đây mà không có phản đối nào:</p>
            <p>Liên kết đến trang web của Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên (sau đây gọi là một “Liên Kết Nguồn”) không biểu thị, 
            hoặc trực tiếp hoặc gián tiếp, bất cứ mối quan hệ nào giữa Thép Tiến Lên với cá nhân, tổ chức, quản lý các Đường Dẫn, 
            chúng cũng không có nghĩa là Thép Tiến Lên giới thiệu và chịu trách nhiệm về nội dung của các trang web có tại Liên Kết Nguồn.</p>
            <p>Những cá nhân hoặc tổ chức, có một Liên Kết Nguồn tới trang web của Thép Tiến Lên được yêu cầu phải thực hiện theo các điều khoản sử dụng của 
            Liên Kết Nguồn đó và các điều khoản sử dụng của website Thép Tiến Lên. Trong mọi trường hợp, Thép Tiến Lên không chịu trách nhiệm cho bất kỳ thiệt hại 
            hoặc tổn thất phát sinh từ bất kỳ Liên Kết Nguồn nào.</p>
            <h3>MIỄN TRỪ TRÁCH NHIỆM</h3> 
            <p>Thép Tiến Lên luôn kiểm tra chu đáo tất cả các thông tin trước khi đăng trên website, tuy nhiên không đảm bảo về độ chính xác tuyệt đối 
            hoặc sự phù hợp của các thông tin cho một mục đích cụ thể.</p>
            <p>Trong mọi trường hợp, Thép Tiến Lên sẽ không chịu trách nhiệm cho bất kì thiệt hại phát sinh từ việc sử dụng hoặc không thể sử dụng trang web 
            này hoặc từ các dữ liệu độc hại như virus máy tính hoặc từ bất kì nguyên nhân nào khác.</p>
            <p>Những yêu cầu hoặc những câu hỏi được gửi đến Thép Tiến Lên khi thực hiện qua trang web này có thể không nhận được câu trả lời do 
            các trục trặc về hệ thống, trục trặc về mã hóa, do nhiễm virus hoặc do lỗi đánh máy. 
            Nếu bạn không nhận được câu trả lời trong vòng 48 tiếng sau khi gửi yêu cầu, vui lòng gửi lại lần nữa hoặc liên hệ theo phương thức khác.</p>
            <h3>ĐIỀU KHOẢN CHUNG</h3> 
            <p>Điều khoản sử dụng được điều chỉnh bởi pháp luật Việt Nam. Một điều khoản sử dụng bị vô hiệu theo quyết định của tòa án có thẩm quyền 
            sẽ không ảnh hưởng đến tính hiệu lực của các điều khoản còn lại.</p>
            <p>Thép Tiến Lên luôn hoan nghênh những ý kiến/góp ý của bạn về nội dung website. Nếu có một phần nào đó của website này mà bạn cho rằng đã 
            có dấu hiệu vi phạm bản quyền của bên thứ ba, vui lòng gửi liên hệ với Thép Tiến Lên qua số điện thoại và những địa chỉ email được cung cấp.</p>
            <p>Thông báo này và tất cả các điều khoản sử dụng tạo thành toàn bộ thỏa thuận giữa Thép Tiến Lên và bạn liên quan đến việc sử dụng các 
            thông tin trên website. Thép Tiến Lên có thể điều chỉnh nội dung thông báo này bất cứ lúc nào bằng cách cập nhật lên website. 
            Bạn nên thường xuyên truy cập website để theo dõi các quy định ràng buộc này khi sử dụng.</p> 
            <p>Bất kỳ tranh chấp hay khiếu nại nào phát sinh hoặc liên quan đến giao dịch tại website sẽ được ưu tiên giải quyết bằng hình thức thương lượng, 
            hòa giải và/ hoặc các văn bản pháp luật hiện hành của Việt Nam.</p>
            `
    },
    PolicySale: {
      title: 'Chính sách bán hàng',
      path: '/policy/sales',
      category: [
        'PHƯƠNG THỨC ĐẶT HÀNG VÀ TIẾP NHẬN XỬ LÝ ĐƠN HÀNG',
        'CHÍNH SÁCH GIA CÔNG',
        'PHƯƠNG THỨC THANH TOÁN',
        'PHƯƠNG THỨC GIAO HÀNG',
        'CHÍNH SÁCH DÀNH CHO KHÁCH HÀNG THƯỜNG XUYÊN',
        'CHẤT LƯỢNG SẢN PHẦM VÀ QUYỀN LỢI CỦA KHÁCH HÀNG',
        'THAY ĐỔI VỀ CHÍNH SÁCH',
      ],
      content: `
      <h3 id='sales-1'>1. PHƯƠNG THỨC ĐẶT HÀNG VÀ TIẾP NHẬN XỬ LÝ ĐƠN HÀNG</h3> 
      <p>
        Nhằm thuận tiện cho khách hàng và đáp ứng kịp thời nhu cầu, tiết kiệm tối đa thời gian quý báu của khách hàng, Tập đoàn thép tiến lên đã triển khai 
        nhiều kênh thông tin tiếp nhận yêu cầu của khách hàng như: Điện thoại, Email, Website… Các thông tin sau khi tiếp nhận sẽ được nhanh chóng chuyển đến nhân 
        viên kinh doanh tại tổng công ty hoặc các chi nhánh trực thuộc tuỳ vào nơi giao hàng mong muốn của khách hàng.
      </p>
      <p>
        Sau khi tư vấn, giới thiệu, báo giá và khách hàng đã đồng ý với giá cả, hai bên sẽ tiến hành ký kết hợp đồng mua bán hoặc thỏa thuận trực tiếp trên 
        đơn hàng với điều khoản chủ yếu do hai bên thoả thuận.
      </p>
      <p>Các kênh thông tin tiếp nhận đơn hàng của quý khách gồm:
      <br>• Email: sales@tienlen.com.vn 
      <br>• Số điện thoại:
      <br>&nbsp;&nbsp;&nbsp;&nbsp;Tổng đài: 0251.3823187 (máy lẻ 117)
      <br>&nbsp;&nbsp;&nbsp;&nbsp;Phòng kinh doanh: 0251.8823087 
      <br>• Website: www.product.tienlensteel.com.vn (Đặt hàng qua mạng) 
      <br>• Gặp trực tiếp tại văn phòng tập đoàn hoặc tại cửa hàng, chi nhánh, công ty thành viên: </p>
      <p><strong><u style="text-decoration: none">Tại tỉnh Đồng Nai:</u></strong>
      <br><span style="text-decoration: underline">Toà nhà điều hành Công ty Cổ Phần Tập Đoàn Thép Tiến Lên</span>
      <br>Địa chỉ : Số G4A, khu phố 4, phường Tân Hiệp, thành phố Biên Hoà, tỉnh Đồng Nai
      <br>Điện thoại : 0251.3823.187 ( máy lẻ 117 ); 0251.8823.087 Fax : 0251.3829.043 
      </p>
      <p><strong><u style="text-decoration: none;">Tại TP. Hồ Chí Minh:</u></strong> 
      <br><span style="text-decoration: underline">Công ty TNHH Sản Xuất và Thương Mại Đại Phúc </span>
      <br>Địa chỉ : Lô số 8, đường Song Hành, KCN Tân Tạo, Phường Tân Tạo A, Quận Bình Tân, TP. Hồ Chí Minh 
      <br><span style="text-decoration: underline">Công ty TNHH Thép Tây Nguyên </span>
      <br>Địa chỉ : Lô 12-14 đường số 1, KCN Tân Tạo, Phường Tân Tạo A, Quận Bình Tân, TP.Hồ Chí Minh. 
      </p>
      <p><strong><u style="text-decoration: none">Tại Đà Nẵng:</u></strong> 
      <br><span style="text-decoration: underline">Chi nhánh Công ty CP tập đoàn thép Tiến Lên tại TP. Đà Nẵng</span>
      <br>Địa chỉ : Xã Hoà Châu, Huyện Hoà Vang, thành phố Đà Nẵng
      </p>
      <p><strong><u style="text-decoration: none">Tại Hà Nội:</u></strong> 
      <br><span style="text-decoration: underline">Công ty TNHH MTV Thép Phúc Tiến </span>
      <br>Địa chỉ : 307-309 đường Tam Trinh, Quận Hoàng Mai, TP.Hà Nội.
      </p> 
      <p><strong><u style="text-decoration: none">Tại Hưng Yên:</u></strong> 
      <br><span style="text-decoration: underline">Công ty TNHH Sản xuất và Thương mại Phúc Tiến Hưng Yên. </span>
      <br>Địa chỉ : Thôn Nghĩa Trai, Xã Tân Quang, Huyện Văn Lâm, Tỉnh Hưng Yên
      <p><strong><u style="text-decoration: none">Tại Hải Phòng:</u></strong> 
      <br><span style="text-decoration: underline">Công ty TNHH Phúc Tiến Hải Phòng</span>
      <br>Địa chỉ : Thôn Bắc Hà, Xã Bắc Sơn, Huyện An Dương, Thành Phố Hải Phòng
      <br>
      <p>
        Với phương thức giao dịch đặt hàng nêu trên, Tập đoàn Thép Tiến Lên chúng tôi cam kết phụ vụ Quý khách hàng tận tình chu đáo hiệu quả/cùng hợp tác - 
        cùng phát triển. Website bán hàng <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a> 
        do Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên trực tiếp thực hiện và vận hành hoạt động, 
        đối tượng phục vụ là tất cả các khách hàng trên mọi miền đất nước có nhu cầu sử dụng sản phẩm. 
        Việc thực hiện giao dịch mua bán đều minh bạch, đảm bảo tối đa mọi quyền lợi cho người tiêu dùng,
      </p>
      <h3 id='sales-2'>2. CHÍNH SÁCH GIA CÔNG</h3> 
      <p>Công ty Cổ Phần Tập Đoàn Thép Tiến Lên là một trong những nhà gia công thép có máy móc hiện đại nhất trên thị trường Việt Nam. Máy móc của 
      Tập đoàn có thể gia công được những mặt hàng thép lớn, quy cách đặc biệt với độ khó cao mà vẫn duy trì được chất lượng của hàng hoá. 
      Trong đó bao gồm máy móc cắt thép tấm độ dày khác nhau từ 0.2mm tới 25mm, khổ từ 1m tới 2m và trọng lượng cuộn lên tới 35 tấn. 
      Nếu Quý khách có nhu cầu gia công, vui lòng liên hệ trực tiếp qua trang <a target='_blank' href='https://product.tienlensteel.com.vn/contact'>liên hệ</a>  để được nhân viên 
      Tập đoàn tư vấn thêm.</p>
      <h3 id='sales-3'>3. PHƯƠNG THỨC THANH TOÁN</h3> 
      <i><u style="text-decoration: none">a) <span style="text-decoration: underline">Thanh toán bằng tiền mặt hoặc chuyển khoản</span></u></i>
      <br>
      <p>Khách hàng có thể lựa chọn các phương thức thanh toán phù hợp cho các giao dịch của mình trước khi nhận hàng thông qua các hệ thống Ngân hàng hoặc tại 
      Công ty/chi nhánh/cửa hàng đại diện của Tập đoàn Tiến Lên trên mọi miền đất nước.</p>
      <p><i><u style="text-decoration: none">b) <span style="text-decoration: underline">Thanh Toán qua việc mở thư bảo lãnh trong nước</span></u></i> 
      <p>Để phục vụ nhu cầu giao dịch mua bán trả chậm/trả sau thời hạn nhận hàng và/hoặc để tiết giảm các chi phí tài chính đối với các giao dịch lớn giá trị cao, 
      khách hàng có thể lựa chọn hình thức mở Thư bảo lãnh thanh toán nội địa trong nước đối với giao dịch của mình. 
      Tập đoàn sẵn sàng tư vấn và hướng dẫn chi tiết cho khách hàng khi có nhu cầu.</p>
      <i><u style="text-decoration: none">c) <span style="text-decoration: underline">Nhập uỷ thác</span></u></i>
      <br>
      <p>Tập đoàn Thép Tiến Lên có cung cấp dịch vụ nhận nhập uỷ thác với mức phí hấp dẫn. Quý khách hàng sẽ được tư vấn trực tiếp khi có nhu cầu.</p>
      <p style="margin-left: 10px">
        i. Quý khách có thể thanh toán trực tiếp với khách hàng và Tập đoàn sẽ đứng ra làm thủ tục nhập/xuất khẩu và chỉ thu khoản phí dịch vụ làm thủ tục.
      </p>
      <p style="margin-left: 10px">
        ii. Quý khách uỷ thác cho Tập đoàn thanh toán và làm thủ tục nhập/xuất khẩu với khách hàng. Trong trường hợp này, 
        Tập đoàn sẽ thu phí lãi số tiền đứng ra thanh toán cho khách hàng với mức lãi suất hấp dẫn.
      </p>
      <p><i><u style="text-decoration: none">d) <span style="text-decoration: underline">Đối với mặt hàng xuất khẩu </span></u></i>
      <br>
      Đối với mặt hàng xuất khẩu, Tập đoàn nhận thanh toán qua chuyển khoản hoặc qua hình thức mở thư bảo lãnh tại ngân hàng.</p>
      <h3 id='sales-4'>4. PHƯƠNG THỨC GIAO HÀNG</h3>
      <p><strong>Nơi giao hàng</strong>
      <br>
      <i><u style="text-decoration: none">a) <span style="text-decoration: underline"></sp>Giao hàng tại nhà máy sản xuất</span></u></i>
      <br>Tập Đoàn Thép Tiến Lên hiện là đối tác hàng đầu của nhiều nhà máy sản xuất sắt thép trong nước như Posco Vietnam, Pomina, TungHo Vietnam, Thép Nhà Bè, 
      Thép An Khánh, Tisco, Đại Việt, Việt Ý, SSE... Quý khách có thể đặt hàng thông qua trang web bán hàng và ghi chú phương thức nhận hàng tại 
      nhà máy khi có nhu cầu và/hoặc theo sự chấp thuận của nhà máy sản xuất.
      <p><i><u style="text-decoration: none">b) 
      <span style="text-decoration: underline">Giao hàng tại các Hệ thống Cảng biển/Nhà ga/Bến tàu/Kho ngoại quan/Cửa khẩu</span></u></i> 
      <br>Là đơn vị tiên phong và nhập khẩu chuyên nghiệp các sản phẩm kim khí sắt thép, Thép Tiến Lên cung cấp đa dạng các sản phẩm có xuất sứ từ 
      những quốc gia sản xuất hàng đầu như Nhật Bản, Hàn Quốc, Thái Lan, Ấn Độ, Trung Quốc, Nga, Indonesia, Bra-xin…Chúng tối sẵn sàng cung cấp 
      dịch vụ giao hàng tại các Hệ thống Cảng biển/Nhà ga/Bến tàu/Kho ngoại quan/Cửa khẩu nhằm đáp ứng mọi nhu cầu thực tế từ phía khách hàng, 
      kịp thời đối với nguồn cung cấp nhanh chóng đến các công trường, dự án, đơn vị gia công…kể cả các loại hình: “From Warehouse to Warehouse” hay “Back to Back.”
      </p>
      <p><i><u style="text-decoration: none">c) 
      <span style="text-decoration: underline">Giao hàng tại hệ thống cửa hàng, kho bãi của Tập đoàn thép Tiến Lên</u></i>
      <br>Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên có hệ thống kho/bãi/nhà máy phủ rộng trên khắp thị trường ba miền đất nước để đáp ứng tối đa nhu cầu của quý </span>
      khách hàng, đáp ứng tối đa nhu cầu của Quý khách hàng. Với các danh mục hàng hoá phong phú phục vụ được tất cả các nhu cầu của khách hàng tại mọi thời điểm. 
      Khách hàng có thể tham khảo các đơn vị phân phối của Tập đoàn tại <a target='_blank' href='https://tienlensteel.com.vn/intro/agency'>đây</a> 
      </p>
      <p><i><u style="text-decoration: none">d) <span style="text-decoration: underline">Giao hàng tại địa điểm bên mua yêu cầu</i></u>
      <br>Công Ty Cổ Phần Tập Đoàn Thép Tiến Lên cung cấp giải pháp giao hàng đến tận kho hoặc công trình với tiêu chí đảm bảo chất lượng hàng hóa trên 
      suốt quá trình giao hàng và mức giá giao hàng hợp lý. Đối với hình thức này, chúng tôi sẽ tư vấn trực tiếp cho Quý khách hàng các phương án hợp lý nhất, 
      nhằm tiết kiệm tối đa chi phí, thời gian và nhân lực (như tư vấn về các tuyến Đường bộ, Đường biển, Đường sắt…) tuỳ theo điểm đích nhận hàng và 
      địa diểm theo yêu cầu từ phía Khách hàng.
      </p>
      <p><i><u style="text-decoration: none">e) <span style="text-decoration: underline">Để tại kho của Tập đoàn</i></u>
      <br>Ngoài ra, nhằm hỗ trợ khách hàng trong khâu chủ động chủ động cho nguồn hàng đã mua trong điều kiện khách hàng không có địa điểm/kho 
      để tập kết hàng hoá, Tập đoàn có cung cấp thêm dịch vụ gửi hàng/bảo quản/giao nhận với mức phí hấp dẫn. Tập đoàn có hệ thống nhà xưởng 
      kín bao gồm đầy đủ mái che và hệ thống cẩu trục nâng hạ đến 35 tấn đảm bảo sự an toàn tối đa cho chất lượng của sản phẩm/hàng hoá. 
      </p>
      <p><strong>Những thông tin khác</strong>
      <br>a) Để đảm bảo cho hàng hoá đến được tay khách hàng một cách an toàn, nhanh chóng và đảm bảo, khi mua hàng, Tập đoàn sẽ cùng khách hàng thoả thuận 
      những giấy tờ cần cung cấp cụ thể. 
      <br>b) Tập đoàn cũng cung cấp hoá đơn VAT theo chỉ định của bộ tài chính kèm theo chứng chỉ chất lượng để chứng minh nguồn gốc và chất lượng hàng hoá.
      </p>
      <h3 id='sales-5'>5. CHÍNH SÁCH DÀNH CHO KHÁCH HÀNG THƯỜNG XUYÊN</h3>
      <p>Đối với các khách hàng thường xuyên, khách hàng truyền thống lâu năm, khách hàng giao dịch các lô hàng trị giá lơn, Tập đoàn chúng tôi sẽ có những 
      đãi ngộ hấp dẫn như: áp dụng chính sách thanh toán riêng, áp dụng cơ chế giá đàm phán riêng, được ưu tiên trong việc tư vấn về biến động giá, 
      giữ giá/giữ hàng, được tham gia trong các chương trình tri ân khách hàng, chương trình áp dụng chính sách mua hàng giảm giá, chính sách tháng mua hàng 
      hỗ trợ vận chuyển/hỗ trợ giao hàng…</p>
      <h3 id='sales-6'>6. CHẤT LƯỢNG SẢN PHẨM VÀ QUYỀN LỢI CỦA KHÁCH HÀNG</h3>
      <p>Trước sức ép cạnh tranh khốc liệt ngay giữa những sản phẩm sản xuất trong nước và những sản phẩm nhập khẩu, Tập đoàn luôn xem chất 
      lượng là yếu tố sống còn. Để đảm bảo yêu cầu này, hai nhân tố chủ chốt của Thép Tiến Lên là đội ngũ lao động tâm huyết, 
      luôn trau dồi nâng cao chuyên môn về nghề và công nghệ tiên tiến, hiện đại.</p>
      <p>
        Bên cạnh chất lượng ổn định, giá cả phải chăng, Tập đoàn thép Tiến Lên còn đặc biệt quan tâm đến việc bảo vệ người tiêu dùng, 
        chống hàng giả, hàng nhái. Chính vì vậy, các sản phẩm do Thép Tiến Lên cung cấp ngày càng khẳng định được chỗ đứng trên thị trường. 
        Tập đoàn cũng đã đăng ký nhãn hiệu độc quyền tại Cục Sở hữu trí tuệ Việt Nam để tránh việc thương hiệu bị sử dụng với mục đích xấu.
      </p>
      <p>Với quan điểm bảo vệ khách hàng một cách tốt nhất, Tập đoàn thép Tiến Lên tiếp tục đẩy mạnh tuyên truyền chất lượng sản phẩm; Bên cạnh đó, 
      Tập đoàn cũng tổ chức mạng lưới phân phối xuyên suốt, thống nhất trong chuỗi bao gồm toàn bộ các công ty thành viên trong tập đoàn nhằm đồng nhất về quy trình, 
      quy chế, chính sách bán hàng.</p>
      <h3 id='sales-7'>7. THAY ĐỔI VỀ CHÍNH SÁCH</h3> 
      <p>a. Khi có sự thay đổi về chính sách bán hàng này, Tập đoàn sẽ đăng thông báo lên website 
      chính (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>) trước 15 ngày. Khi cập nhật nội dung chính sách này, 
      chúng tôi sẽ chỉnh sửa lại thời gian “Cập nhật lần cuối” bên dưới. Kính mong khách hàng cập nhật thông tin thường xuyên trên website của Tập đoàn.</p>
      <p>b. Nội dung “Chính sách bán hàng” này chỉ áp dụng tại Tập đoàn thép Tiến Lên, không bao gồm hoặc liên quan đến các bên thứ ba đặt quảng cáo hay có đường 
      link tại <a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a> 
      và <a target='_blank' href='https://tienlensteel.com.vn'>www.tienlensteel.com.vn</a></p> 
      <p>c. Chính sách bán hàng nêu trên bao gồm đầy đủ các yếu tố về mặt pháp lý theo phát luật Việt Nam trong mọi thời điểm/mọi nơi. 
      Chính sách này hợp pháp trước mọi giao dịch và các yêu cầu thanh toán, đảm bảo an toàn trước mọi giao dịch của khách hàng</p>
      <p>d. Tập đoàn Thép Tiến Lên chúng tôi cam kết: không tiết lộ hoặc sử dụng thông tin của khách hàng sai mục đích, chúng tôi tuân thủ theo đúng 
      quy định của pháp luật Việt Nam về bán hàng và bảo vệ quyền lợi tiêu dùng.</p>
      `
    }
  },
  en: {
    title: 'Customer Support',
    PolicySecurity: {
      title: 'Privacy Policy',
      path: '/policy/security',
      content: `
        <p>
          Tien Len Steel Corporation Joint Stock Company (Tien Len Steel) respects the privacy of our customers and we are commited to protecting 
          the privacy of  customers' personal information. In this privacy policy, "personal information" is defined to include name, address, email address, 
          phone number or any other personal information you provide. Please take some time to read the privacy policy below to better understand 
          the kind of personal information we collect, how we use them, and the commitments we make.
        </p>
        <p>
          When you access and use this website (including registering for an account) and affiliated websites 
          (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>), it is deemed that you have fully agreed to the terms of this privacy 
          policy
        </p>
        <h3>1. COLLECTION OF PERSONAL INFORMATION</h3>
        <p>
        The following types of personal information may be collected, stored and used:
        <br>
        <ul style="margin-left: 22px">
          <li style="list-style: disc">
            Your visit and use of this website, including the source, duration of visit, the number of pages you 
            view, the number of links you access. We also collect information about your Web browser such 
            as your IP address, geographic location, browser version;
          </li>
          <br>
          <li style="list-style: disc">
            Information you enter when registering an account on our website including name, address, email 
            address. All information must be accurate and legal. We are not responsible for any information 
            not in conformation with the law of Vietnam;
          </li>
          <br>
          <li style="list-style: disc">
            Information regarding anything you purchase on our website;
          </li>
          <br>
          <li style="list-style: disc">
            Information contained in any form of contact you send to us by email or through our website, 
            including the content of such contact;
          </li>
          <br>
          <li style="list-style: disc">
            Any other personal information you submit to us.
          </li>
        </ul>
        </p>
        <h3>2. SCOPE OF USE OF PERSONAL INFORMATION</h3>
        <p>
          The personal information you provide will be used for us to respond to your request or so that we can 
          contact you directly through means of letter, email, text message or phone. By providing information to us 
          through this website, it is deemed that you have understood and consent to the collection, use and disclosure 
          of the above personal information for the purposes identified in this policy
        </p>
        <p>
          When you use this website, it means that you completely agree and accept that the personal information 
          you provide may be kept at the headquarters or affiliated companies of Tien Len Steel for the purpose of 
          improving the website as well as services of Tien Len Steel.
        </p>
        <h3>3. INFORMATION SECURITY</h3>
        <p>
          We are committed to protecting your privacy. We will not provide, transfer or disclose your personal 
          information to any third party for the purpose of commercial promotion without your consent, except in 
          cases requested by competent authority.
        </P>
        <p>
          This website is secured with SSL (Secure Sockets Layer) technology to maximize the protection of the 
          confidentiality and safety of your personal information. However, no technical system is absolutely secured. 
          Therefore, we cannot guarantee the absolute security of and are not responsible for the information you provided. 
          However, Tien Len Steel will always strive to update new technology and take appropriate 
          precautions to minimize any possible risk.
        </p>
        <h3>4. RIGHTS OF THE USER</h3> 
        <p>
          When you discover that your personal information has been misused or abused, you can file a complaint 
          through our contact page or at the following address:
          <br>
          <strong><i>Tien Len Steel Corporation Joint Stock Company</i></strong><br>
          <strong><i>G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province.</i></strong><br>
          <strong><i>Phone number: +84 2513823187 (Vietnamese only)</i></strong><br>
          <strong><i>Email address: info@tienlen.com.vn</i></strong>
          <br>
          We will take all necessary measures to prevent further abuse of such personal information, as well as 
          providing supportive measures to protect your legitimate rights and interest.
        </p>
        <p>
          When receiving your feedback, we will confirm the authenticity of the information and evidence you 
          provide, and guide you within our capacity and capability on how to restore and secure your personal 
          information. In the event that an agreement cannot be reached, either the user or Tien Len Steel has the 
          right to request a judgment from a competent legal authority to protect its legitimate interest.
        </p>
        <h3>5. COOKIES </h3> 
        <p>
          Our Website may collect cookies to enhance your experience. You have the right to agree or disagree with 
          the use of Cookies.
        </p>
        <h3>6. LINKS</h3> 
        <p>
          Our website may contain links to independent third-party websites such as to our partners or related services. 
          This is to give you a better experience and better understanding of the products and services we offer. We 
          are not responsible for the content and privacy of your visit to such website.
        </p>
        <h3>7. POLICY AMENDMENT</h3> 
        <p>
          Tien Len Steel reserves the right to amend any provision to this policy including adding, removing part or 
          in whole or suspending the website without prior notice. However, if any provision is amended in a way 
          that may affect the interest of the user, Tien Len Steel will attempt to notify the amendment 
          on our main website (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>).
        </p>
      `
    },
    PolicyUsage: {
      title: 'Terms Of Use',
      path: '/policy/usage',
      content: `
        <h3>PREFACE</h3> 
        <p>Thank you for visiting the official website of Tien Len Steel Corporation Joint Stock Company (hereinafter referred to as Tien Len Steel for short).</p>
        <p>
          Please take a moment to review and refer to the Terms of Use in regards to usage of this website
          (the site) and any affiliated websites including the main site (<a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>). 
          By naccessing, browsing, or using the site, you (the user) acknowledge that you have read, understood, 
          and agree to conform to the Terms of Use. If you (the user) do not agree with these terms, please 
          do not use this website.
        </p>
        <p>
          Tien Len Steel is responsible for ensuring the transparency and legality of the content on this website and affiliated website
          (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>). All information, images and content posted on this website 
          are in compliance with Vietnamese customs and laws
        </p>
        <p>
          Tien Len Steel provides information, documents and images as a convenience to you as a website 
          user. You (the user) may contact Tien Len Steel's staff directly through the phone number and 
          email address provided in the contact form of this website.
        </p>
        <h3>CONTENT</h3>
        <p>
          Tien Len Steel provides mechanical information, specification, origin as well as documents related 
          to the products and services we provide. You (the user) may communicate directly with our
          employees through the email address or phone number provided on this website for inquiry. The use of this 
          website (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>) is completely free. 
          Tien Len Steel reserves the right to place advertising, including banner ads, 
          on the website and affiliated website (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>).
        </p>
        <p>
          Access to the this website and affiliated website (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>)
          is limited to people with sufficient knowledge of the legal use of online materials. 
          Your (the user) use of this site is authenticated by the relevant username and password 
          you (the user) provide during registration.
        </p>
        <h3>TRADEMARKS AND COPYRIGHTS</h3> 
        <p>
          The content of this website and any affiliated websites including the main site 
          (<a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>), including the text, design, graphics, interface, images, code are 
          copyrighted by Tien Len Steel or a third party officially licensed to Tien Len Steel. Copyright of 
          Tien Len Steel is shown on this site and any other affiliated websites including our main website 
          (<a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) with the English words "Copyright Tien Len Steel Corporation 
          Joint Stock Company. All rights reserved.''
        </p>
        <p>
          All content posted and used on this website including trademark, brand, trade name, logo, images, 
          text, product information, or product designs... are owned by Tien Len Steel and are protected by provisions
          of the Intellectual Property Law of Vietnam and related regulations.
        </p> 
        <p>
          All acts of quoting, copying, modifying, publishing, circulating, distributing, etc. for commercial 
          purposes in any form without prior written consent from Tien Len Steel are considered to be 
          infringement upon the rights of Tien Len Steel. Tien Len Steel has the right to terminate the use 
          of the website and request for compensation (if any) if any infringement is found.
        </p>
        <h3>RIGHTS AND RESPONSIBILITIES OF TIEN LEN STEEL CORPORATION</h3> 
        <p>
          Tien Len Steel has the right to modify, replace and/or terminate the contents and features in part 
          or in whole of this website and all affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>)
          without any prior notice.
        </p>
        <p>
          Tien Len Steel reserves the right to take security measures to protect against unauthorized access 
          or unauthorized modification, disclosure or destruction of information on this website and all 
          affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>)
        </p>
        <p>
          While using this website and all affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>), the 
          user's information and accessed content will be transmitted through a medium/device beyond the 
          control of Tien Len Steel. Accordingly, Tien Len Steel is in no way responsible for any delay, 
          failure, or interruption of any data or other information transmitted in connection with the use of 
          this website and all affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>).
        </p>
        <p>
          Personal information such as address, email, phone number (provided by the user voluntarily) may 
          be used internally for the purpose of upgrading the website, products and services. Such usage strictly complies with the privacy policy posted on this website.
        </p>
        <p>
          Tien Len Steel is allowed to use any ideas or concepts you (the user) have posted on the website 
          for all purposes without limitation to product development, manufacturing or marketing. Tien Len 
          Steel has no obligation to compensate or reward the supply of such information.
        </p>
        <p>
          The user must be aware of and ensure that the information and documents submitted must be that 
          of his/her own or have otherwise been agreed upon. Tien Len Steel will not infringe on third 
          party's rights without prior consent.
        </p>
        <p>
          Tien Len Steel reserves the right to refuse certain documents you submit without being required 
          to respond with reasons.
        </p>

        <h3>RIGHTS AND RESPONSIBILITIES OF THE USER</h3>
        <p>
          You (the user) may view or extract information on the website (print, download, forward…) or 
          share it with others, but only for personal and non-commercial use, provided that the information 
          shared has the following copyright shown: “Copyright Tien Len Steel Corporation Joint Stock 
          Company. All rights reserved.”
        </p>
        <p>
          You (the user) by using this website agrees to comply with the provisions of this Terms of Use; 
          You (the user) may not interfere with or hinder the use of this website and affiliated website 
          (including www.product.tienlensteel.com.vn) by other users; You (the user) may also not interfere in the 
          operation and management of this website and all affiliated websites 
          (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>).
        
          </p>
        <p>
          You (the user) acknowledge and accepts that Tien Len Steel and/or its member 
          companies/subsidiaries/employees are not responsible for any loss, damage or expense arising 
          from your decision to use any information on this website and all affiliated websites (including 
          <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) for any reason.
        </p>
        <p>
          If you are not satisfied with any information on this Website or affiliated websites (including 
          <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) or with 
          any of the terms and conditions of this Terms of Use, please do not use this Website or any 
          affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>)
        </p>

        <h3>URL LINKS</h3>
        <p>
          Anyone linking to Tien Len Steel's website is deemed to fully understand and agree to the 
          condition below without objection:
        </p>
        <p>
          A link to the website of Tien Len Steel Corporation Joint Stock Company (hereinafter referred to 
          as a “Source Link”) does not represent, directly or indirectly, any relationship between Tien Len 
          Steel and any individual, organization, or administrator of the Links. Likewise, any forms of link 
          do not mean that Tien Len Steel is responsible for the content of the website contained in the 
          Source Link.
        </p>
         <p>
          Individuals or entities, who have a Source Link to Tien Len Steel's website are required to comply 
          with the Terms of Use of such Source Link and the Terms of Use of Tien Len Steel's website. 
          Under no circumstances shall Tien Len Steel be liable for any damage or loss arising from any 
          Source Link.
        </p>

        <h3>INDEMNITY</h3> 
        <p>
          Tien Len Steel strives to ensure the accuracy of information before posting on the website, but 
          does not guarantee the absolute accuracy or suitability of the information for a particular purpose.
        </p>
        <p>
          Under no circumstances shall Tien Len Steel be liable for any damages arising from the use or 
          inability to use this website or affiliated website (<a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>)
          from malicious data such as computer virus or any other cause. 
        </p>
        <p>
          You agree to indemnify and hold Tien Len Steel, its shareholders, employees, subsidiaries and affiliates 
          harmless from any demands, loss, liability, claims or expenses, made against Tien Len Steel by 
          any third party due to or arising out of or in connection with your use of this Website or any 
          affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>).
        </p>
        <p>
          Any answer to request or question sent to Tien Len Steel when made through this website may not 
          be received due to system error, coding error, virus infections or typographical error. If you do 
          not receive a reply within 48 hours, please resubmit or contact us by other means.
        </p>
        <h3>GENERAL TERMS</h3> 
        <p>
          The Terms of Use are governed by the laws of Vietnam.
        </p>
        <p>
          If any provision to this Terms of Use is held by the court or any other competent jurisdiction to be 
          void or unenforcable, such decision is limited only to that provision and shall be replaced with a 
          valid provision at the soonest. Such decision does not mean the invalidity of any of the remaining 
          provisions.
        </p>
        <p>
          Tien Len Steel welcomes any comments/suggestions about website content. If there is a part of 
          this website or any affiliated websites (including <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) that you believe to 
          have infringed upon by a third party, please contact Tien Len Steel by phone number or email 
          address provided in this website.
        </p>
        <p>
          This Terms of Use constitutes the entire agreement between Tien Len Steel and you (the user) in 
          relation to the use of information on this website and any affiliated websites (including 
          <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>). Tien Len Steel may modify the content of this Terms of Use at any 
          time without prior notice.
        </p>
        <p>
          Any dispute or complaint arising or related to this website shall be resolved by amicable negotiation, mediation and/or 
          the current legal documents of Vietnam.
        </p>
      `
    },
    PolicySale: {
      title: 'Sales Policy',
      path: '/policy/sales',
      category: [
        'ORDERING, RECEIVING AND PROCESSING ORDER',
        'STEEL PROCESSING',
        'PAYMENT METHOD',
        'DELIVERY METHOD',
        'POLICY FOR REGULAR CUSTOMER',
        'PRODUCT QUALITY AND CUSTOMER\'S BENEFITS',
        'POLICY CHANGE',
      ],
      content: `
            <h3 id='sales-1'>1. ORDERING, RECEIVING AND PROCESSING ORDER </h3> 
            <p>
              In order to facilitate customers' needs, Tien Len Steel has adopted many methods of 
              receiving orders, such as through Telephone, Email, Website... Upon receiving an order, 
              sales staff at the headquarter will process and may forward the order to one of our 
              subsidiary companies depending on the customer's desired place of delivery.
            </p>
            <p>
              Once we have received your acceptance on price, our staff will walk you through the order 
              process, including the signing of sales contract or sales order. We will follow and assist 
              you until you have safely received your order.
            </p>
            <p>Please find below our contact information:
            <br>• Email: sales@tienlen.com.vn 
            <br>• Telephone number:
            <br>&nbsp;&nbsp;&nbsp;&nbsp;Hotline: 0251.3823187 (ext. 117)
            <br>&nbsp;&nbsp;&nbsp;&nbsp;Sales Department: 0251.8823087 
            <br>• Website: www.product.tienlensteel.com.vn (Online order) 
            <br>• Meet in person at our head office or at a store, branch, or member company: </p>
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Dong Nai Prefecture:</u></i>
            <br><span style="text-decoration: underline">TLH Tower - Tien Len Steel Corporation Joint Stock Company</span>
            <br>Address : G4A, Quarter 4, Tan Hiep Ward, Bien Hoa City, Dong Nai Province
            <br>Tel : 0251.3823.187 ( ext. 117 ); 0251.8823.087 Fax : 0251.3829.043
            </p>
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Ho Chi Minh City:</u></i> 
            <br><span style="text-decoration: underline">Dai Phuc Trading and Production Co., Ltd</span>
            <br>Address : Lot 8, Song Hanh Street, Tan Tao Industrial Zone, Tan Tao A Ward, Binh Tan District, Ho Chi Minh City.
            <br><span style="text-decoration: underline">Tay Nguyen Steel Co., Ltd</span>
            <br>Address : Lot 12-14, No.1 Street, Tan Tao Industrial Zone, Tan Tao A Ward, Binh Tan District, Ho Chi Minh City.
            </p>
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Da Nang :</u></i> 
            <br><span style="text-decoration: underline">Tien Len Steel Corporation Joint Stock Company's Da Nang Branch</span>
            <br>Address : Hoa Chau Village, Hoa Vang District, Da Nang
            </p>
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Ha Noi :</u></i> 
            <br><span style="text-decoration: underline">Phuc Tien Steel Company Limited</span>
            <br>Address: 307-309, Tam Trinh Road, Hoang Mai District, Ha Noi.
            </p> 
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Hung Yen:</u></i> 
            <br><span style="text-decoration: underline">Phuc Tien Hung Yen Trade and Manufacture Co., Ltd.</span>
            <br>Address : Nghia Trai Hamlet, Tan Quan Village, Van Lam District, Hung Yen Province
            </p>
            <p><i><u style="text-decoration: none; font-weight: bold; font-style: normal">Hai Phong:</u></i> 
            <br><span style="text-decoration: underline">Phuc Tien Hai Phong Limited Company</span>
            <br>Address: Bac Ha Hamlet, Bac Son Village, An Duong district, Hai Phong City
            </p>
            <p>
              Tien Len Steel is committed to serving our customer with dedication. We place importance on efficiency, 
              and prioritize mutual development to protect the interest of our customer.<br>
              <a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a> is directly operated by Tien Len Steel Corporation Joint 
              Stock Company's head office. We ensure the transparency of all transactions and the 
              maximization of interest for our customers.
            </p>
            <h3 id='sales-2'>2. STEEL PROCCESSING</h3> 
            <p>
              We are proud to be one of the few steel suppliers with the most modern steel processing 
              technology on the Vietnamese market. Our machines can process large steel products with 
              high difficulty while ensuring quality remains unchanged. Among the machines we 
              have, we are proud to have machines that can cut steel coil and plate with thicknesses from 
              0.2mm to 25mm, width from 1m to 2m and coil weight up to 35 tons. If you are interested, 
              please contact us directly via the <a target='_blank' href='https://product.tienlensteel.com.vn/contact'>contact page.</a>
            </p>
            <h3 id='sales-3'>3. PAYMENT METHOD</h3> 
            <i><u style="text-decoration: none"><span>a) </span><span style="text-decoration: underline">Cash or Bank transfer</span></u></i>
            <br>
            <p>
              You may pay by cash at our head office or one of our member companies or representative 
              stores. We also accept bank transfer before delivering your order.
            </p>
            <p><i><u style="text-decoration: none">b) <span style="text-decoration: underline">Payment by Letter of Credit opened at a trusted bank</span></u></i> 
            <p>
              For customers wanting to pay after delivery and/or to reduce financial costs for large, 
              high-valued transaction, we accept payment made by Letter of Credit opened at a 
              creditable bank. We are happy to provide guidance if you are interested in this form of 
              payment.
            </p>
            <i><u style="text-decoration: none">c) <span style="text-decoration: underline">Entrusted import</span></u></i>
            <br>
            <p>
              Tien Len Steel Corporation provides entrusted import service with attractive fees. 
              Please contact us through our contact page or the phone number provided above  for further assistance.
            </p>
            <p style="margin-left: 10px">
              i. You can pay directly to the supplier and we will take care of the import/export 
              procedure and only collect the service fee for such procedure.
            </p>
            <p style="margin-left: 10px">
              ii. You may entrust us to pay and carry out import/export procedure on your behalf. In this case, we 
              will apply an interest fee on the amount paid. Please contact us 
              through our contact page or the phone number provided above for further 
              assistance.
            </p>
            <p>
              <i><u style="text-decoration: none">d) <span style="text-decoration: underline">For export product</span></u></i>
              <br>
              For export products, we accept payment via bank transfer or by opening a Letter of Credit
            </p>
            <h3 id='sales-4'>4. DELIVERY METHOD</h3>
            <p><strong>Place of Delivery</strong>
            <br>
            <i><u style="text-decoration: none">a) <span style="text-decoration: underline">Mill</span></u></i> <br>
            Tien Len Steel is currently a leading partner of many domestic steel mills such as Posco 
            Vietnam, Pomina, TungHo Vietnam, Nha Be Steel, An Khanh Steel, Tisco, Dai Viet, Viet 
            Y, SSE... if you wish to receive your order directly at the mill, please note “delivery at mill” 
            under the desired place of delivery when ordering.
            </p>
            <p><i><u style="text-decoration: none">b) <span style="text-decoration: underline">
              Delivery at Seaport/Terminal/Ship/Bonded Warehouse/Border Gate
              </span>
              </u></i> 
            <br>
            You may choose to receive your order directly at Seaport/Terminal/Ship/Bonded  Warehouse/Border Gate once the cargo has arrived. 
            As one of the leading importers of steel products, Tien Len Steel imports on a daily basis a wide range of products originating 
            from leading producing countries such as Japan, Korea, Thailand, India, China, Russia, Indonesia, Brazil, etc. Therefore, 
            by providing this method of delivery, we aim to help  customers save time and ensure that construction projects and manufacturing schedule are 
            carried out on time. This form of delivery includes "From Warehouse to Warehouse" and "Back to Back."
            </p>
            <p><i><u style="text-decoration: none">c) <span style="text-decoration: underline">At Tien Len Steel's warehouse</span></u></i>
            <br>
            Our warehouses/factories are located throughout Vietnam in order to promptly meet 
            customer's needs. To save time, you may choose to receive your order at our warehouse.
            </p>
            <p><i><u style="text-decoration: none">d) <span style="text-decoration: underline">Delivery at the place requested by the buyer</span></i></u>
            <br>
            We offers delivery to the warehouse, construction site or any other place at your request 
            while ensuring the quality of product is maintained throughout the delivery process and a reasonable delivery price. 
            For this method of delivery, we are happy to advise customers on the most reasonable option in order to save cost, time and human resources.
            </p>
            <p><i><u style="text-decoration: none">e) <span style="text-decoration: underline">Storing at our warehouse</span></i></u>
            <br>
            To assist customers who do not have storage area, we offer storage service at our 
            warehouse at a reasonable rate. Our warehouses are equipped with the necessary facilities 
            to maintain the quality of products, including roof to prevent rain and crane system 
            to lift and lower products up to 35 tons.
            </p>
            <p><strong>Other information</strong>
            <br>
            a) In order to ensure that products reach customers safely, quickly and securely, when 
            concluding an order, we may consult with customers on the documents we may need you to provide.
            <br>
            b) We also issue VAT invoice designated by the Ministry of Finance and provide certificate of quality to prove the origin and quality of product.
            </p>
            <h3 id='sales-5'>5. POLICY FOR REGULAR CUSTOMER</h3>
            <p>
              For regular customers, long-term traditional customers, customers who place large-value 
              order, we provide additional benefits such as: special payment policy, special transaction 
              policy, priority in receiving information on price fluctuations, keeping price/keeping 
              goods, participation in customer appreciation programs, discount policy, 
              shipping/delivery support, etc.
            </p>
            <h3 id='sales-6'>6. PRODUCT QUALITY AND CUSTOMER'S BENEFITS: </h3>
            <p>
              In the face of fierce competition between domestic products and imported products, we 
              consider the quality of product and customer service as the deciding factor for continuous 
              growth. As such, we make sure to maintain a workforce dedicated to giving customers the 
              best service possible and advanced technology to ensure the quality of the products we 
              supply.
            </p>
            <p>
              We also pay special attention to eliminating counterfeit and imitated products. With a 
              wealth of experience, all products supplied by us are original. We have also registered for
              exclusive trademark at the National Office of Intellectual Property of Vietnam to prevent 
              our name from being misused.
            </p>
            <p>
              With the mission to protect our customer's interest, we make sure all of our employees 
              undergo necessary training as well as carrying out regular quality check.
            </p>
            <p>
              Our member companies, branches and representative stores also apply the same policy 
              mentioned herein.
            </p>
            <h3 id='sales-7'>7. POLICY CHANGE</h3> 
            <p>
              a) When there is a change in this sales policy, we will post a notice on our main website 
              (<a target='_blank' href='https://product.tienlensteel.com.vn'>https://www.product.tienlensteel.com.vn</a>) 15 days in advance. We recommend 
              customers to regularly check our main website to stay up to date.
            </p>
            <p>
              b) The content of this "Sales Policy" applies only to Tien Len Steel Corporation Joint 
              Stock Company and does not include or relate to third parties who place 
              advertisements or have links at <a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a> 
              and <a target='_blank' href='https://product.tienlensteel.com.vn'>www.product.tienlensteel.com.vn</a>
            </p>
            <p>
              c) This sales policy includes all legal elements according to Vietnamese law. This policy 
              is applied for all transactions and payment request to ensure a safe transaction.
            </p>
            <p>
              d) At Tien Len Steel, we commit to not disclose or misuse customer's information. We
              strictly comply with the provisions of Vietnamese law on sales transaction and the
              protection of consumer rights.
            </p>
          `
    }
  }
};
