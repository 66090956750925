import logo from '../../../asset/img/Logo.png';

export const FooterCommon = {
  vi: {
    FooterTitle: {
      main: [
        {
          title: 'Giới thiệu',
          link: 'https://tienlensteel.com.vn/vi/intro/',
        },
        {
          title: 'Sản phẩm',
          path: '/products',
        },
        {
          title: 'Hệ thống phân phối',
          link: 'https://tienlensteel.com.vn/vi/intro/agency',
        },
        {
          title: 'Liên hệ',
          path: '/contact',
        },
        {
          title: 'Hỗ trợ khách hàng',
          path: '/policy/security',
        },
      ],
      sub: [
        {
          title: 'Chính sách bán hàng',
          path: '/policy/sales',
        },
        {
          title: 'Chính sách sử dụng website',
          path: '/policy/usage',
        },
        {
          title: 'Chính sách bảo mật website',
          path: '/policy/security',
        },
      ]
    },
    FooterEnding: {
      logo,
      title: `CÔNG TY CỔ PHẦN TẬP ĐOÀN THÉP TIẾN LÊN - TRỤ SỞ CHÍNH`,
      address: `G4A Khu Phố 4, Phường Tân Hiệp,
            <br /> Thành Phố Biên Hòa, Tỉnh Đồng Nai`,
      phone: '(+84) 251 382 3187',
      workTime: `Sáng: 7h30 - 12h00, Chiều: 13h00 - 16h30 <br />Nghỉ vào Chủ nhật và Ngày Lễ`
    },
    FooterPolicy: {
      copyright: 'Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved',
      policy: 'Điều khoản sử dụng'
    }
  },
  en: {
    FooterTitle: {
      main: [
        {
          title: 'About us',
          link: 'https://tienlensteel.com.vn/en/intro',
        },
        {
          title: 'Products',
          path: '/products',
        },
        {
          title: 'Distribution Network',
          link: 'https://tienlensteel.com.vn/en/intro/agency',
        },
        {
          title: 'Contact',
          path: '/contact',
        },
        {
          title: 'Customer Support',
          path: '/policy/security',
        },
      ],
      sub: [
        {
          title: 'Sales Policy',
          path: '/policy/sales',
        },
        {
          title: 'Terms of Use',
          path: '/policy/usage',
        },
        {
          title: 'Privacy Policy',
          path: '/policy/security',
        },
      ]
    },
    FooterEnding: {
      title: 'Tien Len Steel Corporation Joint Stock Company - Head Office',
      address: `G4A, Quarter 4, Tan Hiep Ward,<br> Bien Hoa City, Dong Nai Province`,
      phone: '(+84) 251 382 3187',
      logo,
      workTime: `Morning from 7:30am to 12:00pm, afternoon from 1:00pm to 4:30pm.<br>Holiday on
                Sunday and Vietnamese national holiday.`
    },
    FooterPolicy: {
      copyright: 'Copyright Tien Len Steel Corporation Joint Stock Company. All rights reserved',
      policy: 'Terms of use'
    }
  }
};

export const FooterCommonMobile = {
  vi: {
    FooterTitle: {
      main: [
        {
          title: 'Giới thiệu',
          link: 'https://tienlensteel.com.vn/vi/intro/',
        },
        {
          title: 'Sản phẩm',
          path: '/products',
        },
        {
          title: 'Hệ thống phân phối',
          link: 'https://tienlensteel.com.vn/vi/intro/agency',
        },
        {
          title: 'Liên hệ',
          path: '/contact',
        },
        {
          title: 'Hỗ trợ khách hàng',
          path: '/policy/security',
          sub: [
            {
              title: 'Chính sách bán hàng',
              path: '/policy/sales',
            },
            {
              title: 'Chính sách sử dụng website',
              path: '/policy/usage',
            },
            {
              title: 'Chính sách bảo mật website',
              path: '/policy/security',
            },
          ]
        },
      ],
    }
  },
  en: {
    FooterTitle: {
      main: [
        {
          title: 'About us',
          link: 'https://tienlensteel.com.vn/en/intro',
        },
        {
          title: 'Products',
          path: '/products',
        },
        {
          title: 'Distribution Network',
          link: 'https://tienlensteel.com.vn/en/intro/agency',
        },
        {
          title: 'Contact',
          path: '/contact',
        },
        {
          title: 'Customer Support',
          path: '/policy/security',
          sub: [
            {
              title: 'Sales Policy',
              path: '/policy/sales',
            },
            {
              title: 'Terms of Use',
              path: '/policy/usage',
            },
            {
              title: 'Privacy Policy',
              path: '/policy/security',
            },
          ]
        },
      ],
    }
  },
};

export const HeaderCommon = {
  vi: {
    logout: 'Đăng xuất',
    cart: 'Giỏ hàng',
    HeaderTitleHeading: [
      {
        path: '/profile',
        title: 'Tài khoản'
      },
    ],
    HeaderTitleMain: [
      {
        path: '/',
        title: 'Trang chủ',
      },
      {
        link: 'https://tienlensteel.com.vn/vi/intro',
        title: 'Giới thiệu',
      },
      {
        path: '/products',
        title: 'Sản phẩm',
      },
      {
        link: 'https://tienlensteel.com.vn/vi/intro/agency',
        title: 'Hệ thống phân phối',
      },
      {
        path: '/policy/security',
        title: 'Hỗ trợ khách hàng',
      },
      {
        path: '/contact',
        title: 'Liên hệ',
      }
    ]
  },
  en: {
    logout: 'Log out',
    cart: 'Cart',
    HeaderTitleHeading: [
      {
        path: '/profile',
        title: 'Account'
      },
    ],
    HeaderTitleMain: [
      {
        path: '/',
        title: 'Home',
      },
      {
        link: 'https://tienlensteel.com.vn/en/intro',
        title: 'About Us',
      },
      {
        path: '/products',
        title: 'Products',
      },
      {
        link: 'https://tienlensteel.com.vn/en/intro/agency',
        title: 'Distribution Network',
      },
      {
        path: '/policy/security',
        title: 'Customer Support',
      },
      {
        path: '/contact',
        title: 'Contact us',
      }
    ]
  }
};

export const HeaderMobile = {
  vi: {
    HeaderTitleMain: [
      {
        path: '/',
        title: 'Trang chủ',
      },
      {
        link: 'https://tienlensteel.com.vn/vi/intro',
        title: 'Giới thiệu',
      },
      {
        path: '/products',
        title: 'Sản phẩm',
      },
      {
        link: 'https://tienlensteel.com.vn/vi/intro/agency',
        title: 'Hệ thống phân phối',
      },
      {
        title: 'Hỗ trợ khách hàng',
        sub: [
          {
            path: '/policy/sales',
            title: 'Chính sách bán hàng',
          },
          {
            path: '/policy/sales',
            title: 'Chính sách sử dụng website',
          },
          {
            path: '/policy/security',
            title: 'Chính sách bảo mật website',
          },
        ]
      },
      {
        path: '/contact',
        title: 'Liên hệ',
      }
    ]
  },
  en: {
    HeaderTitleMain: [
      {
        path: '/',
        title: 'Home',
      },
      {
        link: 'https://tienlensteel.com.vn/en/intro',
        title: 'About Us',
      },
      {
        path: '/products',
        title: 'Products',
      },
      {
        link: 'https://tienlensteel.com.vn/en/intro/agency',
        title: 'Distribution Network',
      },
      {
        title: 'Customer Support',
        sub: [
          {
            path: '/policy/sales',
            title: 'Sales Policy',
          },
          {
            path: '/policy/sales',
            title: 'Terms of Use',
          },
          {
            path: '/policy/security',
            title: 'Privacy Policy',
          },
        ]
      },
      {
        path: '/contact',
        title: 'Contact',
      }
    ]
  }
};

// Contact
export const contactMedia = {
  phone: '02518823087',
  email: 'sales@tienlen.com.vn',
  facebook: 'https://www.facebook.com/theptienlen/'
};
