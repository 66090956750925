import React from 'react';
import { Link } from 'react-router-dom';
import { CART_LANGUAGE } from '../../Cart/Language/Language';
function CustomerInfo({ customerModel, language }) {
  let receivingMethodName = null;
  let receivingMethodCode = null;
  if (customerModel && customerModel.Customer_Receiving_Method) {
    receivingMethodCode = customerModel.Customer_Receiving_Method;
    switch (customerModel.Customer_Receiving_Method) {
      case 'WAREHOUSE':
        receivingMethodName = language === 'vi' ? 'Tại kho của Tập đoàn' : 'Pick-up at our warehouse';
        break;
      case 'REQUESTED_PLACE':
        receivingMethodName = language === 'vi' ? 'Tại địa chỉ của khách hàng' : 'Deliver to your address';
        break;
      case 'OTHERS':
        receivingMethodName = language === 'vi' ? 'Phương thức khác' : 'Others';
        break;
      default:
        break;
    }
  }
  return (
    <div className='customer-info'>
      <div className="customer-info__title">
        <p>{CART_LANGUAGE[language]['CUSTOMER_INFO']}</p>
        <Link to='/cart/changecustomerinfo' className="btn-edit">{CART_LANGUAGE[language]['EDIT']}</Link>
      </div>
      <div className="customer-info__content">
        <div className="customer-info__content-row">
          <label>{CART_LANGUAGE[language]['FULLNAME']}</label>
          <p>{customerModel?.Customer_Fullname}</p>
        </div>
        <div className="customer-info__content-row">
          <label>Email</label>
          <p>{customerModel?.Customer_Email}</p>
        </div>
        <div className="customer-info__content-row">
          <label>{CART_LANGUAGE[language]['PHONE_NUMBER']}</label>
          <p>{customerModel?.Customer_PhoneNumber}</p>
        </div>
        {receivingMethodName &&
          <div className="customer-info__content-row">
            <label>{CART_LANGUAGE[language]['RECEIVING_METHOD']}</label>
            <p>{receivingMethodName}</p>
          </div>
        }
        {receivingMethodCode && receivingMethodCode !== 'WAREHOUSE' &&
          <div className="customer-info__content-row">
            <label>{CART_LANGUAGE[language]['ADDRESS_OR_METHOD']}</label>
            <p>{customerModel?.Customer_Address}</p>
          </div>
        }
      </div>
    </div>
  );
}

export default CustomerInfo;
