import React, { useState } from 'react';
import { Steps } from 'antd';
import { MenuOutlined, } from '@ant-design/icons';
import logo from '../../../asset/img/Logo.png';
import { CART_LANGUAGE } from '../../Cart/Language/Language';

const { Step } = Steps;

function Header(props) {
  const [active, setActive] = useState(false);
  const language = props.language;
  return (
    <header className='checkout__header'>
      <div className='container'>
        <div className='checkout__header-content'>
          <div className='checkout__header-mobile-menu'>
            <MenuOutlined onClick={() => setActive(!active)} />
            <div className={`checkout__header-mobile-menu-content ${active ? 'active' : ''}`}>
              <Steps size='small' current={props.current} direction='vertical' responsive={true}>
                <Step title={CART_LANGUAGE[language]['TITLE']} />
                <Step title={CART_LANGUAGE[language]['CUSTOMER_INFO']} />
                <Step title={CART_LANGUAGE[language]['PLACE_ORDER']} />
              </Steps>
            </div>
          </div>
          <div className='checkout__header-logo'>
            <a href='/'>
              <img src={logo} alt='logo' />
            </a>
          </div>
          <div className='checkout__header-progress'>
            <Steps size='small' current={props.current} responsive={true}>
              <Step title={CART_LANGUAGE[language]['TITLE']} />
              <Step title={CART_LANGUAGE[language]['CUSTOMER_INFO']} />
              <Step title={CART_LANGUAGE[language]['PLACE_ORDER']} />
            </Steps>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
