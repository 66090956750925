import React, { useEffect, useState, } from 'react';
import backgroundTitle from '../../asset/img/img-8.jpg';
import { ChangePasswordCommon, ProfileCommon } from './Model/LoginModel';
import Helmet from '../Layout/Helmet';
import Breadcrum from '../Layout/Breadcrum';
import TitleBreadcrum from '../Layout/TitleBreadcrum';
import { Form, Input, Row, Col, Select } from 'antd';
import './Css/Login.css';
import { Link } from 'react-router-dom';
import ModalLogin from './Control/ModalLogin';
import { LoginService } from './Services/LoginServices';
import { RESULT_CODES } from '../../common/Constant.Common';
import { TITLE_DATASOURCE } from '../Cart/Model/Cart.Model';
const { Option } = Select;

export default function ProfilePage(props) {
    const [modal, setModal] = useState(false);
    const { language } = props;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = async (values) => {
        const model = { _id: userInfo._id, ...values };
        const update = await LoginService.update(model);
        
        if (update.returnCode === RESULT_CODES.SUCCESS) {
            localStorage.setItem('userInfo', JSON.stringify(update.data));
            setModal(prevProps => !prevProps);
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        }
    };
    return (
        <Helmet title={ProfileCommon[language].title}>
            <Breadcrum data={[
                ProfileCommon[language].title
            ]}
            ></Breadcrum>
            <TitleBreadcrum title={ProfileCommon[language].title} background={backgroundTitle}></TitleBreadcrum>
            <Form
                className='login-main'
                onFinish={onSubmit}
                initialValues={userInfo}
            >
                <h3>{ProfileCommon[language].ProfileField.title}</h3>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.email.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <div className='login-main_-field--input--fake'>
                            <span>{userInfo.User_Email}</span>
                        </div>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.password.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <div className='login-main_-field--input--fake'>
                            <span>*********</span>
                            <Link to='changePassword' className='login-main_-field--input--fake-event'>{ChangePasswordCommon[language].title}</Link>
                        </div>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.name.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>

                        <Form.Item
                            name='User_Username'
                            rules={[{ required: true, message: ProfileCommon[language].ProfileField.main.name.warning }]}
                        >
                            <Input type='text' className='login-main__field--input'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.phone.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='User_Phone'
                            rules={[{ required: true,  pattern: /^[\d]{0,10}$/ ,message: ProfileCommon[language].ProfileField.main.phone.warning }]}
                        >
                            <Input type='text' maxLength={10} className='login-main__field--input'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.address.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='User_Address'
                            rules={[{ required: true, message: ProfileCommon[language].ProfileField.main.address.warning }]}
                        >
                            <Input type='text' className='login-main__field--input'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.gender.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='User_Call'
                            rules={[{ required: true, message: ProfileCommon[language].ProfileField.main.gender.warning }]}
                        >
                             <Select >
                            {TITLE_DATASOURCE[language].map((item) => {
                                return <Select.Option value={item.value} key={item.id}>{item.title}</Select.Option>;
                            })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className='login-main__field-title login-main__field-title--profile'>{ProfileCommon[language].ProfileField.main.birthday.title}</div>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <Form.Item
                            name='User_Birthday'
                        >
                            <DatePicker className='login-main__field--input' placeholder='Ngày sinh' format={dateFormat} ></DatePicker>
                        </Form.Item>
                    </Col>
                </Row> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col lg={8} md={8} sm={24} xs={24}>
                    </Col>
                    <Col lg={16} md={16} sm={24} xs={24}>
                        <button htmlType='submit' className='btn-submit-form btn-submit-form-profile'>
                            {ProfileCommon[language].submit}
                        </button>
                    </Col>
                </Row>
            </Form>
            {modal ?
                <ModalLogin handleToggleModal={() => {
                    setModal(prevProps => !prevProps);
                    document.getElementsByTagName('body')[0].style.overflow = 'auto';
                }} content={ProfileCommon[language].modalSuccess}></ModalLogin>
                : ''
            }
        </Helmet>
    );
}
