
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { DownOutlined, MenuOutlined, CloseOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import logo from '../../asset/img/Logo.png';
import { HeaderCommon, HeaderMobile } from './Model/LayoutModel';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { HomeCommon } from '../Home/Model/HomeModel';
import { useCart } from '../Cart/CustomHook/useCart';
import VI from '../../asset/img/LANG_VI.png';
import EN from '../../asset/img/LANG_EN.png';

const getMarginPaddingContainer = () => {
  const a = document.getElementsByClassName('container')[0];
  return a.currentStyle || window.getComputedStyle(a);
};

export default function Header(props) {
  const history = useHistory();
  const headerRef = useRef(null);
  const { totalItem } = useCart();
  const { pathname } = useLocation();
  const { language } = props;
  const pathnameSplit = pathname.split('/')
  const pathnameTemp = [pathnameSplit[2],pathnameSplit[3]]
  const pathnameFinal = `/${pathnameTemp.join(pathnameTemp[1] === undefined ? '':'/')}`
  const checkpath = (check) => check.path === pathnameFinal
  const activeNav = HeaderCommon[language].HeaderTitleMain.findIndex(checkpath)

  const [menuMobile, setMenuMobile] = useState(false);
  const [navMobileChild, setNavMobileChild] = useState(null);
  const [fakebg, setFakebg] = useState('0');
  const home = pathname === `/${language}`||pathname === `/${language}/` ? true : false;
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('customermodel');
    localStorage.removeItem('accessToken');
    history.push(`/${language}/signIn`);
  };

  const handleResize = () => {
    setFakebg(`${getMarginPaddingContainer().marginLeft} + ${getMarginPaddingContainer().paddingLeft}`);
  };

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    if (headerRef.current) {
      headerRef.current.classList.add('header__home--white');
    }
    if (currentScrollPos < 10) {
      headerRef.current.classList.remove('header__home--white');
    }
  }, []);

  const handleOpenMobileMenu = (value) => {
    if (value) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      headerRef.current.classList.add('header__home__mobile--white');
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'unset';
      headerRef.current.classList.remove('header__home__mobile--white');
    }
    setMenuMobile(value);
  };

  useEffect(() => {
    if (home) {
      window.addEventListener('scroll', handleScroll);
    }
    if (headerRef.current) {
      headerRef.current.classList.remove('header__home--white');
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [home, handleScroll]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header ref={headerRef} className={`${!home ? 'header__main' : 'header'} ${home ? 'header__home' : ''} `}>
      <div className='container'>
        <div className='header__contain'>
          {/* <div className='header__content__category__item--mobile--small'>
            <MenuOutlined onClick={() => handleOpenMobileMenu(!menuMobile)} />
          </div> */}
          <Link to={`${HomeCommon[language].path}`} className='header__logo'>
            <img src={logo} alt='logo' />
          </Link>

          <div className='header__content'>
            <div className={`header__content__heading ${!home ? 'header__content__heading-main' : ''}`}>
              {
                localStorage.getItem('userInfo') ?
                  <div className='header__content__heading__item' onClick={handleLogout}>
                    {HeaderCommon[language].logout}
                  </div> :
                  ''
              }
              {
                HeaderCommon[language].HeaderTitleHeading.map((item, index) => {
                  return (
                    <Link key={`${index}${item.title}`} to={`/${language}${item.path}`} className='header__content__heading__item'>
                      <span className='user__profile__icon'> <i className='fas fa-user' aria-hidden='true'></i></span>
                      <p>{user?._id ? user.User_Username : item.title}</p>
                    </Link>
                  );
                })
              }
              <div className='header__content__heading__item'>
                <img src={language === 'vi' ? VI : EN} alt='FLAG' onClick={() => {
                  if (language === 'vi') {
                    props.handleSignLanguage('en');
                  } else {
                    props.handleSignLanguage('vi');
                  }
                }} />
              </div>
              <div className={`header__content__heading__item__fake ${!home ? 'header__content__heading__item__fake-main' : ''}`}
                style={{ width: `calc(${fakebg})` }}
              />
            </div>
            <div className='header__content__category'>
              {
                HeaderCommon[language].HeaderTitleMain.map((item, index) => {
                  if (!!item.sub ?? item.sub.length > 0)
                    return (
                      <div className={`header__content__category__item-container ${!home ? 'header__content__category__item-container-main' : ''}`} key={`${index}${item.title}`}>
                        <Link to={`/${language}${item.path}`} className={`header__content__category__item ${!home ? 'header__content__category__item-main' : ''} 
                        ${activeNav === index ? 'active' : ''}`}>
                          <p>{item.title}</p>
                          <DownOutlined />
                        </Link>
                        <div
                          className={`header__content__category__child ${index === HeaderCommon[language].HeaderTitleMain.length - 1 ?
                            'header__content__category__child--last' : ''}`}
                        >
                          {item.sub.map((item2, index2) => {
                            return (
                              item2?.path ?
                                <Link to={`/${language}${item2.path}`} key={`${index2}${item2.title}`}
                                  className='header__content__category__child__item'
                                >
                                  <p>{item2.title}</p>
                                </Link>
                                :
                                <div className='header__sub-menu-container' key={`${index2}${item2.title}`}>
                                  <div className='header__content__category__child__item flex item-center cursor-pointer'>
                                    <p className='mr-8 transform-unset'>{item2.title}</p>
                                    <DownOutlined />
                                  </div>
                                  <div className='header__sub-menu-item'>
                                    {
                                      item2.sub && item2.sub.length > 0 && item2.sub.map((item3, index3) => {
                                        return (
                                          <Link to={`/${language}${item3.path}`} key={`${index3}${item3.title}`}
                                            className='header__content__category__child__item'>
                                            <p className='translate-x-8'>{item3.title}</p>
                                          </Link>
                                        );
                                      })
                                    }
                                  </div>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  if (item.link)
                    return (
                      <div className={`header__content__category__item-container ${!home ? 'header__content__category__item-container-main' : ''}`} key={`${index}${item.title}`}>
                        <a href={item.link}
                          className={`header__content__category__item ${!home ? 'header__content__category__item-main' : ''} 
                          ${activeNav === index ? 'active' : ''}`}
                          target='_blank' rel='noopener noreferrer'
                        >
                          <p>{item.title}</p>
                        </a>
                      </div>
                    );
                  return (
                    <div className={`header__content__category__item-container ${!home ? 'header__content__category__item-container-main' : ''}`} key={`${index}${item.title}`}>
                      <Link to={`/${language}${item.path}`} className={`header__content__category__item 
                        ${!home ? 'header__content__category__item-main' : ''} ${activeNav === index ? 'active' : ''}`}>
                        <p>{item.title}</p>
                      </Link>
                    </div>
                  );
                })
              }
              <div className='header__content__category__item-cart'>
                <Link to={`/${language}/cart`} onClick={() => handleOpenMobileMenu(false)} className={`header__content__category__item`}>
                  <ShoppingCartOutlined className='header__content__category__item-cart__icon' />
                </Link>
                <div className={`header__content__category__item__quantity ${!home ? 'header__content__category__item__quantity-main' : ''}`}>
                  {totalItem}
                </div>
              </div>
            </div>

            <div className='header__content__category__item--mobile'>
              {
                HeaderCommon[language].HeaderTitleHeading.map((item, index) => {
                  return (
                    <Link key={`${index}${item.title}`} onClick={() => handleOpenMobileMenu(false)} to={`/${language}${item.path}`}
                      className='header__content__heading__item'
                    >
                      <span className='user__profile__icon'> <i className='fas fa-user' aria-hidden='true'></i></span>
                      <p>{user?._id ? user.User_Username : item.title}</p>
                    </Link>
                  );
                })
              }
              <div className='header__content__heading__item'>
                <img src={language === 'vi' ? VI : EN} alt='FLAG' onClick={() => {
                  if (language === 'vi') {
                    props.handleSignLanguage('en');
                  } else {
                    props.handleSignLanguage('vi');
                  }
                }}></img>
              </div>
              <div className='header__content__heading__item'>
                <Link to={`/${language}/cart`} onClick={() => handleOpenMobileMenu(false)}>
                  <ShoppingCartOutlined className='header__content__category__item-cart__icon' />
                </Link>
                <div className={`header__content__category__item__quantity header__content__category__item__quantity-main`}>
                  {totalItem}
                </div>
              </div>
              {menuMobile
                ? <CloseOutlined onClick={() => handleOpenMobileMenu(!menuMobile)} className='menu__icon' />
                : <MenuOutlined onClick={() => handleOpenMobileMenu(!menuMobile)} className='menu__icon' />
              }
            </div>
          </div>

          <div className='header__menu-mobile' style={{ display: !menuMobile ? 'none' : 'block' }}>
            <div className='header__menu-mobile__list'>
              {
                HeaderMobile[language].HeaderTitleMain.map((item, index) => {
                  if (item?.sub && item.sub.length > 0)
                    return (
                      <div key={`${index}${item.title}`}>
                        <div
                          className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`}
                          onClick={() => {
                            if (!navMobileChild || navMobileChild !== item.title)
                              setNavMobileChild(item.title);
                            else
                              setNavMobileChild(null);
                          }}>
                          <p>{item.title}</p>
                          {item.title === navMobileChild ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
                        </div>
                        <div className={`header__menu-mobile__list__child ${item.title === navMobileChild ? 'active' : ''}`}>
                          {item.sub.map((item2, index2) => {
                            return (
                              item2?.path ?
                                <Link to={`/${language}${item2.path}`} key={`${index2}${item2.title}`}
                                  className='header__menu-mobile__list__child__item'
                                  onClick={() => handleOpenMobileMenu(false)}>
                                  <p>{item2.title}</p>
                                </Link>
                                :
                                <div className='header__mobile__sub-menu-container' key={`${index2}${item2.title}`}>
                                  <div className='header__menu-mobile__list__child__item flex item-center'>
                                    <p className='mr-8'>{item2.title}</p>
                                    <DownOutlined />
                                  </div>
                                  <>
                                    {
                                      item2.sub && item2.sub.length > 0 && item2.sub.map((item3, index3) => {
                                        return (
                                          <Link to={`/${language}${item3.path}`} key={`${index3}${item3.title}`}
                                            className='header__menu-mobile__list__child__item'
                                            onClick={() => handleOpenMobileMenu(false)}>
                                            <p className='translate-x-8'>{item3.title}</p>
                                          </Link>
                                        );
                                      })
                                    }
                                  </>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  if (item.link)
                    return (
                      <a
                        key={index + item.title}
                        href={item.link}
                        target='_blank' rel='noopener noreferrer'
                        className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`}
                        onClick={() => handleOpenMobileMenu(false)}>
                        <p>{item.title}</p>
                      </a>
                    );
                  return (
                    <Link
                      key={index + item.title}
                      to={`/${language}${item.path}`}
                      className={`header__menu-mobile__list__detail ${activeNav === index ? 'active' : ''}`}
                      onClick={() => handleOpenMobileMenu(false)}>
                      <p>{item.title}</p>
                    </Link>
                  );
                })
              }
              <Link
                to={`/${language}/cart`}
                className={`header__menu-mobile__list__detail ${pathname === '/cart' ? 'active' : ''}`}
                onClick={() => handleOpenMobileMenu(false)}>
                <p>{`${HeaderCommon[language]['cart']} (${totalItem})`}</p>
              </Link>
              {
                localStorage.getItem('userInfo') ?
                  <div className='header__menu-mobile__list__detail' onClick={() => {
                    handleLogout();
                    handleOpenMobileMenu(false);
                  }}>
                    {HeaderCommon[language].logout}
                  </div> :
                  ''
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
