import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { CART_LANGUAGE } from '../../Cart/Language/Language';
import noImages from '../../../asset/img/no-image.png';
import {formatNumber} from '../../../common/Method.Common'
import {PRODUCT_TYPE} from '../../../common/Constant.Common'
import '../Css/Style.css'
function OrderInfo(props) {
//   useEffect( ()=> {
//     props.cartItems.map(item => {
//       item.orderCustomization.map((spec => {
//         spec[`Specification_Product_Unit_${spec.id}_${item._id}`]
//       }))
//     })
// console.log()
//   },[])
  return (
    <div className='order__info'>
      <div className="order__info__title">
        <div>
          <h3>{CART_LANGUAGE[props.language]['CUSTOMER_ORDER']}</h3>
          <p>{`( ${props.totalItem} ${CART_LANGUAGE[props.language]['PRODUCT']} )`}</p>
        </div>
        <Link to={`/${props.language}/cart`} className="btn-edit">{CART_LANGUAGE[props.language]['EDIT']}</Link>
      </div>
      {props.cartItems.length > 0 &&
        <>
          <div className="order__info__content">
            {props.cartItems.map(item => {
              const image = item.Product_Images?.[0]?.name
                ? `${process.env.REACT_APP_API_URL}/products/${item.Product_Images?.[0]?.name}`
                : noImages;
              return (
                <div key={item._id} className="order__info__content-row">
                  <div className="order__info__image">
                    <div className="order__info__image-wrapper">
                      <img src={image} alt={item.Product_Name} />
                    </div>
                  </div>
                  <div className="order__info__detail">
                    <h3 className="order__info__product__name">
                      {props.language === 'vi' ? item.Product_Name : item.Product_Name_Eng}
                    </h3>
                    <div className="order__info__product__specification">
                      <Row className='order__info__product__specification__title' >
                         {(item.Product_Category==PRODUCT_TYPE.THEPHINH)?
                        <Col lg={5} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['SPECIFICATION']}</h5>
                        </Col>:''
                          }
                        {(item.Product_Category==PRODUCT_TYPE.THEPTAM || item.Product_Category==PRODUCT_TYPE.THEPTAMGAN)?
                          <>

                       <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['LENGTH']}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['WIDTH']}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['HEIGHT']}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['UNIT']}</h5>
                        </Col>
                          </>
                        :''
                        }
                        { item.Product_Category == PRODUCT_TYPE.THEPHINH ? 
                        <>
                          <Col lg={3} md={5} sm={5} xs={5}>
                            <h5>{CART_LANGUAGE[props.language]['WEIGHT_OVER_UNIT']}</h5>
                          </Col>
                        </>
                          : 
                        <>
                          <Col lg={3} md={5} sm={5} xs={5}>
                            <h5>{CART_LANGUAGE[props.language]['WEIGHT_OVER_UNIT_PCS']}</h5>
                          </Col>
                        </>
                        }
                        <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['QUANTITY']} {item[`Specification_Product_Unit_${item.id}_${item._id}`]}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['WEIGHT']}</h5>
                        </Col>
                        {/* <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['STEEL_GRADE']}</h5>
                        </Col> */}
                        {/* <Col lg={3} md={5} sm={5} xs={5}>
                          <h5>{CART_LANGUAGE[props.language]['ORIGIN']}</h5>
                        </Col> */}
                        
                      </Row>
                      <div className='box_content' >
                      <div className='box_content_cart'>
                      {item.orderCustomization.length > 0 && item.orderCustomization.map(spec => (
                        <Row key={spec.id} className='order__info__product__specification__content' >
                       {(item.Product_Category==PRODUCT_TYPE.THEPHINH)?
                        <Col lg={5} md={5} sm={5} xs={5}>
                          <h5>{spec[`Product_Specification_${spec.id}_${item._id}`]}</h5>
                        </Col>:''
                        }
                      {/* {(item.Product_Category==PRODUCT_TYPE.THEPHINH)?
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Product_Type_${spec.id}_${item._id}`]}</h5>
                        </Col>:''
                         } */}
                         
                         {(item.Product_Category==PRODUCT_TYPE.THEPTAM || item.Product_Category==PRODUCT_TYPE.THEPTAMGAN)?
                          <>

                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Length_${spec.id}_${item._id}`]}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Width_${spec.id}_${item._id}`]}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Height_${spec.id}_${item._id}`]}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Product_Unit_${spec.id}_${item._id}`]}</h5>
                        </Col>
                          </>
                        :''
                        }
                  
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Weight_Over_Unit_${spec.id}_${item._id}`]}</h5>
                        </Col>
                        <Col lg={3} md={5} sm={5} xs={5}>
                        <h5>{spec[`Product_Quantity_${spec.id}_${item._id}`]} ({spec[`Specification_Product_Unit_${spec.id}_${item._id}`]})</h5>
                        </Col>
                        <Col lg={5} md={5} sm={5} xs={5}>
                        <h5>{spec[`Specification_Weight_${spec.id}_${item._id}`]}</h5>
                        </Col>
                        {/* <Col lg={5} md={5} sm={5} xs={5}>
                        <h5>{spec[`Product_Quality_${spec.id}_${item._id}`]}</h5>
                        </Col> */}
                        {/* <Col lg={5} md={5} sm={5} xs={5}>
                        <h5>{spec[`Product_Origin_${spec.id}_${item._id}`]}</h5>
                        </Col> */}
                        
                        </Row>
                      ))}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      }
    </div>
  );
}

export default OrderInfo;
